import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * props:
 * - data: objeto contendo as informações do vencedor
 */
function InfoSupplier(props) {
    const { t } = useTranslation();

    const infoSupplier = useMemo(() => {
        return (
            <div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <b>{t('Cliente')}:</b> {props.cliente.name} {props.cliente.sobrenamo}
                    </div>
                    <div className="col-md-6 mb-3">
                        <b>{t('Telefone')}:</b> {props.cliente.phone}
                    </div>
                </div>
                <div className="row">
                    {   
                        props.cliente.job && props.cliente.job !== null && props.cliente.job !== 'null'
                        ? <div className="col-md-6 mb-3">
                            <b>{t('Profissão')}:</b> {props.cliente.job}
                         </div>
                        : <React.Fragment></React.Fragment> 
                    }
                    {   
                        props.cliente.cnpj && props.cliente.cnpj !== null && props.cliente.cnpj !== 'null'
                        ? <div className="col-md-6 mb-3">
                            <b>{t('CNPJ/CPF')}:</b> {props.cliente.cnpj}
                        </div>
                        : <React.Fragment></React.Fragment>
                    }
                </div>
                {/*
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <b>{t('Dia inicial para o atendimento')}:</b> {props.fornecedor.raitherDay}
                    </div>
                    <div className="col-md-6 mb-3">
                        <b>{t('Horário')}:</b> {props.fornecedor.hour}
                    </div>
                </div>*/}
                <div className="row">
                    <div className="col-md-12 mb-3" style={{
                        width: '100%',
                        height: 'auto',
                        minHeight: '20px',
                        overflow: 'hidden',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word'
                    }}>
                        <b>{t('Solicitado')}:</b> {props.fornecedor.description}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mb-3" style={{
                        width: '100%',
                        height: 'auto',
                        minHeight: '20px',
                        overflow: 'hidden',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word'
                    }}>
                        <b>{t('Sua propósta')}:</b> {props.fornecedor.resposta}
                    </div>
                </div>

                <div className="row">
                    {   
                        props.fornecedor.obs && props.fornecedor.obs !== null && props.fornecedor.obs !== 'null'
                        ? <div className="col-md-12 mb-3" style={{
                            width: '100%',
                            height: 'auto',
                            minHeight: '20px',
                            overflow: 'hidden',
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word'
                        }}>
                            <b>{t('Observação')}:</b> {props.fornecedor.obs}
                         </div>
                        : <React.Fragment></React.Fragment>
                    }
                </div>
                <div className="row">
                    {   
                        props.fornecedor.voluntario
                        ? <div className="col-md-12 mb-3">
                            <b>{t('Valor')}: </b> {t('Sem custos')}. ({t('Obrigado por ser voluntário')}).
                         </div>
                        : props.fornecedor.real && props.fornecedor.real !== null && props.fornecedor.real !== 'null'
                            ? <div className="col-md-12 mb-3">
                                <b>{t('Valor')}: </b> {props.fornecedor.real}
                              </div>
                            : <div className="col-md-12 mb-3">
                                <b>{t('Valor')}: </b> {t('Sem custos')}. ({t('Obrigado por ser voluntário')}).
                              </div>
                    }

                </div>

                <div className="row">
                    <div className="col-md-4 mb-3">
                        <b>{t('CEP')}:</b> {props.cliente.cep}
                    </div>
                    <div className="col-md-4 mb-3">
                        <b>{t('Cidade')}:</b> {props.cliente.city}
                    </div>
                    <div className="col-md-4 mb-3">
                        <b>{t('Estado')}:</b> {props.cliente.state}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 mb-3">
                        <b>{t('Bairro')}:</b> {props.cliente.neighborhood}
                    </div>
                    <div className="col-md-4 mb-3">
                        <b>{t('Rua')}:</b> {props.cliente.street}
                    </div>
                    <div className="col-md-4 mb-3">
                        <b>{t('Número')}:</b> {props.cliente.number}
                    </div>
                </div>
            </div>
        );
    }, [props]);

    return infoSupplier;
}

export default InfoSupplier;