import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Pagination({ count, max = 10, size = 10 }) {
    const { t } = useTranslation();

    const PAGE_SIZE = size;
    const MAX_PAGES = max;

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();

    function getPageClass(page) {
        const queryPage = query.get('page');
        const isActive = queryPage == page || (!queryPage && page === 1);
        return isActive ? "page-item active" : "page-item";
    }

    function getPageLink(page) {
        // Preserva outros parâmetros de consulta na URL
        query.set('page', page);
        return `${window.location.pathname}?${query.toString()}`;
    }

    let pagesQty = Math.ceil(count / PAGE_SIZE);
    const pages = [];
    for (let i = 1; i <= Math.min(pagesQty, MAX_PAGES); i++) {
        pages.push(i);
    }

    function getBottom() {
        if (count > (PAGE_SIZE * MAX_PAGES))
            return (<div className="fw-normal small mt-4 mt-lg-0"><b>{count}</b> {t('resultados. Primeiras')} <b>{MAX_PAGES}</b> {t('páginas.')} </div>);
        else if (count > 0)
            return (<div className="fw-normal small mt-4 mt-lg-0"><b>{count}</b> {t('resultados.')}</div>);
        else
            return (<div className="fw-normal small mt-4 mt-lg-0"><b>{t('Nenhum resultado encontrado.')}</b> {t('Crie um primeiro.')}</div>);
    }

    return (
        <div className="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
            <nav aria-label="Page navigation">
                <ul className="pagination mb-0">
                    {pages.map(p => (
                        <li key={p} className={getPageClass(p)}>
                            <Link className="page-link" to={getPageLink(p)}>{p}</Link>
                        </li>
                    ))}
                </ul>
            </nav>
            {getBottom()}
        </div>
    );
}

export default Pagination;