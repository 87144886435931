import React from 'react';
import PropTypes from 'prop-types';
import './BrandLogo.css';

const BrandLogo = ({ imageUrl, alt, size, frameStyle }) => {
    return (
        <div className={`brand-logo-container ${size} ${frameStyle}`}>
            <div className="brand-logo-box">
                <img src={imageUrl} alt={alt} className="brand-logo-image" />
            </div>
        </div>
    );
};

BrandLogo.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    frameStyle: PropTypes.oneOf([
        'wood', 
        'blue', 
        'gold', 
        'double-shadow', 
        'double-shadow-blue', 
        'double-shadow-white', 
        'double-shadow-black', 
        'rainbow', 
        'default'
    ]),
};

BrandLogo.defaultProps = {
    size: 'medium',
    frameStyle: 'default',
};

export default BrandLogo;
