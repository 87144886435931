import axios from './BaseService';

const ORDERS_URL = `${process.env.REACT_APP_API_URL}/orders/`;

export async function allowOrder(order) { // Fornecedores e Provedores *
    const ordersUrl = `${ORDERS_URL}updateorder`;
    const response = await axios.post(ordersUrl, order);
    return response.data;//{count, rows}
}

export async function excludeOrder(order) { // Fornecedores e Provedores *
    const ordersUrl = `${ORDERS_URL}excludeorder`;
    const response = await axios.post(ordersUrl, order);
    return response.data;//{count, rows}
}

export async function endOrderProvider(order) { // Fornecedores e Provedores *
    const ordersUrl = `${ORDERS_URL}hideorderprovider`;
    const response = await axios.post(ordersUrl, order);
    return response.data;//{count, rows}
}

export async function endCreateProvider(order) { // Fornecedores e Provedores *
    const ordersUrl = `${ORDERS_URL}createhideorderprovider`;
    const response = await axios.post(ordersUrl, order);
    return response.data;//{count, rows}
}

export async function endCreateSupplier(order) { // Fornecedores e Provedores *
    const ordersUrl = `${ORDERS_URL}createhideordersupplier`;
    const response = await axios.post(ordersUrl, order);
    return response.data;//{count, rows}
}

export async function endOrderSupplier(order) { // Fornecedores e Provedores *
    const ordersUrl = `${ORDERS_URL}hideordersupplier`;
    const response = await axios.post(ordersUrl, order);
    return response.data;//{count, rows}
}

export async function cancelOrder(order) { // Fornecedores e Provedores *
    const ordersUrl = `${ORDERS_URL}cancelorder`;
    const response = await axios.post(ordersUrl, order);
    return response.data;//{count, rows}
}

// Função para realizar o upload da imagem
export async function updateNewdOrder(formData) {
    const ordersUrl = `${ORDERS_URL}upload`;
    const response = await axios.post(ordersUrl, formData)
    return response.data;
}

// Função para realizar o upload da imagem
export async function saveResponseSuppliers(formData) { // Fornecedores - funcionando *
    const ordersUrl = `${ORDERS_URL}insertsupplier`;
    const response = await axios.post(ordersUrl, formData)
    return response.data;
}

// Função para realizar o upload da imagem
export async function saveResponseProviders(formData) { // Provedores -verificar *
    const ordersUrl = `${ORDERS_URL}insertprovider`;
    const response = await axios.post(ordersUrl, formData)
    return response.data;
}

export async function getSuppliers(search, page = 1) { // Fornecedores - funcionando *
    const ordersUrl = `${ORDERS_URL}getsuppliers${search}?page=${page}`;
    const response = await axios.get(ordersUrl);
    return response.data;//{count, rows}
}

export async function getProviders(search, page = 1) { // Provedores - verificar *
    const ordersUrl = `${ORDERS_URL}getproviders${search}?page=${page}`;
    const response = await axios.get(ordersUrl);
    return response.data;//{count, rows}
}

export async function getMyOrders() { // Provedores - verificar *
    const ordersUrl = `${ORDERS_URL}myorders`; // Pega todas as ordens pelo Id
    const response = await axios.get(ordersUrl);
    return response.data;//{count, rows}
}

export async function getMyProviders(search, page = 1) { // Provedores - verificar *
    const ordersUrl = `${ORDERS_URL}myproviders/${search}?page=${page}`; // Pega todas as ordens pelo Id
    const response = await axios.get(ordersUrl);
    return response.data;//{count, rows}
}

export async function getMySuppliers(search, page = 1) { // Fornecedores - funcionando *
    const ordersUrl = `${ORDERS_URL}mysuppliers/${search}?page=${page}`; // Pega todas as ordens pelo Id
    const response = await axios.get(ordersUrl);
    return response.data;//{count, rows}
}

export async function getImages(images) { // Fornecedores e Provedores *
    const ordersUrl = `${ORDERS_URL}images`;
    const response = await axios.post(ordersUrl, images);
    return response.data;//{count, rows}
}

/*
export async function cancelOrder(symbol, orderId, isFuture = false) {
    const response = await axios.post(`${ORDERS_URL}${symbol}/${orderId}?isFuture=${isFuture}`);
    return response.data;
}*/

export async function rejectResponseSuppliers(respf) { // Fornecedores - funcionando *
    const ordersUrl = `${ORDERS_URL}rejectsupplier`;
    const response = await axios.post(ordersUrl, respf);
    return response.data;//{count, rows}
}

export async function rejectResponseProviders(respf) { // Provedores - verificar *
    const ordersUrl = `${ORDERS_URL}rejectprovider`;
    const response = await axios.post(ordersUrl, respf);
    return response.data;//{count, rows}
}

export async function allowResponseSuppliers(respf) { // Fornecedores - funcionando *
    const ordersUrl = `${ORDERS_URL}allowsupplier`;
    const response = await axios.post(ordersUrl, respf);
    return response.data;//{count, rows}
}

export async function allowResponseProviders(respf) { // Provedores - verificar *
    const ordersUrl = `${ORDERS_URL}allowprovider`;
    const response = await axios.post(ordersUrl, respf);
    return response.data;//{count, rows}
}

export async function winnerResponseSuppliers(respf) { // Fornecedores - funcionando *
    const ordersUrl = `${ORDERS_URL}winnersupplier`;
    const response = await axios.post(ordersUrl, respf);
    return response.data;//{count, rows}
}

export async function winnerResponseProviders(respf) { // Provedores - verificar *
    const ordersUrl = `${ORDERS_URL}winnerprovider`;
    const response = await axios.post(ordersUrl, respf);
    return response.data;//{count, rows}
}

export async function updateFavorite(favoriteData) {
    const ordersUrl = `${ORDERS_URL}favorite`;
    const response = await axios.patch(ordersUrl, favoriteData);
    return response.data;
}

export async function chkFavorite(favoriteData) { // Fornecedores e Provedores *
    const ordersUrl = `${ORDERS_URL}getfavorite`;
    const response = await axios.post(ordersUrl, favoriteData);
    return response.data;//{count, rows}
}










