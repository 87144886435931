import React, { useState, useEffect, useMemo } from 'react';
import SupplierRow from './SupplierRow';
import ViewRespModal from './ViewRespModal';
import { useHistory } from 'react-router-dom';
import { getAverageRating } from '../../../services/RatingService';
import { getPriority, getSettings } from '../../../services/SettingsService';
import { Loader } from '@googlemaps/js-api-loader';
import { useTranslation } from 'react-i18next';

import '../Suppliers.css';

function Supplier(props) {
  const { t } = useTranslation();
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const history = useHistory();
  const [suppliers, setSuppliers] = useState([]);
  const [viewSup, setViewSup] = useState({});
  const [sortedOrders, setSortedOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [localAddress, setLocalAddress] = useState(null);
  const itemsPerPage = 5;

  useEffect(() => {
    getSettings()
      .then(result => {
        setLocalAddress(`${result.street}, ${result.number} ${result.city}`);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (Array.isArray(props.suppliers) && localAddress) {
      addFactorsAndSortOrders(props.suppliers, localAddress);
    }
  }, [props.suppliers, localAddress]);

  async function addFactorsAndSortOrders(orders, local) {
    const enhancedOrders = await Promise.all(orders.map(async order => {
      const myDestination = {
        city: order.city,
        street: order.street,
        number: order.number,
      };

      const { travelTime, distance } = await calculateTravelTimeAndDistance(local, myDestination);
      const ratings = await getAverageRating(order.userId);
      const rating = parseFloat(ratings.averageRating) || 0;
      const priority = await getPriority(order.userId);
      console.log('PRIORITY', priority);

      return {
        ...order,
        travelTime,
        distance,
        rating,
        priority,
      };
    }));

    const sorted = enhancedOrders.sort((a, b) => {
      if (a.priority !== b.priority) return b.priority - a.priority;
      if (a.rating !== b.rating) return b.rating - a.rating;
      if (a.travelTime !== b.travelTime) return a.travelTime - b.travelTime;
      return a.distance - b.distance;
    });

    setSortedOrders(sorted);
  }

  function calculateTravelTimeAndDistance(client, provider) {
    return new Promise((resolve, reject) => {
      const loader = new Loader({
        apiKey: "AIzaSyB0JqCNUoU9i4yNhs9KcebU08ZBC2BpqJE",
        version: "weekly",
        libraries: ["places"]
      });

      loader.load().then(() => {
        const localAddress = client;
        const destinationAddress = `${provider.street}, ${provider.number}, ${provider.city}`;
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ address: localAddress }, (localResults, localStatus) => {
          if (localStatus === 'OK') {
            const originCoords = localResults[0].geometry.location;

            geocoder.geocode({ address: destinationAddress }, (destResults, destStatus) => {
              if (destStatus === 'OK') {
                const destinationCoords = destResults[0].geometry.location;
                const service = new window.google.maps.DistanceMatrixService();

                service.getDistanceMatrix(
                  {
                    origins: [originCoords],
                    destinations: [destinationCoords],
                    travelMode: 'DRIVING'
                  },
                  (response, status) => {
                    if (status === 'OK') {
                      const element = response.rows[0].elements[0];
                      const durationValue = element.duration.value;
                      const distanceValue = element.distance.value;
                      resolve({ travelTime: durationValue, distance: distanceValue });
                    } else {
                      console.error('Error fetching distance:', status);
                      reject(status);
                    }
                  }
                );
              } else {
                console.error('Geocode for destination was not successful:', destStatus);
                reject(destStatus);
              }
            });
          } else {
            console.error('Geocode for local address was not successful:', localStatus);
            reject(localStatus);
          }
        });
      }).catch(error => {
        console.error('Error loading Google Maps API:', error);
        reject(error);
      });
    });
  }

  const handleShowMore = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const displayedOrders = sortedOrders.slice(0, currentPage * itemsPerPage);

  function onSupClick(event) {
    const id = event.target.id.replace('rep', '');
    const sup = sortedOrders.find(o => o.id == id);
    setViewSup({ ...sup });
  }

  function onStarClick(event) {
    const id = event.target.getAttribute('name');
    const item = sortedOrders.find(o => o.id == id);
    if (item) {
      const supWithRating = { ...item, rating: true };
      setViewSup(supWithRating);
    }
  }

  function onAllow() {
    setSuppliers([]);
    if (props.onAllowOrder) {
      props.onAllowOrder();
    }
  }

  function onDeny() {
    setSuppliers([]);
    if (props.onDenyOrder) {
      props.onDenyOrder();
    }
  }

  return (
    <>
      {isLargeScreen ? (
        displayedOrders && displayedOrders.length && displayedOrders.some(item => item.active > 0) ? (
          <div className="col-12 mb-4">
            <div className="card border-0 shadow">
              <div className="card-header">
                <div className="row">
                  <div className="col">
                    <h2 className="fs-5 fw-bold mb-0">{t('Respostas')}</h2>
                  </div>
                </div>
              </div>
              <div className="relative-container">
                <div className="table-responsive divScroll">
                  <table className="table align-items-center table-flush table-sm table-hover tableFixHead">
                    <thead className="thead-light">
                      <tr>
                        <th className="border-gray-200">{t('NOME')}</th>
                        <th className="border-gray-200">{t('VALOR')}</th>
                        <th className="border-gray-200">{t('AVALIAÇÃO')}</th>
                        <th className="border-gray-200">{t('DETALHES')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedOrders.map((item) =>
                        item.active && item.negado === false ? (
                          <SupplierRow key={item.id} data={item} onClick={onSupClick} onStarClick={onStarClick} />
                        ) : (
                          <React.Fragment key={item.id}></React.Fragment>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                {sortedOrders.length > displayedOrders.length && (
                  <button onClick={handleShowMore} className="float-button">{t('Ver mais')}</button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )
      ) : (
        <div className="col-12 mb-4">
          <div className="card border-0 shadow">
            <div className="card-header">
              <div className="row">
                <div className="col">
                  <h2 className="fs-5 fw-bold mb-0">{t('Respostas')}</h2>
                </div>
              </div>
            </div>
            <div className="relative-container">
              <div className="table-responsive divScroll">
                <table className="table align-items-center table-flush table-sm table-hover tableFixHead" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                  <thead className="thead-light" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                    <tr style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                      <th className="border-gray-200" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('NOME')}</th>
                      <th className="border-gray-200" style={{ paddingLeft: "0.6rem", paddingRight: "0.6rem" }}>{t('AVALIAÇÃO')}</th>
                      <th className="border-gray-200" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('DETALHES')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayedOrders.map((item) =>
                      item.active && item.negado === false ? (
                        <SupplierRow key={item.id} data={item} onClick={onSupClick} onStarClick={onStarClick} />
                      ) : (
                        <React.Fragment key={item.id}></React.Fragment>
                      )
                    )}
                  </tbody>
                </table>
              </div>
              {sortedOrders.length > displayedOrders.length && (
                <button onClick={handleShowMore} className="float-button">{t('Mais...')}</button>
              )}
            </div>
          </div>
        </div>
      )}
      <ViewRespModal data={viewSup} onAllow={onAllow} onDeny={onDeny} />
    </>
  );
}

export default Supplier;
