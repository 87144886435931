import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css'; // Certifique-se de importar o CSS global
import Routes from './routes';

ReactDOM.render(
  <React.StrictMode>
   <div className="scale-90">
      <Routes />
  </div>
  </React.StrictMode>,
  document.getElementById('root')
);
