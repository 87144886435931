import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Avatar from '../../components/Avatar/Avatar';
import BrandLogo from '../../components/BrandLogo/BrandLogo';
import SelectCategoryS from '../../components/SelectCategory/SelectCatSSettings';
import SelectCategoryF from '../../components/SelectCategory/SelectCatFSettings';
import SelectCities from '../../components/SelectCities/SelectCities';
import PrivacyModal from '../../components/Privacy/PrivacyModal';
import TermsModal from '../../components/Terms/TermsModal';
import Footer from '../../components/Footer/Footer';
import Toast from '../../components/Toast/Toast';
import { useTranslation } from 'react-i18next';
import './Settings.css';
import { getSettings, updateSettings, uploadAvatar, uploadBrand, getAvatar, getBrand } from '../../services/SettingsService';
import axios from 'axios';

function Settings() {
    const { t } = useTranslation();
    const confirmPassword = useRef('');
    const [settings, setSettings] = useState({});
    const [notification, setNotification] = useState({});
    const [errors, setErrors] = useState({});
    const [avatarImage, setAvatarImage] = useState(null);
    const [brandImage, setBrandImage] = useState(null);
    const [avatarBase64, setAvatarBase64] = useState('');
    const [brandBase64, setBrandBase64] = useState('');
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 992);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const settingsResult = await getSettings();
                setSettings({
                    ...settingsResult,
                    categoriaS: settingsResult.categoriaS ? JSON.parse(settingsResult.categoriaS) : [],
                    categoriaF: settingsResult.categoriaF ? JSON.parse(settingsResult.categoriaF) : [],
                    othercity: settingsResult.othercity ? JSON.parse(settingsResult.othercity) : [],
                });

                const avatarData = await getAvatar();
                setAvatarBase64(avatarData);

                if (settingsResult.priority >= 1) {
                    const brandData = await getBrand();
                    setBrandBase64(brandData);
                }
            } catch (err) {
                console.error(err.response ? err.response.data : err.message);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (notification) {
            const timeoutId = setTimeout(() => {
                setNotification({});
            }, 10000);

            return () => clearTimeout(timeoutId);
        }
    }, [notification]);

    useEffect(() => {
        if (settings.cep && settings.cep.length > 7) {
            axios.get(`https://viacep.com.br/ws/${settings.cep}/json/`)
                .then(response => {
                    const data = response.data;

                    setSettings(prevState => ({
                        ...prevState,
                        street: data.logradouro,
                        city: data.localidade,
                        neighborhood: data.bairro,
                        state: data.uf,
                        country: 'Brasil'
                    }));

                })
                .catch(error => {
                    console.error('Erro ao consultar CEP:', error);
                });
        }
    }, [settings.cep]); 

    function handleAvatarChange(event) {
        const file = event.target.files[0];
        setAvatarImage(file);
    }

    function handleBrandChange(event) {
        const file = event.target.files[0];
        setBrandImage(file);
    }

    function handleSaveAvatar() {
        const formData = new FormData();
        formData.append('file', avatarImage);
        formData.append('userId', settings.id);

        uploadAvatar(formData)
            .then(() => {
                setNotification({ type: 'success', text: t('Foto de Perfil salva com sucesso!') });
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
            });
    }

    function handleSaveBrand() {
        const formData = new FormData();
        formData.append('file', brandImage);
        formData.append('userId', settings.id);

        uploadBrand(formData)
            .then(() => {
                setNotification({ type: 'success', text: t('Logo salvo com sucesso!') });
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
            });
    }

    function onCategorySChange(selectedServices) {
        setSettings(prevState => ({ ...prevState, categoriaS: selectedServices }));
    }

    function onCitiesChange(selectedCities) {
        setSettings(prevState => ({ ...prevState, othercity: selectedCities }));
    }

    function onCategoryFChange(selectedSuppliers) {
        setSettings(prevState => ({ ...prevState, categoriaF: selectedSuppliers }));
    }

    /*
    function onInputChange(event) {
        const { id, value } = event.target;
        setSettings(prevState => ({ ...prevState, [id]: value }));
        setErrors(prevErrors => ({ ...prevErrors, [id]: '' }));
    }*/

        function formatCPFOrCNPJ(value) {
            value = value.replace(/\D/g, ''); // Remove tudo que não é dígito
        
            if (value.length <= 11) {
                // Formatação CPF: XXX.XXX.XXX-XX
                value = value.replace(/(\d{3})(\d)/, '$1.$2');
                value = value.replace(/(\d{3})(\d)/, '$1.$2');
                value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
            } else if (value.length <= 14) {
                // Formatação CNPJ: XX.XXX.XXX/0000-XX
                value = value.replace(/(\d{2})(\d)/, '$1.$2');
                value = value.replace(/(\d{3})(\d)/, '$1.$2');
                value = value.replace(/(\d{3})(\d)/, '$1/$2');
                value = value.replace(/(\d{4})(\d{1,2})$/, '$1-$2');
            }
        
            return value;
        }
        
        function onInputChange(event) {
            const { id, value } = event.target;
            let validationErrors = {};
        
            // Formata o valor como CPF ou CNPJ conforme o usuário digita
            let formattedValue = formatCPFOrCNPJ(value);
        
            // Validação específica para redes sociais (mantida conforme seu código)
            if (id === 'facebook' && value && !value.includes('facebook.com')) {
                validationErrors[id] = t('O campo Facebook deve conter um URL do Facebook válido.');
            } else if (id === 'instagram' && value && !value.includes('instagram.com')) {
                validationErrors[id] = t('O campo Instagram deve conter um URL do Instagram válido.');
            } else if (id === 'linkedin' && value && !value.includes('linkedin.com')) {
                validationErrors[id] = t('O campo LinkedIn deve conter um URL do LinkedIn válido.');
            } else if (id === 'tiktok' && value && !value.includes('tiktok.com')) {
                validationErrors[id] = t('O campo TikTok deve conter um URL do TikTok válido.');
            } else if (id === 'x' && value && !value.includes('x.com')) {
                validationErrors[id] = t('O campo X deve conter um URL do X válido.');
            } else {
                // Se passou na validação, remove o erro específico
                setErrors(prevErrors => ({ ...prevErrors, [id]: '' }));
            }
        
            // Se houver erro, atualiza o estado de erros
            if (Object.keys(validationErrors).length > 0) {
                setErrors(prevErrors => ({ ...prevErrors, ...validationErrors }));
            }
        
            // Atualiza o campo 'twitter' com o valor do campo 'x'
            if (id === 'x') {
                setSettings(prevState => ({ ...prevState, twitter: value }));
            } else if (id === 'cnpj') {
                setSettings(prevState => ({ ...prevState, [id]: formattedValue }));
            } else {
                setSettings(prevState => ({ ...prevState, [id]: value }));
            }
        }

    function onFormSubmit(event) {
        event.preventDefault();
        let validationErrors = {};

        if ((settings.password || confirmPassword.current.value) && settings.password !== confirmPassword.current.value) {
            validationErrors.password = t('Os campos Senha e Confirme a Senha devem ser iguais.');
            validationErrors.confirmPassword = t('Os campos Senha e Confirme a Senha devem ser iguais.');
        }

        const requiredFields = [
            'name', 'sobrenamo', 'birthdate', 'email', 'phone', 'nickname',
            'cep', 'street', 'state', 'country', 'city', 'number', 'neighborhood'
        ];

        requiredFields.forEach(field => {
            if (!settings[field]) {
                validationErrors[field] = t('Este campo é obrigatório.');
            }
        });

        if (settings.nickname && settings.nickname.length > 15) {
            validationErrors.nickname = t('Apelido não pode ter mais de 15 caracteres!');
        }

        if (settings.type === '3' && (!settings.categoriaF || settings.categoriaF.length === 0 || !settings.job || !settings.cnpj)) {
            if (!settings.categoriaF || settings.categoriaF.length === 0) validationErrors.categoriaF = t('Preencha a categoria do fornecedor.');
            if (!settings.job) validationErrors.job = t('Preencha o campo de razão social.');
            if (!settings.cnpj) validationErrors.cnpj = t('Preencha o campo de CNPJ.');
        }

        if (settings.type === '2' && (!settings.categoriaS || settings.categoriaS.length === 0 || !settings.job || !settings.cnpj)) {
            if (!settings.categoriaS || settings.categoriaS.length === 0) validationErrors.categoriaS = t('Preencha a categoria do prestador.');
            if (!settings.job) validationErrors.job = t('Preencha o campo de profissão.');
            if (!settings.cnpj) validationErrors.cnpj = t('Preencha o campo de CNPJ/CPF.');
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setNotification({ type: 'check', text: t('Preencha todos os campos obrigatórios') });
            return;
        }

        const updatedSettings = {
            type: settings.type,
            name: settings.name,
            sobrenamo: settings.sobrenamo,
            birthdate: settings.birthdate,
            street: settings.street,
            state: settings.state,
            country: settings.country,
            city: settings.city,
            cep: settings.cep,
            number: settings.number,
            neighborhood: settings.neighborhood,
            complement: settings.complement,
            phone: settings.phone,
            job: settings.job,
            cnpj: settings.cnpj,
            categoriaS: JSON.stringify(settings.categoriaS),
            categoriaF: JSON.stringify(settings.categoriaF),
            othercity: JSON.stringify(settings.othercity), 
            email: settings.email,
            fa: settings.fa,
            checked: true,
            nickname: settings.nickname,
            password: settings.password,
            site: settings.site,
            facebook: settings.facebook,
            instagram: settings.instagram,
            twitter: settings.twitter,
            tiktok: settings.tiktok,
            linkedin: settings.linkedin,
            experiences: settings.experiences,
        };

        updateSettings(updatedSettings)
            .then(() => {
                setNotification({ type: 'success', text: t('Configurações salvas com sucesso!') });
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
            });
    }

    function onCheckboxChange(event) {
        setSettings(prevState => ({ ...prevState, fa: event.target.checked }));
    }

    return (
        <>
            {isLargeScreen
                ? (
                    <React.Fragment>
                        <Menu />
                        <main className="content">
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                                <div className="d-block mb-4 mb-md-0">
                                    <h1 className="h4">{t('Perfil')}</h1>
                                </div>
                            </div>
                            <div className="card card-body border-0 shadow mb-4">
                                <div className="row">
                                    <div className="col-md-6 d-flex flex-column align-items-center">
                                        <label htmlFor="avatarInput" className="cursor-pointer">
                                            <Avatar imageUrl={avatarImage ? URL.createObjectURL(avatarImage) : `data:image/png;base64,${avatarBase64}`} alt="Clicar" size="large" frameStyle="double-shadow-blue" />
                                            <input id="avatarInput" type="file" onChange={handleAvatarChange} accept="image/*" style={{ display: 'none' }} />
                                        </label>
                                        <h2 className="h5 mt-2">{t('Foto do Perfil')}</h2>
                                        <button className="btn btn-primary mt-2" onClick={handleSaveAvatar}>{t('Salvar Foto')}</button>
                                    </div>
                                    {settings.type !== '1' && (
                                        <div className="col-md-6 d-flex flex-column align-items-center">
                                            <label htmlFor="brandLogoInput" className="cursor-pointer">
                                                <BrandLogo imageUrl={brandImage ? URL.createObjectURL(brandImage) : `data:image/png;base64,${brandBase64}`} alt="Clicar" size="large" frameStyle="double-shadow-blue" />
                                                <input id="brandLogoInput" type="file" onChange={handleBrandChange} accept="image/*" style={{ display: 'none' }} />
                                            </label>
                                            <h2 className="h5 mt-2">
                                                {t('Logo')}
                                            </h2>
                                            {settings.priority === 0 && (<span style={{ fontSize: 9, marginTop: -10 }}> (Plano Premium)</span>)}
                                            <button className="btn btn-primary mt-2" disabled={settings.priority === 0} onClick={handleSaveBrand}>{t('Salvar Logo')}</button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card card-body border-0 shadow mb-4">
                                        <h2 className="h5 mb-4">{settings.type === '3' ? t('Dados do Responsável') : t('Dados Pessoais')}</h2>
                                        <form onSubmit={onFormSubmit}>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="name">{t('Nome')}*</label>
                                                        <input className={`form-control ${errors.name ? 'border-danger' : ''}`} id="name" type="text" placeholder="" value={settings.name || ''} onChange={onInputChange} />
                                                        {errors.name && <div className="error-message" style={{ marginTop: '10px' }}>{errors.name}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="sobrenamo">{t('Sobrenome')}*</label>
                                                        <input className={`form-control ${errors.sobrenamo ? 'border-danger' : ''}`} id="sobrenamo" type="text" placeholder="" value={settings.sobrenamo || ''} onChange={onInputChange} />
                                                        {errors.sobrenamo && <div className="error-message" style={{ marginTop: '10px' }}>{errors.sobrenamo}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="nickname">{t('Chat Apelido')}*</label><span className="small">(Max. 15)</span>
                                                        <input className={`form-control ${errors.nickname ? 'border-danger' : ''}`} id="nickname" type="text" placeholder="" value={settings.nickname || ''} onChange={onInputChange} />
                                                        {errors.nickname && <div className="error-message" style={{ marginTop: '10px' }}>{errors.nickname}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="email">{t('E-mail')}*</label>
                                                        <input className={`form-control ${errors.email ? 'border-danger' : ''}`} id="email" type="email" placeholder="name@company.com" value={settings.email || ''} onChange={onInputChange} />
                                                        {errors.email && <div className="error-message" style={{ marginTop: '10px' }}>{errors.email}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="phone">{t('Telefone')}*</label>
                                                        <input className={`form-control ${errors.phone ? 'border-danger' : ''}`} id="phone" type="tel" placeholder="Ex: (99) 99999-9999" value={settings.phone || ''} onChange={onInputChange} />
                                                        {errors.phone && <div className="error-message" style={{ marginTop: '10px' }}>{errors.phone}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="birthdate">{t('Data de Nascimento')}*</label>
                                                        <input className={`form-control ${errors.birthdate ? 'border-danger' : ''}`} id="birthdate" type="date" placeholder="" value={settings.birthdate ? settings.birthdate.split('T')[0] : ''} onChange={onInputChange} />
                                                        {errors.birthdate && <div className="error-message" style={{ marginTop: '10px' }}>{errors.birthdate}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            {settings.type === '3' && (
                                                <React.Fragment>
                                                    <h2 className="h5 mb-4">{t('Dados da Empresa')}*</h2>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <div className="form-group">
                                                                <label htmlFor="site">{t('Site')}</label>
                                                                <input className={`form-control ${errors.site ? 'border-danger' : ''}`} id="site" type="text" placeholder={settings.priority >= 1 ? "https://www..." : t('Plano Premium') } disabled={settings.priority === 0} value={settings.site || ''} onChange={onInputChange} />
                                                                {errors.site && <div className="error-message" style={{ marginTop: '10px' }}>{errors.site}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            <div className="form-group">
                                                                <label htmlFor="job">{t('Razão Social')}*</label>
                                                                <input className={`form-control ${errors.job ? 'border-danger' : ''}`} id="job" type="text" placeholder="" value={settings.job || ''} onChange={onInputChange} />
                                                                {errors.job && <div className="error-message" style={{ marginTop: '10px' }}>{errors.job}</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <div className="form-group">
                                                                <label htmlFor="cnpj">{t('CNPJ')}*</label>
                                                                <input className={`form-control ${errors.cnpj ? 'border-danger' : ''}`} id="cnpj" type="text" placeholder="" value={settings.cnpj || ''} onChange={onInputChange} />
                                                                {errors.cnpj && <div className="error-message" style={{ marginTop: '10px' }}>{errors.cnpj}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 mb-3">
                                                            <div className="form-group">
                                                            <label htmlFor="cnpj">{t('Experiências')}</label>
                                                            <textarea className="form-control" id="experiences" placeholder={t('Descreva sua experiência, especialidade, anos de atuação, principais habilidades e projetos realizados.')} style={{
                                                                    height: 'auto',
                                                                    minHeight: '20px',
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'pre-wrap',
                                                                    wordWrap: 'break-word'
                                                                }}  value={settings.experiences || ''} onChange={onInputChange}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            <div className="form-group">
                                                                <label htmlFor="categoriaF">{t('Categoria')}*</label>
                                                                <SelectCategoryF value={settings.categoriaF} onChange={onCategoryFChange} className={errors.categoriaF ? 'error' : ''}/>
                                                                {errors.categoriaF && <div className="error-message" style={{ marginTop: '10px' }}>{errors.categoriaF}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                            <h2 className="h5 mb-4">{t('Endereço')}</h2>
                                            <div className="row">
                                                <div className="col-md-2 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="cep">{t('CEP')}*</label>
                                                        <input className={`form-control ${errors.cep ? 'border-danger' : ''}`} id="cep" type="text" placeholder="Ex: 82590200" value={settings.cep || ''} onChange={onInputChange} />
                                                        {errors.cep && <div className="error-message" style={{ marginTop: '10px' }}>{errors.cep}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="street">{t('Rua')}*</label>
                                                        <input className={`form-control ${errors.street ? 'border-danger' : ''}`} id="street" type="text" placeholder="Rua, Av..." value={settings.street || ''} onChange={onInputChange} />
                                                        {errors.street && <div className="error-message" style={{ marginTop: '10px' }}>{errors.street}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-2 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="number">{t('Número')}*</label>
                                                        <input className={`form-control ${errors.number ? 'border-danger' : ''}`} id="number" type="text" placeholder="" value={settings.number || ''} onChange={onInputChange} />
                                                        {errors.number && <div className="error-message" style={{ marginTop: '10px' }}>{errors.number}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="complement">{t('Complemento')}</label>
                                                        <input className={`form-control ${errors.complement ? 'border-danger' : ''}`} id="complement" type="text" placeholder="Condomínio, Apto, Bloco" value={settings.complement || ''} onChange={onInputChange} />
                                                        {errors.complement && <div className="error-message" style={{ marginTop: '10px' }}>{errors.complement}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="neighborhood">{t('Bairro')}*</label>
                                                        <input className={`form-control ${errors.neighborhood ? 'border-danger' : ''}`} id="neighborhood" type="text" placeholder="" value={settings.neighborhood || ''} onChange={onInputChange} />
                                                        {errors.neighborhood && <div className="error-message" style={{ marginTop: '10px' }}>{errors.neighborhood}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="city">{t('Cidade')}*</label>
                                                        <input className={`form-control ${errors.city ? 'border-danger' : ''}`} id="city" type="text" placeholder="" value={settings.city || ''} onChange={onInputChange} />
                                                        {errors.city && <div className="error-message" style={{ marginTop: '10px' }}>{errors.city}</div>}
                                                    </div>
                                                    {/*<select>
                                                    {cidades.map((cidade, index) => (
                                                        <option key={index} value={cidade.nome}>
                                                        {cidade.nome}
                                                        </option>
                                                    ))}
                                                    </select>*/}
                                                </div>
                                                <div className="col-md-1 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="state">{t('UF')}*</label>
                                                        <input className={`form-control ${errors.state ? 'border-danger' : ''}`} id="state" type="text" placeholder="" value={settings.state || ''} onChange={onInputChange} />
                                                        {errors.state && <div className="error-message" style={{ marginTop: '10px' }}>{errors.state}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="country">{t('País')}*</label>
                                                        <input className={`form-control ${errors.country ? 'border-danger' : ''}`} id="country" type="text" placeholder="" value={settings.country || ''} onChange={onInputChange} />
                                                        {errors.country && <div className="error-message" style={{ marginTop: '10px' }}>{errors.country}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            { settings.priority >=1 && settings.type !== '1' && ( <>
                                                <h2 className="h5 mb-4">{t('Outras Regiões Atendidas')}</h2>
                                                <div className="row">
                                                    <div className="col-md-1 mb-3">
                                                        <div className="form-group">
                                                            <label htmlFor="UF">{t('UF')}*</label>
                                                            <input className="form-control" id="uf" type="text" placeholder="" value={settings.uf || ''} onChange={onInputChange} /> 
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className="form-group">
                                                            <label htmlFor="othercity">{t('Cidades')}</label>
                                                            <SelectCities value={settings.othercity} state={settings.uf} onChange={onCitiesChange}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                            {settings.type === '2' && (
                                                <React.Fragment>
                                                    <h2 className="h5 mb-4">{t('Dados do Serviço')}</h2>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <div className="form-group">
                                                                <label htmlFor="site">{t('Site')}</label>
                                                                <input className={`form-control ${errors.site ? 'border-danger' : ''}`} id="site" type="text" placeholder={settings.priority >= 1 ? "https://www..." : t('Plano Premium') } disabled={settings.priority === 0} value={settings.site || ''} onChange={onInputChange} />
                                                                {errors.site && <div className="error-message" style={{ marginTop: '10px' }}>{errors.site}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            <div className="form-group">
                                                                <label htmlFor="job">{t('Profissão')}*</label>
                                                                <input className={`form-control ${errors.job ? 'border-danger' : ''}`} id="job" type="text" placeholder="" value={settings.job || ''} onChange={onInputChange} />
                                                                {errors.job && <div className="error-message" style={{ marginTop: '10px' }}>{errors.job}</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <div className="form-group">
                                                                <label htmlFor="cnpj">{t('CNPJ/CPF')}*</label>
                                                                <input className={`form-control ${errors.cnpj ? 'border-danger' : ''}`} id="cnpj" type="text" placeholder="" value={settings.cnpj || ''} onChange={onInputChange} />
                                                                {errors.cnpj && <div className="error-message" style={{ marginTop: '10px' }}>{errors.cnpj}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 mb-3">
                                                            <div className="form-group">
                                                            <label htmlFor="cnpj">{t('Experiências')}</label>
                                                            <textarea className="form-control" id="experiences" placeholder={t('Descreva sua experiência, especialidade, anos de atuação, principais habilidades e projetos realizados.')} style={{
                                                                    height: 'auto',
                                                                    minHeight: '20px',
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'pre-wrap',
                                                                    wordWrap: 'break-word'
                                                                }}  value={settings.experiences || ''} onChange={onInputChange}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            <div className="form-group">
                                                                <label htmlFor="categoriaS">{t('Categoria')}*</label>
                                                                <SelectCategoryS value={settings.categoriaS} onChange={onCategorySChange} className={errors.categoriaS ? 'error' : ''}/>
                                                                {errors.categoriaS && <div className="error-message" style={{ marginTop: '10px' }}>{errors.categoriaS}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                            <h2 className="h5 mb-4">{t('Redes Sociais')}</h2>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <div className="form-group">
                                                        <div className="d-flex align-items-center">
                                                            <label htmlFor="facebook" className="me-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#1877F2" className="icon icon-xs me-2">
                                                                    <path d="M22.675 0h-21.35c-.734 0-1.325.592-1.325 1.325v21.351c0 .733.591 1.324 1.325 1.324h11.498v-9.294h-3.128v-3.622h3.128v-2.672c0-3.1 1.893-4.785 4.658-4.785 1.324 0 2.463.098 2.796.142v3.24h-1.918c-1.504 0-1.796.715-1.796 1.763v2.312h3.587l-.467 3.622h-3.12v9.294h6.116c.734 0 1.325-.591 1.325-1.324v-21.35c0-.733-.591-1.325-1.325-1.325z"/>
                                                                </svg>
                                                            </label>
                                                            <input className={`form-control ${errors.facebook ? 'border-danger' : ''}`} id="facebook" type="text" placeholder={settings.priority >= 1 ? "Facebook URL" : t('Plano Premium') } disabled={settings.priority === 0} value={settings.facebook || ''} onChange={onInputChange} />
                                                        </div>
                                                        {errors.facebook && <div className="error-message" style={{ marginTop: '10px' }}>{errors.facebook}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <div className="form-group">
                                                        <div className="d-flex align-items-center">
                                                            <label htmlFor="instagram" className="me-2">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 132.004 132"
                                                                    className="icon icon-xs me-2"
                                                                >
                                                                    <defs>
                                                                    <linearGradient id="b">
                                                                        <stop offset="0" stopColor="#3771c8" />
                                                                        <stop stopColor="#3771c8" offset=".128" />
                                                                        <stop offset="1" stopColor="#60f" stopOpacity="0" />
                                                                    </linearGradient>
                                                                    <linearGradient id="a">
                                                                        <stop offset="0" stopColor="#fd5" />
                                                                        <stop offset=".1" stopColor="#fd5" />
                                                                        <stop offset=".5" stopColor="#ff543e" />
                                                                        <stop offset="1" stopColor="#c837ab" />
                                                                    </linearGradient>
                                                                    <radialGradient
                                                                        id="c"
                                                                        cx="158.429"
                                                                        cy="578.088"
                                                                        r="65"
                                                                        gradientUnits="userSpaceOnUse"
                                                                        gradientTransform="matrix(0 -1.98198 1.8439 0 -1031.402 454.004)"
                                                                        fx="158.429"
                                                                        fy="578.088"
                                                                        xlinkHref="#a"
                                                                    />
                                                                    <radialGradient
                                                                        id="d"
                                                                        cx="147.694"
                                                                        cy="473.455"
                                                                        r="65"
                                                                        gradientUnits="userSpaceOnUse"
                                                                        gradientTransform="matrix(.17394 .86872 -3.5818 .71718 1648.348 -458.493)"
                                                                        fx="147.694"
                                                                        fy="473.455"
                                                                        xlinkHref="#b"
                                                                    />
                                                                    </defs>
                                                                    <path
                                                                    fill="url(#c)"
                                                                    d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
                                                                    transform="translate(1.004 1)"
                                                                    />
                                                                    <path
                                                                    fill="url(#d)"
                                                                    d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
                                                                    transform="translate(1.004 1)"
                                                                    />
                                                                    <path
                                                                    fill="#fff"
                                                                    d="M66.004 18c-13.036 0-14.672.057-19.792.29-5.11.234-8.598 1.043-11.65 2.23-3.157 1.226-5.835 2.866-8.503 5.535-2.67 2.668-4.31 5.346-5.54 8.502-1.19 3.053-2 6.542-2.23 11.65C18.06 51.327 18 52.964 18 66s.058 14.667.29 19.787c.235 5.11 1.044 8.598 2.23 11.65 1.227 3.157 2.867 5.835 5.536 8.503 2.667 2.67 5.345 4.314 8.5 5.54 3.054 1.187 6.543 1.996 11.652 2.23 5.12.233 6.755.29 19.79.29 13.037 0 14.668-.057 19.788-.29 5.11-.234 8.602-1.043 11.656-2.23 3.156-1.226 5.83-2.87 8.497-5.54 2.67-2.668 4.31-5.346 5.54-8.502 1.18-3.053 1.99-6.542 2.23-11.65.23-5.12.29-6.752.29-19.788 0-13.036-.06-14.672-.29-19.792-.24-5.11-1.05-8.598-2.23-11.65-1.23-3.157-2.87-5.835-5.54-8.503-2.67-2.67-5.34-4.31-8.5-5.535-3.06-1.187-6.55-1.996-11.66-2.23-5.12-.233-6.75-.29-19.79-.29zm-4.306 8.65c1.278-.002 2.704 0 4.306 0 12.816 0 14.335.046 19.396.276 4.68.214 7.22.996 8.912 1.653 2.24.87 3.837 1.91 5.516 3.59 1.68 1.68 2.72 3.28 3.592 5.52.657 1.69 1.44 4.23 1.653 8.91.23 5.06.28 6.58.28 19.39s-.05 14.33-.28 19.39c-.214 4.68-.996 7.22-1.653 8.91-.87 2.24-1.912 3.835-3.592 5.514-1.68 1.68-3.275 2.72-5.516 3.59-1.69.66-4.232 1.44-8.912 1.654-5.06.23-6.58.28-19.396.28-12.817 0-14.336-.05-19.396-.28-4.68-.216-7.22-.998-8.913-1.655-2.24-.87-3.84-1.91-5.52-3.59-1.68-1.68-2.72-3.276-3.592-5.517-.657-1.69-1.44-4.23-1.653-8.91-.23-5.06-.276-6.58-.276-19.398s.046-14.33.276-19.39c.214-4.68.996-7.22 1.653-8.912.87-2.24 1.912-3.84 3.592-5.52 1.68-1.68 3.28-2.72 5.52-3.592 1.692-.66 4.233-1.44 8.913-1.655 4.428-.2 6.144-.26 15.09-.27zm29.928 7.97c-3.18 0-5.76 2.577-5.76 5.758 0 3.18 2.58 5.76 5.76 5.76 3.18 0 5.76-2.58 5.76-5.76 0-3.18-2.58-5.76-5.76-5.76zm-25.622 6.73c-13.613 0-24.65 11.037-24.65 24.65 0 13.613 11.037 24.645 24.65 24.645C79.617 90.645 90.65 79.613 90.65 66S79.616 41.35 66.003 41.35zm0 8.65c8.836 0 16 7.163 16 16 0 8.836-7.164 16-16 16-8.837 0-16-7.164-16-16 0-8.837 7.163-16 16-16z"
                                                                    />
                                                                </svg>
                                                            </label>
                                                            <input className={`form-control ${errors.instagram ? 'border-danger' : ''}`} id="instagram" type="text" placeholder={settings.priority >= 1 ? "Instagram URL" : t('Plano Premium') } disabled={settings.priority === 0} value={settings.instagram || ''} onChange={onInputChange} />
                                                        </div>
                                                        {errors.instagram && <div className="error-message" style={{ marginTop: '10px' }}>{errors.instagram}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <div className="form-group">
                                                        <div className="d-flex align-items-center">
                                                            <label htmlFor="x" className="me-2">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlSpace="preserve"
                                                                    className="icon icon-xs me-2"
                                                                    version="1.1"
                                                                    style={{
                                                                    shapeRendering: "geometricPrecision",
                                                                    textRendering: "geometricPrecision",
                                                                    imageRendering: "optimizeQuality",
                                                                    fillRule: "evenodd",
                                                                    clipRule: "evenodd"
                                                                    }}
                                                                    viewBox="0 0 211.07 210.92"
                                                                >
                                                                    <defs>
                                                                    <style type="text/css">
                                                                        {`
                                                                        .fil1 {fill:#040404}
                                                                        .fil0 {fill:#FAFAFA}
                                                                        `}
                                                                    </style>
                                                                    </defs>
                                                                    <g id="Layer_x0020_1">
                                                                    <metadata id="CorelCorpID_0Corel-Layer" />
                                                                    <g id="X_icon_2.svg">
                                                                        <g>
                                                                        <path
                                                                            className="fil0"
                                                                            d="M43.28 43.06c0.42,0.61 10.26,14.93 21.86,31.82 11.6,16.9 22.33,32.52 23.84,34.72 1.51,2.2 2.75,4.05 2.75,4.1 0,0.05 -0.56,0.75 -1.25,1.54 -0.69,0.8 -2.63,3.05 -4.31,5 -1.68,1.96 -4.52,5.25 -6.3,7.32 -1.78,2.07 -4.92,5.72 -6.97,8.11 -2.05,2.39 -5.74,6.68 -8.2,9.53 -7.6,8.84 -8.7,10.11 -14.48,16.85 -3.09,3.6 -6.05,7.04 -6.58,7.65 -0.53,0.61 -0.97,1.17 -0.97,1.24 0,0.09 1.94,0.14 5.47,0.14l5.47 0 6.01 -7c3.31,-3.85 6.57,-7.64 7.25,-8.43 1.47,-1.69 12.68,-14.74 13.62,-15.85 0.36,-0.43 0.89,-1.04 1.16,-1.35 0.28,-0.32 2.49,-2.88 4.9,-5.69 2.42,-2.82 4.46,-5.19 4.54,-5.27 0.08,-0.08 1.36,-1.57 2.84,-3.31 1.48,-1.73 2.74,-3.15 2.8,-3.15 0.06,0 7.67,11.02 16.92,24.5 9.25,13.47 16.98,24.74 17.19,25.03l0.37 0.53 18.75 0c15.42,0 18.74,-0.03 18.68,-0.18 -0.06,-0.16 -9.05,-13.27 -32.1,-46.82 -16.59,-24.15 -18.8,-27.4 -18.74,-27.56 0.06,-0.16 2.32,-2.8 17.18,-20.1 2.55,-2.97 6.06,-7.06 7.81,-9.09 1.75,-2.03 3.51,-4.08 3.92,-4.55 0.41,-0.47 2.56,-2.97 4.77,-5.55 2.22,-2.58 5.99,-6.97 8.37,-9.75 2.39,-2.78 4.43,-5.16 4.54,-5.3 0.18,-0.23 -0.13,-0.24 -5.36,-0.24l-5.55 0 -2.47 2.88c-3.29,3.83 -9.23,10.74 -10.92,12.7 -0.75,0.86 -1.68,1.95 -2.07,2.41 -0.39,0.46 -1.16,1.36 -1.71,1.99 -0.55,0.63 -3.33,3.87 -6.18,7.19 -2.85,3.32 -5.22,6.08 -5.26,6.12 -0.05,0.04 -0.66,0.75 -1.36,1.57 -1.22,1.45 -2.46,2.89 -8.16,9.5 -2.5,2.9 -2.63,3.03 -2.84,2.76 -0.12,-0.15 -7.45,-10.82 -16.29,-23.7l-16.07 -23.42 -18.82 -0 -18.82 -0 0.77 1.1 0 0zm14.69 7.68c0.14,0.22 3.66,5.26 7.81,11.2 7.88,11.27 38.86,55.61 58.78,84.13 6.37,9.11 11.65,16.65 11.75,16.75 0.14,0.14 1.99,0.17 8.66,0.14l8.49 -0.04 -22.21 -31.79c-12.22,-17.48 -29.9,-42.8 -39.31,-56.25l-17.09 -24.46 -8.57 -0.04 -8.57 -0.04 0.26 0.39z"
                                                                        />
                                                                        </g>
                                                                        <g>
                                                                        <path
                                                                            className="fil1"
                                                                            d="M-0 105.46l0 105.46 105.5 -0.04 105.5 -0.04 0.04 -105.43 0.04 -105.43 -105.53 0 -105.53 0 0 105.46 0 0zm0.07 0.07c0,58 0.02,81.73 0.04,52.73 0.02,-29 0.02,-76.46 0,-105.46 -0.02,-29 -0.04,-5.27 -0.04,52.73l0 0zm43.21 -62.47c0.42,0.61 10.26,14.93 21.86,31.82 11.6,16.9 22.33,32.52 23.84,34.72 1.51,2.2 2.75,4.05 2.75,4.1 0,0.05 -0.56,0.75 -1.25,1.54 -0.69,0.8 -2.63,3.05 -4.31,5 -1.68,1.96 -4.52,5.25 -6.3,7.32 -1.78,2.07 -4.92,5.72 -6.97,8.11 -2.05,2.39 -5.74,6.68 -8.2,9.53 -7.6,8.84 -8.7,10.11 -14.48,16.85 -3.09,3.6 -6.05,7.04 -6.58,7.65 -0.53,0.61 -0.97,1.17 -0.97,1.24 0,0.09 1.94,0.14 5.47,0.14l5.47 0 6.01 -7c3.31,-3.85 6.57,-7.64 7.25,-8.43 1.47,-1.69 12.68,-14.74 13.62,-15.85 0.36,-0.43 0.89,-1.04 1.16,-1.35 0.28,-0.32 2.49,-2.88 4.9,-5.69 2.42,-2.82 4.46,-5.19 4.54,-5.27 0.08,-0.08 1.36,-1.57 2.84,-3.31 1.48,-1.73 2.74,-3.15 2.8,-3.15 0.06,0 7.67,11.02 16.92,24.5 9.25,13.47 16.98,24.74 17.19,25.03l0.37 0.53 18.75 0c15.42,0 18.74,-0.03 18.68,-0.18 -0.06,-0.16 -9.05,-13.27 -32.1,-46.82 -16.59,-24.15 -18.8,-27.4 -18.74,-27.56 0.06,-0.16 2.32,-2.8 17.18,-20.1 2.55,-2.97 6.06,-7.06 7.81,-9.09 1.75,-2.03 3.51,-4.08 3.92,-4.55 0.41,-0.47 2.56,-2.97 4.77,-5.55 2.22,-2.58 5.99,-6.97 8.37,-9.75 2.39,-2.78 4.43,-5.16 4.54,-5.3 0.18,-0.23 -0.13,-0.24 -5.36,-0.24l-5.55 0 -2.47 2.88c-3.29,3.83 -9.23,10.74 -10.92,12.7 -0.75,0.86 -1.68,1.95 -2.07,2.41 -0.39,0.46 -1.16,1.36 -1.71,1.99 -0.55,0.63 -3.33,3.87 -6.18,7.19 -2.85,3.32 -5.22,6.08 -5.26,6.12 -0.05,0.04 -0.66,0.75 -1.36,1.57 -1.22,1.45 -2.46,2.89 -8.16,9.5 -2.5,2.9 -2.63,3.03 -2.84,2.76 -0.12,-0.15 -7.45,-10.82 -16.29,-23.7l-16.07 -23.42 -18.82 -0 -18.82 -0 0.77 1.1 0 0zm14.69 7.68c0.14,0.22 3.66,5.26 7.81,11.2 7.88,11.27 38.86,55.61 58.78,84.13 6.37,9.11 11.65,16.65 11.75,16.75 0.14,0.14 1.99,0.17 8.66,0.14l8.49 -0.04 -22.21 -31.79c-12.22,-17.48 -29.9,-42.8 -39.31,-56.25l-17.09 -24.46 -8.57 -0.04 -8.57 -0.04 0.26 0.39z"
                                                                        />
                                                                        </g>
                                                                    </g>
                                                                    </g>
                                                                </svg>
                                                            </label>
                                                            <input className={`form-control ${errors.x ? 'border-danger' : ''}`} id="x" type="text" placeholder={settings.priority >= 1 ? "X URL" : t('Plano Premium') } disabled={settings.priority === 0} value={settings.twitter || ''} onChange={onInputChange} />
                                                        </div>
                                                        {errors.x && <div className="error-message" style={{ marginTop: '10px' }}>{errors.x}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <div className="form-group">
                                                        <div className="d-flex align-items-center">
                                                            <label htmlFor="tiktok" className="me-2">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 512 512"
                                                                    className="icon icon-xs me-2"
                                                                >
                                                                    <defs>
                                                                        <style>
                                                                            {`
                                                                            .cls-1 { fill: #00f6ef; }
                                                                            .cls-2 { fill: #fff; }
                                                                            .cls-3 { fill: #ff004f; }
                                                                            `}
                                                                        </style>
                                                                    </defs>
                                                                    <rect height="512" rx="71.15" width="512" x="0" y="0" />
                                                                    <path
                                                                        className="cls-1"
                                                                        d="M389.39,221.92V164.85c-74.6-3.15-77-70.94-77-77.31v-.48H254.73V309.33h0a45.66,45.66,0,1,1-32.36-43.71V206.76a104.57,104.57,0,0,0-13.32-.85A103.42,103.42,0,1,0,312.47,309.33c0-1.45,0-2.89-.1-4.32V195.56C338.92,219.85,389.39,221.92,389.39,221.92Z"
                                                                    />
                                                                    <path
                                                                        className="cls-2"
                                                                        d="M406.37,236V178.9c-74.61-3.15-77-70.94-77-77.31v-.48H271.71V323.38h0a45.66,45.66,0,1,1-32.36-43.7V220.81A104.57,104.57,0,0,0,226,220,103.42,103.42,0,1,0,329.45,323.38c0-1.45,0-2.89-.1-4.32V209.61C355.9,233.9,406.37,236,406.37,236Z"
                                                                    />
                                                                    <path
                                                                        className="cls-3"
                                                                        d="M313.82,101.11c2.78,15.14,10.9,38.81,34.57,52.66-18.09-21.07-19-48.26-19-52.18v-.48Z"
                                                                    />
                                                                    <path
                                                                        className="cls-3"
                                                                        d="M406.37,236V178.9a106.46,106.46,0,0,1-17-2v44.95s-50.47-2.07-77-26.36V304.91c.06,1.43.1,2.87.1,4.32a103.43,103.43,0,0,1-160.72,86.1,103.41,103.41,0,0,0,177.7-71.95c0-1.45,0-2.89-.1-4.32V209.61C355.9,233.9,406.37,236,406.37,236Z"
                                                                    />
                                                                    <path
                                                                        className="cls-3"
                                                                        d="M222.37,265.53a45.69,45.69,0,0,0-33.19,84.85,45.69,45.69,0,0,1,50.17-70.7V220.81A104.57,104.57,0,0,0,226,220c-1.23,0-2.44,0-3.66.07Z"
                                                                    />
                                                                </svg>
                                                            </label>
                                                            <input className={`form-control ${errors.tiktok ? 'border-danger' : ''}`} id="tiktok" type="text" placeholder={settings.priority >= 1 ? "TikTok URL" : t('Plano Premium') } disabled={settings.priority === 0} value={settings.tiktok || ''} onChange={onInputChange} />
                                                        </div>
                                                        {errors.tiktok && <div className="error-message" style={{ marginTop: '10px' }}>{errors.tiktok}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <div className="form-group">
                                                        <div className="d-flex align-items-center">
                                                            <label htmlFor="linkedin" className="me-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#0A66C2" className="icon icon-xs me-2">
                                                                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5v-14c0-2.761-2.239-5-5-5zm-11 19h-3v-10h3v10zm-1.5-11.249c-.966 0-1.75-.785-1.75-1.751s.784-1.75 1.75-1.75 1.75.784 1.75 1.75-.784 1.751-1.75 1.751zm13.5 11.249h-3v-5.5c0-1.379-1.121-2.5-2.5-2.5s-2.5 1.121-2.5 2.5v5.5h-3v-10h3v1.25c.878-.926 2.144-1.5 3.5-1.5 2.481 0 4.5 2.019 4.5 4.5v5.75z"/>
                                                                </svg>
                                                            </label>
                                                            <input className={`form-control ${errors.linkedin ? 'border-danger' : ''}`} id="linkedin" type="text" placeholder={settings.priority >= 1 ? "LinkedIn URL" : t('Plano Premium') } disabled={settings.priority === 0} value={settings.linkedin || ''} onChange={onInputChange} />
                                                        </div>
                                                        {errors.linkedin && <div className="error-message" style={{ marginTop: '10px' }}>{errors.linkedin}</div>}
                                                    </div>
                                                </div>
                                            </div>                                          
                                            <h2 className="h5 mb-4">{t('Acesso')}</h2>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="password">{t('Senha')}</label>
                                                        <input className={`form-control ${errors.password ? 'border-danger' : ''}`} id="password" type="password" placeholder={t('Digite sua senha')} value={settings.password || ''} onChange={onInputChange} />
                                                        {errors.password && <div className="error-message" style={{ marginTop: '10px' }}>{errors.password}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="confirmPassword">{t('Confirme a Senha')}</label>
                                                        <input ref={confirmPassword} className={`form-control ${errors.confirmPassword ? 'border-danger' : ''}`} id="confirmPassword" type="password" placeholder={t('Confirme sua senha')} />
                                                        {errors.confirmPassword && <div className="error-message" style={{ marginTop: '10px' }}>{errors.confirmPassword}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="fa" checked={settings.fa} onChange={onCheckboxChange} />
                                                        <label className="form-check-label" htmlFor="fa">{t('Ativar autenticação de dois fatores (2FA)')}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <button className="btn btn-primary" onClick={onFormSubmit} >{t('Salvar')}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {settings.type !== '1' && (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card card-body border-0 shadow mb-4">
                                            <h2 className="h5 mb-4">{t('Plano')}</h2>
                                            <div className="alert alert-info">
                                                {settings.priority === 0 ? t('Grátis') : t('Premium')} (
                                                <Link to="/planoptions" className="alert-link">
                                                    {settings.priority === 0 ? t('O premium te espera.') : t('Sinta a diferença.')}
                                                </Link>)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <Footer />
                        </main>
                    </React.Fragment>)
                : (
                    <React.Fragment>
                        <Menu />
                        <main className="content">
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                                <div className="d-block mb-4 mb-md-0">
                                    <h1 className="h4">{t('Perfil')}</h1>
                                </div>
                            </div>
                            <div className="card card-body border-0 shadow mb-4">
                                <div className="row">
                                    <div className="col-md-6 d-flex flex-column align-items-center">
                                        <label htmlFor="avatarInput" className="cursor-pointer">
                                            <Avatar imageUrl={avatarImage ? URL.createObjectURL(avatarImage) : `data:image/png;base64,${avatarBase64}`} alt="Avatar" size="large" frameStyle="double-shadow-blue" />
                                            <input id="avatarInput" type="file" onChange={handleAvatarChange} accept="image/*" style={{ display: 'none' }} />
                                        </label>
                                        <h2 className="h5 mt-2">{t('Fodo do Perfil')}</h2>
                                        <button className="btn btn-primary mt-2 mb-4" onClick={handleSaveAvatar}>{t('Salvar Foto')}</button>
                                    </div>
                                    {settings.type !== '1' && (
                                        <div className="col-md-6 d-flex flex-column align-items-center">
                                            <label htmlFor="brandLogoInput" className="cursor-pointer">
                                                <BrandLogo imageUrl={brandImage ? URL.createObjectURL(brandImage) : `data:image/png;base64,${brandBase64}`} alt="Brand Logo" size="large" frameStyle="double-shadow-blue" />
                                                <input id="brandLogoInput" type="file" onChange={handleBrandChange} accept="image/*" style={{ display: 'none' }} />
                                            </label>
                                            <h2 className="h5 mt-2">
                                                {t('Logo')}
                                            </h2>
                                            {settings.priority === 0 && (<span style={{ fontSize: 9, marginTop: -10 }}> (Plano Premium)</span>)}
                                            <button className="btn btn-primary mt-2" disabled={settings.priority === 0} onClick={handleSaveBrand}>{t('Salvar Logo')}</button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card card-body border-0 shadow mb-4">
                                        <h2 className="h5 mb-4">{settings.type === '3' ? t('Dados do Responsável') : t('Dados Pessoais')}</h2>
                                        <form onSubmit={onFormSubmit}>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="name">{t('Nome')}*</label>
                                                        <input className={`form-control ${errors.name ? 'border-danger' : ''}`} id="name" type="text" placeholder="" value={settings.name || ''} onChange={onInputChange} />
                                                        {errors.name && <div className="error-message" style={{ marginTop: '10px' }}>{errors.name}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="sobrenamo">{t('Sobrenome')}*</label>
                                                        <input className={`form-control ${errors.sobrenamo ? 'border-danger' : ''}`} id="sobrenamo" type="text" placeholder="" value={settings.sobrenamo || ''} onChange={onInputChange} />
                                                        {errors.sobrenamo && <div className="error-message" style={{ marginTop: '10px' }}>{errors.sobrenamo}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="nickname">{t('Chat Apelido')}*</label><span className="small">(Max. 15)</span>
                                                        <input className={`form-control ${errors.nickname ? 'border-danger' : ''}`} id="nickname" type="text" placeholder="" value={settings.nickname || ''} onChange={onInputChange} />
                                                        {errors.nickname && <div className="error-message" style={{ marginTop: '10px' }}>{errors.nickname}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="email">{t('E-mail')}*</label>
                                                        <input className={`form-control ${errors.email ? 'border-danger' : ''}`} id="email" type="email" placeholder="name@company.com" value={settings.email || ''} onChange={onInputChange} />
                                                        {errors.email && <div className="error-message" style={{ marginTop: '10px' }}>{errors.email}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="phone">{t('Telefone')}*</label>
                                                        <input className={`form-control ${errors.phone ? 'border-danger' : ''}`} id="phone" type="tel" placeholder="Ex: (99) 99999-9999" value={settings.phone || ''} onChange={onInputChange} />
                                                        {errors.phone && <div className="error-message" style={{ marginTop: '10px' }}>{errors.phone}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="birthdate">{t('Data de Nascimento')}*</label>
                                                        <input className={`form-control ${errors.birthdate ? 'border-danger' : ''}`} id="birthdate" type="date" placeholder="" value={settings.birthdate ? settings.birthdate.split('T')[0] : ''} onChange={onInputChange} />
                                                        {errors.birthdate && <div className="error-message" style={{ marginTop: '10px' }}>{errors.birthdate}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            {settings.type === '3' && (
                                                <React.Fragment>
                                                    <h2 className="h5 mb-4">{t('Dados da Empresa')}*</h2>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <div className="form-group">
                                                                <label htmlFor="site">{t('Site')}</label>
                                                                <input className={`form-control ${errors.site ? 'border-danger' : ''}`} id="site" type="text" placeholder={settings.priority >= 1 ? "https://www..." : t('Plano Premium') } disabled={settings.priority === 0} value={settings.site || ''} onChange={onInputChange} />
                                                                {errors.site && <div className="error-message" style={{ marginTop: '10px' }}>{errors.site}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            <div className="form-group">
                                                                <label htmlFor="job">{t('Razão Social')}*</label>
                                                                <input className={`form-control ${errors.job ? 'border-danger' : ''}`} id="job" type="text" placeholder="" value={settings.job || ''} onChange={onInputChange} />
                                                                {errors.job && <div className="error-message" style={{ marginTop: '10px' }}>{errors.job}</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <div className="form-group">
                                                                <label htmlFor="cnpj">{t('CNPJ')}*</label>
                                                                <input className={`form-control ${errors.cnpj ? 'border-danger' : ''}`} id="cnpj" type="text" placeholder="" value={settings.cnpj || ''} onChange={onInputChange} />
                                                                {errors.cnpj && <div className="error-message" style={{ marginTop: '10px' }}>{errors.cnpj}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 mb-3">
                                                            <div className="form-group">
                                                            <label htmlFor="cnpj">{t('Experiências')}</label>
                                                            <textarea className="form-control" id="experiences" placeholder={t('Descreva sua experiência, especialidade, anos de atuação, principais habilidades e projetos realizados.')} style={{
                                                                    height: 'auto',
                                                                    minHeight: '20px',
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'pre-wrap',
                                                                    wordWrap: 'break-word'
                                                                }}  value={settings.experiences || ''} onChange={onInputChange}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            <div className="form-group">
                                                                <label htmlFor="categoriaF">{t('Categoria')}*</label>
                                                                <SelectCategoryF value={settings.categoriaF} onChange={onCategoryFChange} className={errors.categoriaF ? 'error' : ''}/>
                                                                {errors.categoriaF && <div className="error-message" style={{ marginTop: '10px' }}>{errors.categoriaF}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                            <h2 className="h5 mb-4">{t('Endereço')}</h2>
                                            <div className="row">
                                                <div className="col-md-2 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="cep">{t('CEP')}*</label>
                                                        <input className={`form-control ${errors.cep ? 'border-danger' : ''}`} id="cep" type="text" placeholder="Ex: 82590200" value={settings.cep || ''} onChange={onInputChange} />
                                                        {errors.cep && <div className="error-message" style={{ marginTop: '10px' }}>{errors.cep}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="street">{t('Rua')}*</label>
                                                        <input className={`form-control ${errors.street ? 'border-danger' : ''}`} id="street" type="text" placeholder="Rua, Av..." value={settings.street || ''} onChange={onInputChange} />
                                                        {errors.street && <div className="error-message" style={{ marginTop: '10px' }}>{errors.street}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-2 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="number">{t('Número')}*</label>
                                                        <input className={`form-control ${errors.number ? 'border-danger' : ''}`} id="number" type="text" placeholder="" value={settings.number || ''} onChange={onInputChange} />
                                                        {errors.number && <div className="error-message" style={{ marginTop: '10px' }}>{errors.number}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="complement">{t('Complemento')}</label>
                                                        <input className={`form-control ${errors.complement ? 'border-danger' : ''}`} id="complement" type="text" placeholder="Condomínio, Apto, Bloco" value={settings.complement || ''} onChange={onInputChange} />
                                                        {errors.complement && <div className="error-message" style={{ marginTop: '10px' }}>{errors.complement}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="neighborhood">{t('Bairro')}*</label>
                                                        <input className={`form-control ${errors.neighborhood ? 'border-danger' : ''}`} id="neighborhood" type="text" placeholder="" value={settings.neighborhood || ''} onChange={onInputChange} />
                                                        {errors.neighborhood && <div className="error-message" style={{ marginTop: '10px' }}>{errors.neighborhood}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="city">{t('Cidade')}*</label>
                                                        <input className={`form-control ${errors.city ? 'border-danger' : ''}`} id="city" type="text" placeholder="" value={settings.city || ''} onChange={onInputChange} />
                                                        {errors.city && <div className="error-message" style={{ marginTop: '10px' }}>{errors.city}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-1 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="state">{t('Estado')}*</label>
                                                        <input className={`form-control ${errors.state ? 'border-danger' : ''}`} id="state" type="text" placeholder="" value={settings.state || ''} onChange={onInputChange} />
                                                        {errors.state && <div className="error-message" style={{ marginTop: '10px' }}>{errors.state}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="country">{t('País')}*</label>
                                                        <input className={`form-control ${errors.country ? 'border-danger' : ''}`} id="country" type="text" placeholder="" value={settings.country || ''} onChange={onInputChange} />
                                                        {errors.country && <div className="error-message" style={{ marginTop: '10px' }}>{errors.country}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            { settings.state && settings.priority >=1 && settings.type !== '1' && ( <>
                                                <h2 className="h5 mb-4">{t('Outras Regiões Atendidas')}</h2>
                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <div className="form-group">
                                                            <label htmlFor="othercity">{t('Cidades')}</label>
                                                            <SelectCities value={settings.othercity} state={settings.state} onChange={onCitiesChange}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                            {settings.type === '2' && (
                                                <React.Fragment>
                                                    <h2 className="h5 mb-4">{t('Dados do Serviço')}</h2>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <div className="form-group">
                                                                <label htmlFor="site">{t('Site')}*</label>
                                                                <input className={`form-control ${errors.site ? 'border-danger' : ''}`} id="site" type="text" placeholder={settings.priority >= 1 ? "https://www..." : t('Plano Premium') } disabled={settings.priority === 0} value={settings.site || ''} onChange={onInputChange} />
                                                                {errors.site && <div className="error-message" style={{ marginTop: '10px' }}>{errors.site}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            <div className="form-group">
                                                                <label htmlFor="job">{t('Profissão')}*</label>
                                                                <input className={`form-control ${errors.job ? 'border-danger' : ''}`} id="job" type="text" placeholder="" value={settings.job || ''} onChange={onInputChange} />
                                                                {errors.job && <div className="error-message" style={{ marginTop: '10px' }}>{errors.job}</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <div className="form-group">
                                                                <label htmlFor="cnpj">{t('CNPJ/CPF')}*</label>
                                                                <input className={`form-control ${errors.cnpj ? 'border-danger' : ''}`} id="cnpj" type="text" placeholder="" value={settings.cnpj || ''} onChange={onInputChange} />
                                                                {errors.cnpj && <div className="error-message" style={{ marginTop: '10px' }}>{errors.cnpj}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 mb-3">
                                                            <div className="form-group">
                                                            <label htmlFor="cnpj">{t('Experiências')}</label>
                                                            <textarea className="form-control" id="experiences" placeholder={t('Descreva sua experiência, especialidade, anos de atuação, principais habilidades e projetos realizados.')} style={{
                                                                    height: 'auto',
                                                                    minHeight: '20px',
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'pre-wrap',
                                                                    wordWrap: 'break-word'
                                                                }}  value={settings.experiences || ''} onChange={onInputChange}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            <div className="form-group">
                                                                <label htmlFor="categoriaS">{t('Categoria')}*</label>
                                                                <SelectCategoryS value={settings.categoriaS} onChange={onCategorySChange} className={errors.categoriaS ? 'error' : ''}/>
                                                                {errors.categoriaS && <div className="error-message" style={{ marginTop: '10px' }}>{errors.categoriaS}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                            <h2 className="h5 mb-4">{t('Redes Sociais')}</h2>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <div className="form-group">
                                                        <div className="d-flex align-items-center">
                                                            <label htmlFor="facebook" className="me-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#1877F2" className="icon icon-xs me-2">
                                                                    <path d="M22.675 0h-21.35c-.734 0-1.325.592-1.325 1.325v21.351c0 .733.591 1.324 1.325 1.324h11.498v-9.294h-3.128v-3.622h3.128v-2.672c0-3.1 1.893-4.785 4.658-4.785 1.324 0 2.463.098 2.796.142v3.24h-1.918c-1.504 0-1.796.715-1.796 1.763v2.312h3.587l-.467 3.622h-3.12v9.294h6.116c.734 0 1.325-.591 1.325-1.324v-21.35c0-.733-.591-1.325-1.325-1.325z"/>
                                                                </svg>
                                                            </label>
                                                            <input className={`form-control ${errors.facebook ? 'border-danger' : ''}`} id="facebook" type="text" placeholder={settings.priority >= 1 ? "Facebook URL" : t('Plano Premium') } disabled={settings.priority === 0} value={settings.facebook || ''} onChange={onInputChange} />
                                                        </div>
                                                        {errors.facebook && <div className="error-message" style={{ marginTop: '10px' }}>{errors.facebook}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <div className="form-group">
                                                        <div className="d-flex align-items-center">
                                                            <label htmlFor="instagram" className="me-2">
                                                            <label htmlFor="instagram" className="me-2">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 132.004 132"
                                                                    className="icon icon-xs me-2"
                                                                >
                                                                    <defs>
                                                                    <linearGradient id="b">
                                                                        <stop offset="0" stopColor="#3771c8" />
                                                                        <stop stopColor="#3771c8" offset=".128" />
                                                                        <stop offset="1" stopColor="#60f" stopOpacity="0" />
                                                                    </linearGradient>
                                                                    <linearGradient id="a">
                                                                        <stop offset="0" stopColor="#fd5" />
                                                                        <stop offset=".1" stopColor="#fd5" />
                                                                        <stop offset=".5" stopColor="#ff543e" />
                                                                        <stop offset="1" stopColor="#c837ab" />
                                                                    </linearGradient>
                                                                    <radialGradient
                                                                        id="c"
                                                                        cx="158.429"
                                                                        cy="578.088"
                                                                        r="65"
                                                                        gradientUnits="userSpaceOnUse"
                                                                        gradientTransform="matrix(0 -1.98198 1.8439 0 -1031.402 454.004)"
                                                                        fx="158.429"
                                                                        fy="578.088"
                                                                        xlinkHref="#a"
                                                                    />
                                                                    <radialGradient
                                                                        id="d"
                                                                        cx="147.694"
                                                                        cy="473.455"
                                                                        r="65"
                                                                        gradientUnits="userSpaceOnUse"
                                                                        gradientTransform="matrix(.17394 .86872 -3.5818 .71718 1648.348 -458.493)"
                                                                        fx="147.694"
                                                                        fy="473.455"
                                                                        xlinkHref="#b"
                                                                    />
                                                                    </defs>
                                                                    <path
                                                                    fill="url(#c)"
                                                                    d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
                                                                    transform="translate(1.004 1)"
                                                                    />
                                                                    <path
                                                                    fill="url(#d)"
                                                                    d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
                                                                    transform="translate(1.004 1)"
                                                                    />
                                                                    <path
                                                                    fill="#fff"
                                                                    d="M66.004 18c-13.036 0-14.672.057-19.792.29-5.11.234-8.598 1.043-11.65 2.23-3.157 1.226-5.835 2.866-8.503 5.535-2.67 2.668-4.31 5.346-5.54 8.502-1.19 3.053-2 6.542-2.23 11.65C18.06 51.327 18 52.964 18 66s.058 14.667.29 19.787c.235 5.11 1.044 8.598 2.23 11.65 1.227 3.157 2.867 5.835 5.536 8.503 2.667 2.67 5.345 4.314 8.5 5.54 3.054 1.187 6.543 1.996 11.652 2.23 5.12.233 6.755.29 19.79.29 13.037 0 14.668-.057 19.788-.29 5.11-.234 8.602-1.043 11.656-2.23 3.156-1.226 5.83-2.87 8.497-5.54 2.67-2.668 4.31-5.346 5.54-8.502 1.18-3.053 1.99-6.542 2.23-11.65.23-5.12.29-6.752.29-19.788 0-13.036-.06-14.672-.29-19.792-.24-5.11-1.05-8.598-2.23-11.65-1.23-3.157-2.87-5.835-5.54-8.503-2.67-2.67-5.34-4.31-8.5-5.535-3.06-1.187-6.55-1.996-11.66-2.23-5.12-.233-6.75-.29-19.79-.29zm-4.306 8.65c1.278-.002 2.704 0 4.306 0 12.816 0 14.335.046 19.396.276 4.68.214 7.22.996 8.912 1.653 2.24.87 3.837 1.91 5.516 3.59 1.68 1.68 2.72 3.28 3.592 5.52.657 1.69 1.44 4.23 1.653 8.91.23 5.06.28 6.58.28 19.39s-.05 14.33-.28 19.39c-.214 4.68-.996 7.22-1.653 8.91-.87 2.24-1.912 3.835-3.592 5.514-1.68 1.68-3.275 2.72-5.516 3.59-1.69.66-4.232 1.44-8.912 1.654-5.06.23-6.58.28-19.396.28-12.817 0-14.336-.05-19.396-.28-4.68-.216-7.22-.998-8.913-1.655-2.24-.87-3.84-1.91-5.52-3.59-1.68-1.68-2.72-3.276-3.592-5.517-.657-1.69-1.44-4.23-1.653-8.91-.23-5.06-.276-6.58-.276-19.398s.046-14.33.276-19.39c.214-4.68.996-7.22 1.653-8.912.87-2.24 1.912-3.84 3.592-5.52 1.68-1.68 3.28-2.72 5.52-3.592 1.692-.66 4.233-1.44 8.913-1.655 4.428-.2 6.144-.26 15.09-.27zm29.928 7.97c-3.18 0-5.76 2.577-5.76 5.758 0 3.18 2.58 5.76 5.76 5.76 3.18 0 5.76-2.58 5.76-5.76 0-3.18-2.58-5.76-5.76-5.76zm-25.622 6.73c-13.613 0-24.65 11.037-24.65 24.65 0 13.613 11.037 24.645 24.65 24.645C79.617 90.645 90.65 79.613 90.65 66S79.616 41.35 66.003 41.35zm0 8.65c8.836 0 16 7.163 16 16 0 8.836-7.164 16-16 16-8.837 0-16-7.164-16-16 0-8.837 7.163-16 16-16z"
                                                                    />
                                                                </svg>
                                                            </label>
                                                            </label>
                                                            <input className={`form-control ${errors.instagram ? 'border-danger' : ''}`} id="instagram" type="text" placeholder={settings.priority >= 1 ? "Instagram URL" : t('Plano Premium') } disabled={settings.priority === 0} value={settings.instagram || ''} onChange={onInputChange} />
                                                        </div>
                                                        {errors.instagram && <div className="error-message" style={{ marginTop: '10px' }}>{errors.instagram}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <div className="form-group">
                                                        <div className="d-flex align-items-center">
                                                            <label htmlFor="x" className="me-2">
                                                            <label htmlFor="x" className="me-2">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlSpace="preserve"
                                                                    className="icon icon-xs me-2"
                                                                    version="1.1"
                                                                    style={{
                                                                    shapeRendering: "geometricPrecision",
                                                                    textRendering: "geometricPrecision",
                                                                    imageRendering: "optimizeQuality",
                                                                    fillRule: "evenodd",
                                                                    clipRule: "evenodd"
                                                                    }}
                                                                    viewBox="0 0 211.07 210.92"
                                                                >
                                                                    <defs>
                                                                    <style type="text/css">
                                                                        {`
                                                                        .fil1 {fill:#040404}
                                                                        .fil0 {fill:#FAFAFA}
                                                                        `}
                                                                    </style>
                                                                    </defs>
                                                                    <g id="Layer_x0020_1">
                                                                    <metadata id="CorelCorpID_0Corel-Layer" />
                                                                    <g id="X_icon_2.svg">
                                                                        <g>
                                                                        <path
                                                                            className="fil0"
                                                                            d="M43.28 43.06c0.42,0.61 10.26,14.93 21.86,31.82 11.6,16.9 22.33,32.52 23.84,34.72 1.51,2.2 2.75,4.05 2.75,4.1 0,0.05 -0.56,0.75 -1.25,1.54 -0.69,0.8 -2.63,3.05 -4.31,5 -1.68,1.96 -4.52,5.25 -6.3,7.32 -1.78,2.07 -4.92,5.72 -6.97,8.11 -2.05,2.39 -5.74,6.68 -8.2,9.53 -7.6,8.84 -8.7,10.11 -14.48,16.85 -3.09,3.6 -6.05,7.04 -6.58,7.65 -0.53,0.61 -0.97,1.17 -0.97,1.24 0,0.09 1.94,0.14 5.47,0.14l5.47 0 6.01 -7c3.31,-3.85 6.57,-7.64 7.25,-8.43 1.47,-1.69 12.68,-14.74 13.62,-15.85 0.36,-0.43 0.89,-1.04 1.16,-1.35 0.28,-0.32 2.49,-2.88 4.9,-5.69 2.42,-2.82 4.46,-5.19 4.54,-5.27 0.08,-0.08 1.36,-1.57 2.84,-3.31 1.48,-1.73 2.74,-3.15 2.8,-3.15 0.06,0 7.67,11.02 16.92,24.5 9.25,13.47 16.98,24.74 17.19,25.03l0.37 0.53 18.75 0c15.42,0 18.74,-0.03 18.68,-0.18 -0.06,-0.16 -9.05,-13.27 -32.1,-46.82 -16.59,-24.15 -18.8,-27.4 -18.74,-27.56 0.06,-0.16 2.32,-2.8 17.18,-20.1 2.55,-2.97 6.06,-7.06 7.81,-9.09 1.75,-2.03 3.51,-4.08 3.92,-4.55 0.41,-0.47 2.56,-2.97 4.77,-5.55 2.22,-2.58 5.99,-6.97 8.37,-9.75 2.39,-2.78 4.43,-5.16 4.54,-5.3 0.18,-0.23 -0.13,-0.24 -5.36,-0.24l-5.55 0 -2.47 2.88c-3.29,3.83 -9.23,10.74 -10.92,12.7 -0.75,0.86 -1.68,1.95 -2.07,2.41 -0.39,0.46 -1.16,1.36 -1.71,1.99 -0.55,0.63 -3.33,3.87 -6.18,7.19 -2.85,3.32 -5.22,6.08 -5.26,6.12 -0.05,0.04 -0.66,0.75 -1.36,1.57 -1.22,1.45 -2.46,2.89 -8.16,9.5 -2.5,2.9 -2.63,3.03 -2.84,2.76 -0.12,-0.15 -7.45,-10.82 -16.29,-23.7l-16.07 -23.42 -18.82 -0 -18.82 -0 0.77 1.1 0 0zm14.69 7.68c0.14,0.22 3.66,5.26 7.81,11.2 7.88,11.27 38.86,55.61 58.78,84.13 6.37,9.11 11.65,16.65 11.75,16.75 0.14,0.14 1.99,0.17 8.66,0.14l8.49 -0.04 -22.21 -31.79c-12.22,-17.48 -29.9,-42.8 -39.31,-56.25l-17.09 -24.46 -8.57 -0.04 -8.57 -0.04 0.26 0.39z"
                                                                        />
                                                                        </g>
                                                                        <g>
                                                                        <path
                                                                            className="fil1"
                                                                            d="M-0 105.46l0 105.46 105.5 -0.04 105.5 -0.04 0.04 -105.43 0.04 -105.43 -105.53 0 -105.53 0 0 105.46 0 0zm0.07 0.07c0,58 0.02,81.73 0.04,52.73 0.02,-29 0.02,-76.46 0,-105.46 -0.02,-29 -0.04,-5.27 -0.04,52.73l0 0zm43.21 -62.47c0.42,0.61 10.26,14.93 21.86,31.82 11.6,16.9 22.33,32.52 23.84,34.72 1.51,2.2 2.75,4.05 2.75,4.1 0,0.05 -0.56,0.75 -1.25,1.54 -0.69,0.8 -2.63,3.05 -4.31,5 -1.68,1.96 -4.52,5.25 -6.3,7.32 -1.78,2.07 -4.92,5.72 -6.97,8.11 -2.05,2.39 -5.74,6.68 -8.2,9.53 -7.6,8.84 -8.7,10.11 -14.48,16.85 -3.09,3.6 -6.05,7.04 -6.58,7.65 -0.53,0.61 -0.97,1.17 -0.97,1.24 0,0.09 1.94,0.14 5.47,0.14l5.47 0 6.01 -7c3.31,-3.85 6.57,-7.64 7.25,-8.43 1.47,-1.69 12.68,-14.74 13.62,-15.85 0.36,-0.43 0.89,-1.04 1.16,-1.35 0.28,-0.32 2.49,-2.88 4.9,-5.69 2.42,-2.82 4.46,-5.19 4.54,-5.27 0.08,-0.08 1.36,-1.57 2.84,-3.31 1.48,-1.73 2.74,-3.15 2.8,-3.15 0.06,0 7.67,11.02 16.92,24.5 9.25,13.47 16.98,24.74 17.19,25.03l0.37 0.53 18.75 0c15.42,0 18.74,-0.03 18.68,-0.18 -0.06,-0.16 -9.05,-13.27 -32.1,-46.82 -16.59,-24.15 -18.8,-27.4 -18.74,-27.56 0.06,-0.16 2.32,-2.8 17.18,-20.1 2.55,-2.97 6.06,-7.06 7.81,-9.09 1.75,-2.03 3.51,-4.08 3.92,-4.55 0.41,-0.47 2.56,-2.97 4.77,-5.55 2.22,-2.58 5.99,-6.97 8.37,-9.75 2.39,-2.78 4.43,-5.16 4.54,-5.3 0.18,-0.23 -0.13,-0.24 -5.36,-0.24l-5.55 0 -2.47 2.88c-3.29,3.83 -9.23,10.74 -10.92,12.7 -0.75,0.86 -1.68,1.95 -2.07,2.41 -0.39,0.46 -1.16,1.36 -1.71,1.99 -0.55,0.63 -3.33,3.87 -6.18,7.19 -2.85,3.32 -5.22,6.08 -5.26,6.12 -0.05,0.04 -0.66,0.75 -1.36,1.57 -1.22,1.45 -2.46,2.89 -8.16,9.5 -2.5,2.9 -2.63,3.03 -2.84,2.76 -0.12,-0.15 -7.45,-10.82 -16.29,-23.7l-16.07 -23.42 -18.82 -0 -18.82 -0 0.77 1.1 0 0zm14.69 7.68c0.14,0.22 3.66,5.26 7.81,11.2 7.88,11.27 38.86,55.61 58.78,84.13 6.37,9.11 11.65,16.65 11.75,16.75 0.14,0.14 1.99,0.17 8.66,0.14l8.49 -0.04 -22.21 -31.79c-12.22,-17.48 -29.9,-42.8 -39.31,-56.25l-17.09 -24.46 -8.57 -0.04 -8.57 -0.04 0.26 0.39z"
                                                                        />
                                                                        </g>
                                                                    </g>
                                                                    </g>
                                                                </svg>
                                                            </label>
                                                            </label>
                                                            <input className={`form-control ${errors.x ? 'border-danger' : ''}`} id="x" type="text" placeholder={settings.priority >= 1 ? "X URL" : t('Plano Premium') } disabled={settings.priority === 0} value={settings.twitter || ''} onChange={onInputChange} />
                                                        </div>
                                                        {errors.x && <div className="error-message" style={{ marginTop: '10px' }}>{errors.x}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <div className="form-group">
                                                        <div className="d-flex align-items-center">
                                                        <label htmlFor="tiktok" className="me-2">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 512 512"
                                                                    className="icon icon-xs me-2"
                                                                >
                                                                    <defs>
                                                                        <style>
                                                                            {`
                                                                            .cls-1 { fill: #00f6ef; }
                                                                            .cls-2 { fill: #fff; }
                                                                            .cls-3 { fill: #ff004f; }
                                                                            `}
                                                                        </style>
                                                                    </defs>
                                                                    <rect height="512" rx="71.15" width="512" x="0" y="0" />
                                                                    <path
                                                                        className="cls-1"
                                                                        d="M389.39,221.92V164.85c-74.6-3.15-77-70.94-77-77.31v-.48H254.73V309.33h0a45.66,45.66,0,1,1-32.36-43.71V206.76a104.57,104.57,0,0,0-13.32-.85A103.42,103.42,0,1,0,312.47,309.33c0-1.45,0-2.89-.1-4.32V195.56C338.92,219.85,389.39,221.92,389.39,221.92Z"
                                                                    />
                                                                    <path
                                                                        className="cls-2"
                                                                        d="M406.37,236V178.9c-74.61-3.15-77-70.94-77-77.31v-.48H271.71V323.38h0a45.66,45.66,0,1,1-32.36-43.7V220.81A104.57,104.57,0,0,0,226,220,103.42,103.42,0,1,0,329.45,323.38c0-1.45,0-2.89-.1-4.32V209.61C355.9,233.9,406.37,236,406.37,236Z"
                                                                    />
                                                                    <path
                                                                        className="cls-3"
                                                                        d="M313.82,101.11c2.78,15.14,10.9,38.81,34.57,52.66-18.09-21.07-19-48.26-19-52.18v-.48Z"
                                                                    />
                                                                    <path
                                                                        className="cls-3"
                                                                        d="M406.37,236V178.9a106.46,106.46,0,0,1-17-2v44.95s-50.47-2.07-77-26.36V304.91c.06,1.43.1,2.87.1,4.32a103.43,103.43,0,0,1-160.72,86.1,103.41,103.41,0,0,0,177.7-71.95c0-1.45,0-2.89-.1-4.32V209.61C355.9,233.9,406.37,236,406.37,236Z"
                                                                    />
                                                                    <path
                                                                        className="cls-3"
                                                                        d="M222.37,265.53a45.69,45.69,0,0,0-33.19,84.85,45.69,45.69,0,0,1,50.17-70.7V220.81A104.57,104.57,0,0,0,226,220c-1.23,0-2.44,0-3.66.07Z"
                                                                    />
                                                                </svg>
                                                            </label>
                                                            <input className={`form-control ${errors.tiktok ? 'border-danger' : ''}`} id="tiktok" type="text" placeholder={settings.priority >= 1 ? "TikTok URL" : t('Plano Premium') } disabled={settings.priority === 0} value={settings.tiktok || ''} onChange={onInputChange} />
                                                        </div>
                                                        {errors.tiktok && <div className="error-message" style={{ marginTop: '10px' }}>{errors.tiktok}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <div className="form-group">
                                                        <div className="d-flex align-items-center">
                                                            <label htmlFor="linkedin" className="me-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#0A66C2" className="icon icon-xs me-2">
                                                                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5v-14c0-2.761-2.239-5-5-5zm-11 19h-3v-10h3v10zm-1.5-11.249c-.966 0-1.75-.785-1.75-1.751s.784-1.75 1.75-1.75 1.75.784 1.75 1.75-.784 1.751-1.75 1.751zm13.5 11.249h-3v-5.5c0-1.379-1.121-2.5-2.5-2.5s-2.5 1.121-2.5 2.5v5.5h-3v-10h3v1.25c.878-.926 2.144-1.5 3.5-1.5 2.481 0 4.5 2.019 4.5 4.5v5.75z"/>
                                                                </svg>
                                                            </label>
                                                            <input className={`form-control ${errors.linkedin ? 'border-danger' : ''}`} id="linkedin" type="text" placeholder={settings.priority >= 1 ? "LinkedIn URL" : t('Plano Premium') } disabled={settings.priority === 0} value={settings.linkedin || ''} onChange={onInputChange} />
                                                        </div>
                                                        {errors.linkedin && <div className="error-message" style={{ marginTop: '10px' }}>{errors.linkedin}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <h2 className="h5 mb-4">{t('Acesso')}</h2>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="password">{t('Senha')}</label>
                                                        <input className={`form-control ${errors.password ? 'border-danger' : ''}`} id="password" type="password" placeholder={t('Digite sua senha')} value={settings.password || ''} onChange={onInputChange} />
                                                        {errors.password && <div className="error-message" style={{ marginTop: '10px' }}>{errors.password}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="confirmPassword">{t('Confirme a Senha')}</label>
                                                        <input ref={confirmPassword} className={`form-control ${errors.confirmPassword ? 'border-danger' : ''}`} id="confirmPassword" type="password" placeholder={t('Confirme sua senha')} />
                                                        {errors.confirmPassword && <div className="error-message" style={{ marginTop: '10px' }}>{errors.confirmPassword}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="fa" checked={settings.fa} onChange={onCheckboxChange} />
                                                        <label className="form-check-label" htmlFor="fa">{t('Ativar autenticação de dois fatores (2FA)')}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <button className="btn btn-primary" onClick={onFormSubmit} >{t('Salvar')}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {settings.type !== '1' && (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card card-body border-0 shadow mb-4">
                                            <h2 className="h5 mb-4">{t('Plano')}</h2>
                                            <div className="alert alert-info">
                                                {settings.priority === 0 ? t('Grátis') : t('Premium')} (
                                                <Link to="/planoptions" className="alert-link">
                                                    {settings.priority === 0 ? t('O premium te espera.') : t('Sinta a diferença.')}
                                                </Link>)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <Footer />
                        </main>
                    </React.Fragment>
                )}
            <PrivacyModal />
            <TermsModal />
            <Toast text={notification.text} type={notification.type} />
        </>
    );
}

export default Settings;
