import React, { useState, useEffect } from 'react';
import Menu from '../../components/Menu/Menu';
import Orders from './Orders/Orders';
import Service from './Service/Service';
import { checkVerification } from '../../services/DashService';
import { getSettings } from '../../services/SettingsService';
import NewOrderButton from '../../components/NewOrder/NewOrderButton';
import NewOrderModal from '../../components/NewOrder/NewOrderModal';
import PrivacyModal from '../../components/Privacy/PrivacyModal';
import TermsModal from '../../components/Terms/TermsModal';
import GoogleMapsWidget from './GoogleMapsWidget';
import VerificationModal from '../../components/Verification/VerificationModal';
import Footer from '../../components/Footer/Footer';
import Toast from '../../components/Toast/Toast';
import { useTranslation } from 'react-i18next';

function Dashboard() {
  const { t } = useTranslation();
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [notification, setNotification] = useState({ type: '', text: '' });
  const [refresh, setRefresh] = useState(0);
  const [localAddress, setLocalAddress] = useState('');
  const [destinationAddress, setDestinationAddress] = useState('');
  const [myType, setMyType] = useState('');
  const [active, setActive] = useState(true);
  const [isApprovedPanel, setIsApprovedPanel] = useState(false);

  // Função para extrair parâmetros de query string da URL
  const getQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const id = params.get('id');
    return { token, id };
  };

  // Função para verificar o status da verificação
  const checkVerificationStatus = () => {
    checkVerification()
      .then(result => {
        if (result.verification === 0) setShowVerificationModal(true);
      })
      .catch(error => {
        setNotification({ type: 'error', text: error.response ? error.response.data : error });
      });
  };

  // useEffect para extrair token e id da URL e armazená-los no localStorage
  useEffect(() => {
    const { token, id } = getQueryParams();
    if (token && id) {
      localStorage.setItem('token', token);
      localStorage.setItem('id', id);
    }
  }, []);

  useEffect(() => {
    checkVerificationStatus();
  }, []);

  useEffect(() => {
    if (myType && myType !== '1') {
      getSettings()
        .then(result => {
          setDestinationAddress(`${result.street}, ${result.number} ${result.city}`);
        })
        .catch(err => {
          setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
        });
    } else {
      getSettings()
        .then(result => {
          setLocalAddress(`${result.street}, ${result.number} ${result.city}`);
        })
        .catch(err => {
          setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
        });
    }
  }, [myType]);

  useEffect(() => {
    if (notification) {
      const timeoutId = setTimeout(() => {
        setNotification({});
      }, 10000);

      return () => clearTimeout(timeoutId);
    }
  }, [notification]);

  function onSubmitOrder() {
    setNotification({ type: 'success', text: t('Solicitação realizada com sucesso!') });
    setRefresh(Date.now);
  }

  function onViewOrder(orderData, isApproved = false) {
    setSelectedOrder(orderData);
    setIsApprovedPanel(isApproved);
  }

  function onMyLocation(Data) {
    setLocalAddress(`${Data.street}, ${Data.number} ${Data.city}`);
  }

  function onMyType(Data) {
    setMyType(Data);
  }

  function onActive(Data) {
    setActive(Data);
  }

  function onMyDestination(Data) {
    setDestinationAddress(`${Data.street}, ${Data.number} ${Data.city}`);
  }

  function onProposta() {
    setRefresh(Date.now());
    setNotification({ type: 'success', text: t('Proposta realizada com sucesso!') });
  }

  function onFinishOrder(order) {
    setRefresh(Date.now());
    setNotification({ type: 'success', text: t('Excluído com sucesso!') });
  }

  function onExcludeSubmit(order) {
    setRefresh(Date.now());
    setNotification({ type: 'success', text: t('Solicitação excluída com sucesso!') });
  }

  function onCancelSubmit(order) {
    setRefresh(Date.now());
    setNotification({ type: 'success', text: t('Solicitação cancelada com sucesso!') });
  }

  function onAllowOrderSubmit(order) {
    setRefresh(Date.now());
    setNotification({ type: 'success', text: t('Proposta aceita com sucesso!') });
  }

  function onDenyOrderSubmit(order) {
    setRefresh(Date.now());
    setNotification({ type: 'success', text: t('Proposta rejeitada com sucesso!') });
  }

  function onBackToOrders() {
    setSelectedOrder(null);
    setIsApprovedPanel(false);
  }

  return (
    <React.Fragment>
      <Menu />
      <main className="content">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-none d-md-block mb-4 mb-md-0">
            <h1 className="h4">{t('Painel')}</h1>
          </div>
          <div className="ms-2 ms-lg-3">
            <NewOrderButton hide="d-md-none" />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center gap-3 ms-2 ms-lg-3 w-100 d-lg-none">
            <button id="btnNewOrder" className="btn btn-info animate-up-2 w-100 d-md-none" style={{ padding: '25px' }} data-bs-toggle="modal" data-bs-target="#modalOrder">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs me-2">
                <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
              </svg>
              {t('Solicite agora mesmo o que você precisar!')}
            </button>
          </div>
          <div className="btn-toolbar mb-md-0 align-items-center">
            <div className="ms-2 ms-lg-3">
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7 mb-4">
            {selectedOrder && myType === '1' && !isApprovedPanel ? (
              <>
                {/*<button onClick={onBackToOrders} className="btn btn-primary mb-3">Voltar</button>*/}
                <Service
                  orders={selectedOrder}
                  onMyDestination={onMyDestination}
                  onAllowOrder={onAllowOrderSubmit}
                  onDenyOrder={onDenyOrderSubmit}
                  myType={myType}
                  active={active}
                  localAddress={localAddress}
                  onBackToOrders={onBackToOrders}
                />
              </>
            ) : (
              <Orders
                onViewOrder={onViewOrder}
                onMyLocation={onMyLocation}
                onMyType={onMyType}
                onMyDestination={onMyDestination}
                onActive={onActive}
                onProposta={onProposta}
                onFinishOrder={onFinishOrder}
                onExclude={onExcludeSubmit}
                onCancel={onCancelSubmit}
                refresh={refresh}
              />
            )}
          </div>
          <div className="col-md-5 mb-4">
            {myType && myType === '1' ?
              <GoogleMapsWidget localAddress={localAddress} destinationAddress={destinationAddress} /> :
              <GoogleMapsWidget localAddress={destinationAddress} destinationAddress={localAddress} />
            }
          </div>
        </div>
        <Footer />
      </main>
      <PrivacyModal />
      <TermsModal />
      <NewOrderModal onEnviou={onSubmitOrder} />
      <VerificationModal title="Verification" show={showVerificationModal} onClose={() => setShowVerificationModal(false)} />
      <Toast type={notification.type} text={notification.text} />
    </React.Fragment>
  );
}

export default Dashboard;
