import React from 'react';
import { useTranslation } from 'react-i18next';

function NewOrderButton(props) {
    const { t } = useTranslation();


    function getClass(){
        if(props.hide) {
            return "btn btn-primary animate-up-2 d-none d-md-block";
        } else if (props.expansive){
            return "btn btn-primary animate-up-2 w-100";
        } else {    
            return "btn btn-primary animate-up-2 d-none d-md-block";
        }
    }

    return (
        <React.Fragment>
            
                        <button id="btnNewOrder" className={getClass()} data-bs-toggle="modal" data-bs-target="#modalOrder">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs me-2">
                                <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                            </svg>                    
                            {t('Solicite agora mesmo o que você precisar!')}
                        </button>

        </React.Fragment >
    );
}

export default NewOrderButton;