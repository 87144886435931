import React from 'react';
import { useTranslation } from 'react-i18next';
import './PrivacyModal.css';

function PrivacyModal() {
    const { t } = useTranslation();
    return (
        <div className="modal fade" id="privacyModal" tabIndex="-1" aria-labelledby="privacyModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="privacyModalLabel">{t('Política de Privacidade da YEPWEDO')}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>{t('Bem-vindo à YEPWEDO! Valorizamos sua privacidade e estamos comprometidos em protegê-la.')} {t('Esta Política de Privacidade descreve como coletamos, usamos, divulgamos e protegemos suas informações pessoais quando você usa nosso site e serviços.')}</p>
                        <p><strong>{t('1. Informações que Coletamos')}</strong></p>
                        <p><strong>{t('1.1 Informações Pessoais')}</strong><br />
                        {t('Quando você se registra na YEPWEDO, podemos coletar as seguintes informações pessoais')}:
                            <ul>
                                <li>{t('Nome completo')}</li>
                                <li>{t('Endereço de e-mail')}</li>
                                <li>{t('Número de telefone')}</li>
                                <li>{t('Informações de login')} ({t('nome de usuário e senha')})</li>
                            </ul>
                        </p>
                        <p><strong>{t('1.2 Informações Não Pessoais')}</strong><br />
                        {t('Podemos coletar informações não pessoais sobre você quando você interage com nosso site.')} {t('Essas informações podem incluir')}:
                            <ul>
                                <li>{t('Dados demográficos')}</li>
                                <li>{t('Tipo de navegador')}</li>
                                <li>{t('Endereço IP')}</li>
                                <li>{t('Páginas visitadas e tempo de visita')}</li>
                            </ul>
                        </p>
                        <p><strong>{t('2. Uso das Informações')}</strong></p>
                        <p><strong>{t('2.1 Para Fornecer e Melhorar Nossos Serviços')}</strong><br />
                        {t('Usamos as informações coletadas para')}:
                            <ul>
                                <li>{t('Processar seu registro e gerenciar sua conta')}</li>
                                <li>{t('Facilitar a conexão entre você e os provedores de serviço ou produto')}</li>
                                <li>{t('Melhorar nosso site e serviços com base no feedback e nas análises de uso')}</li>
                                <li>{t('Comunicar-se com você sobre atualizações, ofertas e informações relacionadas ao nosso serviço')}</li>
                            </ul>
                        </p>
                        <p><strong>{t('2.2 Marketing e Publicidade')}</strong><br />
                        {t('Com seu consentimento, podemos usar suas informações pessoais para enviar newsletters, campanhas de marketing e outras informações promocionais.')} {t('Você pode optar por não receber essas comunicações a qualquer momento.')}
                        </p>
                        <p><strong>{t('3. Compartilhamento de Informações')}</strong></p>
                        <p><strong>{t('3.1 Com Provedores de Serviço e Produto')}</strong><br />
                        {t('Compartilhamos suas informações com provedores de serviço e produto para facilitar as conexões e transações solicitadas por você.')}
                        </p>
                        <p><strong>{t('3.2 Com Terceiros')}</strong><br />
                        {t('Não vendemos, trocamos ou alugamos suas informações pessoais para terceiros sem o seu consentimento.')} {t('Podemos compartilhar informações com terceiros de confiança que nos ajudam a operar nosso site e serviços, desde que esses terceiros concordem em manter essas informações confidenciais.')}
                        </p>
                        <p><strong>{t('4. Proteção das Informações')}</strong></p>
                        <p><strong>{t('4.1 Medidas de Segurança')}</strong><br />
                        {t('Adotamos medidas de segurança adequadas para proteger suas informações pessoais contra acesso não autorizado, alteração, divulgação ou destruição.')} {t('Utilizamos criptografia, firewalls e outras tecnologias para proteger suas informações.')}
                        </p>
                        <p><strong>{t('4.2 Retenção de Dados')}</strong><br />
                        {t('Reteremos suas informações pessoais apenas pelo tempo necessário para cumprir os propósitos para os quais as coletamos, incluindo a satisfação de quaisquer requisitos legais, contábeis ou de relatório.')}
                        </p>
                        <p><strong>{t('5. Seus Direitos')}</strong></p>
                        <p><strong>{t('5.1 Acesso e Correção')}</strong><br />
                        {t('Você tem o direito de acessar e corrigir suas informações pessoais a qualquer momento.')} {t('Para isso, acesse as configurações da sua conta ou entre em contato conosco.')}
                        </p>
                        <p><strong>{t('5.2 Exclusão de Dados')}</strong><br />
                        {t('Você pode solicitar a exclusão de suas informações pessoais.')} {t('No entanto, podemos reter certas informações conforme necessário para cumprir obrigações legais ou resolver disputas.')}
                        </p>
                        <p><strong>{t('6. Cookies')}</strong><br />
                        {t('Utilizamos cookies para melhorar sua experiência em nosso site.')} {t('Cookies são pequenos arquivos de texto armazenados no seu navegador que ajudam a personalizar sua experiência de navegação.')} {t('Você pode configurar seu navegador para recusar cookies, mas isso pode afetar a funcionalidade do nosso site.')}
                        </p>
                        <p><strong>{t('7. Links para Sites de Terceiros')}</strong><br />
                        {t('Nosso site pode conter links para sites de terceiros.')} {t('Não somos responsáveis pelas práticas de privacidade ou pelo conteúdo desses sites.')} {t('Recomendamos que você leia as políticas de privacidade de cada site que visita.')}
                        </p>
                        <p><strong>{t('8. Alterações a Esta Política de Privacidade')}</strong><br />
                        {t('Podemos atualizar nossa Política de Privacidade periodicamente.')} {t('Notificaremos você sobre quaisquer alterações publicando a nova Política de Privacidade em nosso site.')} {t('Recomendamos que você reveja esta política regularmente para se manter informado sobre como estamos protegendo suas informações.')}
                        </p>
                        <p><strong>{t('9. Contato')}</strong><br />
                        {t('Se você tiver dúvidas ou preocupações sobre esta Política de Privacidade, entre em contato conosco')}:
                            <ul>
                                <li>{t('E-mail: support@yepwedo.com')}</li>
                            </ul>
                        </p>
                        <p>{t('Última atualização')}: {t('14/06/2024')}</p>
                        <p>{t('A YEPWEDO está comprometida em proteger sua privacidade e fornecer uma experiência segura e confiável.')} {t('Obrigado por confiar em nós!')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyModal;
