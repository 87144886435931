import axios from './BaseService';

const CATEGORY_URL = `${process.env.REACT_APP_API_URL}/category` || 'http://localhost:3001/category';

// Fun��o para realizar o upload da imagem
export async function getCategoryProviders() {
    const categoriesUrl = `${CATEGORY_URL}/providers`;
    const response = await axios.get(categoriesUrl)
    return response.data;
}

// Fun��o para realizar o upload da imagem
export async function getCategorySuppliers() {
    const categoriesUrl = `${CATEGORY_URL}/suppliers`;
    const response = await axios.get(categoriesUrl)
    return response.data;
}