import axios from './BaseService';

const RATINGS_URL = `${process.env.REACT_APP_API_URL}/ratings/` || 'http://localhost:3001/ratings';



export async function createRating(ratingData){
    const ratingsUrl = `${RATINGS_URL}`;
    const response = await axios.post(ratingsUrl, ratingData);
    return response.data;
};

export async function getAverageRating(itemId){
    //const ratingsUrl = (`${RATINGS_URL}${itemId}/average`);
    const ratingsUrl = (`${RATINGS_URL}${itemId}`);
    const response = await axios.get(ratingsUrl);
    return response.data;
};