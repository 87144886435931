import React, { useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import MarkerClusterer from '@googlemaps/markerclustererplus';

function GoogleMapsWidget(props) {
  const [origin, setOrigin] = useState({ lat: -25.428360, lng: -49.273251 }); // Localização de origem fixa
  //const origin2 ='Rua Jarana 170, Curitiba 82540-090';
  //const destinationAddress = 'BR116 , 2785 Curitiba'; // Endereço de destino fixo
  const [duration, setDuration] = useState('');

  useEffect(() => {
    const apiOptions = {
      apiKey: "AIzaSyB0JqCNUoU9i4yNhs9KcebU08ZBC2BpqJE",
      version: "weekly",
      libraries: ["places"]
    };
    
    const loader = new Loader(apiOptions);

    loader.load().then(() => {
      //console.log('Maps JS API loaded');
      const map = displayMap();
      //const markers = addMarkers(map);
      //clusterMarkers(map, markers);
      calculateAndDisplayRoute(map);
    });
  }, []);

  function displayMap() {
    const mapOptions = {
      center: origin,
      zoom: 14,
      mapId: 'b04c21d84bd0fad2'
    };
    const mapDiv = document.getElementById('map');
    return new window.google.maps.Map(mapDiv, mapOptions);
  }

  function addMarkers(map) {
    const locations = [
      { name: 'OpenHouse', address: 'Rua Jarana 170, Curitiba 82540-090' }
    ];
    const markers = [];
    for (const location of locations) {
      geocodeAddress(map, location);
    }
    return markers;
  }

  function geocodeAddress(map, location) {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: location.address }, (results, status) => {
      if (status === 'OK') {
        const markerOptions = {
          map: map,
          position: results[0].geometry.location,
        };
        const marker = new window.google.maps.Marker(markerOptions);
        const infoWindowContent = `<div style="font-size: 14px;"><h3>${location.name}</h3><p>${location.address}</p></div>`;
        const infoWindow = new window.google.maps.InfoWindow({
          content: infoWindowContent,
          maxWidth: 200
        });
        marker.addListener('click', () => {
          infoWindow.open(map, marker);
        });
      } else {
        console.error('Geocode was not successful for the following reason:', status);
      }
    });
  }

  function clusterMarkers(map, markers) {
    const clustererOptions = { imagePath: './m' };
    new MarkerClusterer(map, markers, clustererOptions);
  }

  function calculateAndDisplayRoute(map) {
    const directionsService = new window.google.maps.DirectionsService();
    const directionsRenderer = new window.google.maps.DirectionsRenderer();
    directionsRenderer.setMap(map);

    const request = {
      origin: props.localAddress,
      destination: props.destinationAddress,
      travelMode: 'DRIVING'
    };

    directionsService.route(request, (result, status) => {
      if (status === 'OK') {
        directionsRenderer.setDirections(result);
        const route = result.routes[0];
        setDuration(route.legs[0].duration.text);
      } else {
        console.error('Error fetching directions:', status);
      }
    });
  }

  return (
    <div className="row">
      <div className="col-12 mb-4">
        <div className="card cardDark border-0 shadow colorchart" style={{ position: 'relative', height: 520 }}>
          <div className="card-body p-2">
            <div className="tradingview-widget-container" style={{ position: 'relative' }}>
              <div id="map" className="divTradingView" style={{ position: 'relative' }}></div>
              <div>Tempo estimado de deslocamento: {duration}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleMapsWidget;


//AIzaSyB0JqCNUoU9i4yNhs9KcebU08ZBC2BpqJE