import axios from './BaseService';

const SETTINGS_URL = `${process.env.REACT_APP_API_URL}/chat` || 'http://localhost:3001/chat';

const CHATING_URL = `${process.env.REACT_APP_API_URL}/chatfiles` || 'http://localhost:3001/chatfiles';

export async function uploadFile(file) {
    console.log('File:', file);
    const formData = new FormData();
    formData.append('file', file);
    console.log('Form:', formData);
    const response = await axios.post(`${CHATING_URL}/upload`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

// Fun��o para realizar o upload da imagem
export async function getAvatar(id) {
    const settingsUrl = `${SETTINGS_URL}/avatar/${id}`;
    const response = await axios.get(settingsUrl)
    return response.data;
}

// Fun��o para realizar o upload da imagem
export async function getBrand(id) {
    const settingsUrl = `${SETTINGS_URL}/brand/${id}`;
    const response = await axios.get(settingsUrl)
    return response.data;
}

// Fun��o para realizar o upload da imagem
export async function getNickname(id) {
    const settingsUrl = `${SETTINGS_URL}/nickname/${id}`;
    const response = await axios.get(settingsUrl)
    return response.data;
}

// Fun��o para realizar o upload da imagem
export async function getCity(id) {
    const settingsUrl = `${SETTINGS_URL}/city/${id}`;
    const response = await axios.get(settingsUrl)
    return response.data;
}

// Fun��o para realizar o upload da imagem
export async function getPerson(id) {
    const settingsUrl = `${SETTINGS_URL}/person/${id}`;
    const response = await axios.get(settingsUrl)
    return response.data;
}

// Fun��o para realizar o upload da imagem
/*
export async function getCommunity() {
    const settingsUrl = `${SETTINGS_URL}/community`;
    const response = await axios.get(settingsUrl)
    return response.data;
}*/

// Fun��o para realizar o upload da imagem
export async function getContacts() {
    const settingsUrl = `${SETTINGS_URL}/contacts`;
    const response = await axios.get(settingsUrl)
    return response.data;
}

export async function updateContact(contactData) {
    const settingsUrl = `${SETTINGS_URL}/contact`;
    const response = await axios.patch(settingsUrl, contactData);
    return response.data;
}

export async function updateBlocked(blockedtData) {
    const settingsUrl = `${SETTINGS_URL}/blocked`;
    const response = await axios.patch(settingsUrl, blockedtData);
    return response.data;
}

export async function blockUser(contactData) {
    const settingsUrl = `${SETTINGS_URL}/blockuser`;
    const response = await axios.patch(settingsUrl, contactData);
    return response.data;
}

export async function chkContact(contactData) { // Fornecedores e Provedores *
    const settingsUrl = `${SETTINGS_URL}/getcontact`;
    const response = await axios.post(settingsUrl, contactData);
    return response.data;//{count, rows}
}

export async function chkBlocked(blockedData) { // Fornecedores e Provedores *
    const settingsUrl = `${SETTINGS_URL}/getblocked`;
    const response = await axios.post(settingsUrl, blockedData);
    return response.data;//{count, rows}
}

export const getDeliveredMessages = async (userId) => {
    try {
        const response = await axios.get(`${SETTINGS_URL}/delivered/${userId}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching delivered messages:", error);
        throw error;
    }
};

export const searchUserInDatabase = async (query) => {
    // Substitua isso com a chamada para a sua API que busca o usuário no banco de dados
    // Aqui está um exemplo fictício
    try {
        const response = await axios.get(`${SETTINGS_URL}/community?query=${query}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching delivered messages:", error);
        throw error;
    }
};