import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './SideBar.css';

function NavBar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navbarButtonRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onLogoutClick = (event) => {
    event.preventDefault();
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  const calculateRight = () => {
    if (navbarButtonRef.current && dropdownRef.current) {
      const navbarButtonRect = navbarButtonRef.current.getBoundingClientRect();
      const navbarButtonRight = window.innerWidth - navbarButtonRect.right;
      return `${navbarButtonRight}px`;
    }
    return 'auto';
  };

  return (
    <React.Fragment>
      <nav className="navbar navbar-dark navbar-theme-primary px-4 col-12 d-lg-none">
        <div className="container-fluid d-flex justify-content-between align-items-center">
          <ul className="navbar-nav flex-row align-items-center">
            <li className="nav-item">
              <Link to="/dashboard" className="nav-link d-flex align-items-center">
                <span className="sidebar-icon ms-4">
                  <svg
                    className="icon icon-xs me-2"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="20 46.333 160 107"
                    xmlns="http://www.w3.org/2000/svg"
                    data-type="color"
                    role="img"
                    aria-label="Homepage"
                    style={{ transform: 'scaleY(-1)' }}
                  >
                    <g>
                      <path
                        d="M73.333 46.333h53.334l-53.334 107H20l53.333-107z"
                        fill="#D6DCDC"
                        data-color="1"
                      ></path>
                      <path
                        d="M73.333 46.333zM100 99.833l26.667 53.5H180l-26.666-53.5H100z"
                        fill="#D6DCDC"
                        clipRule="evenodd"
                        fillRule="evenodd"
                        data-color="1"
                      ></path>
                    </g>
                  </svg>
                </span>
                <span className="sidebar-text">Yepwedo</span>
              </Link>
            </li>
          </ul>
          <a className="navbar-toggler d-lg-none" type="button" onClick={toggleDropdown}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs-2">
              <path fillRule="evenodd" d="M10.5 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm0 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm0 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z" clipRule="evenodd" />
            </svg>
          </a>
        </div>
        {isDropdownOpen && (
          <div ref={dropdownRef} className={`dropdown-menu show ${isDropdownOpen ? 'right-aligned' : ''}`}>
            <DropdownMenuItems onLogoutClick={onLogoutClick} />
          </div>
        )}
      </nav>
    </React.Fragment>
  );
}

const DropdownMenuItems = ({ onLogoutClick }) => {
  const { t } = useTranslation();

  return (
    <ul className="list-unstyled">
      <li>
        <Link to="/dashboard" className="dropdown-item">
          {t('Painel')}
        </Link>
      </li>
      <li>
        <Link to="/providers" className="dropdown-item">
          {t('Serviços')}
        </Link>
      </li>
      <li>
        <Link to="/suppliers" className="dropdown-item">
          {t('Fornecedores')}
        </Link>
      </li>
      <li>
        <Link to="/favorites" className="dropdown-item">
          {t('Favoritos')}
        </Link>
      </li>
      <li>
        <Link to="/chat" className="dropdown-item">
          Chat
        </Link>
      </li>
      <li>
        <Link to="/settings" className="dropdown-item">
          {t('Configurações')}
        </Link>
      </li>
      <li>
        <Link to="/" className="dropdown-item" onClick={onLogoutClick}>
          {t('Sair')}
        </Link>
      </li>
    </ul>
  );
};

export default NavBar;
