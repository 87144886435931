import React, { useEffect, useState } from "react";
import useWebSocket from 'react-use-websocket';

/**
 * props:
 * - text
 * - type
 */
function Toast(props) {

    const DEFAULT_NOTIFICATION = { type: '', text: '' };

    const [notification, setNotification] = useState(DEFAULT_NOTIFICATION);

    useEffect(() => {
        if (!props.text) return;
        setNotification({ type: props.type, text: props.text });
    }, [props.type, props.text]);

    useEffect(() => {
        if (!notification.text) return;

        const notyf = new window.Notyf({
            position: {
                x: 'right',
                y: 'top',
            },
            duration: 0, // Define a duração para 0 para permitir a customização do tempo de exibição
            types: [
                {
                    type: 'info',
                    background: 'blue',
                    dismissible: true
                },
                {
                    type: 'check',
                    background: 'red',
                    dismissible: true
                },
                {
                    type: 'success',
                    background: 'green',
                    dismissible: true
                },
                {
                    type: 'whats',
                    background: 'green',
                    dismissible: true
                },
                {
                    type: 'ordem',
                    background: 'green',
                    dismissible: true
                }
            ]
        });

        notyf.open({
            type: notification.type,
            message: notification.text
        });

        const timeout = setTimeout(() => {
            notyf.dismissAll();
            setNotification(DEFAULT_NOTIFICATION);
        }, 15000); // Fecha o toast após 15 segundos

        return () => {
            clearTimeout(timeout); // Limpa o timeout caso o componente seja desmontado ou a notificação seja alterada
            notyf.dismissAll(); // Fecha todas as notificações se o componente for desmontado
        };
    }, [notification]);

    const { sendMessage, lastJsonMessage, readyState } = useWebSocket(process.env.REACT_APP_WS_URL, {
        onOpen: () => {
            console.log(`Connected to App WS`);
        },
        onMessage: (event) => {
            const message = JSON.parse(event.data);
            if (message && message.notification) {
                setNotification(message.notification);
            }
        },
        queryParams: { 'token': localStorage.getItem("token") },
        onError: (event) => {
            console.error(event);
            //setNotification({ text: JSON.stringify(event), type: 'error' });
        },
        shouldReconnect: (closeEvent) => true,
        reconnectAttempts: 0,
        reconnectInterval: 3000,
        reconnectMaxInterval: 30000, // Maximum interval for reconnection attempts
        reconnectExponential: true, // Exponential backoff for reconnection attempts
    });

    const [reconnectAttempts, setReconnectAttempts] = useState(0);

    // Implement exponential backoff for reconnection attempts
    const reconnectInterval = Math.min(3000 * Math.pow(2, reconnectAttempts), 30000);

    // Monitor the ready state and attempt reconnection if necessary
    useEffect(() => {
        if (readyState !== WebSocket.OPEN) {
            const timer = setTimeout(() => {
                setReconnectAttempts((prev) => prev + 1);
            }, reconnectInterval);

            return () => clearTimeout(timer);
        } else {
            setReconnectAttempts(0); // Reset attempts on successful connection
        }
    }, [readyState, reconnectInterval]);

    return null;
}

export default Toast;