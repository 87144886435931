import React from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import Login from './public/Login/Login';
import Signup from './public/Signup/Signup';
import Privacy from './public/Privacy/PrivacyPage';
import Home from './public/Home/Home';
import Settings from './private/Settings/Settings';
import PlanOptions from './private/Plan/PlanOptions';
import CardReactMUI from './private/Plan/CardReactMUI';
import PixReactMUI from './private/Plan/PixReactMUI';
import Success from './public/Success/Success';
import Dashboard from './private/Dashboard/Dashboard';
import Suppliers from './private/Suppliers/Suppliers';
import Providers from './private/Providers/Providers';
import Favorites from './private/Favorites/Favorites';
import Chat from './private/Chat/Chat';
import { I18nextProvider } from 'react-i18next'; // Adicione esta importação
import i18n from './components/i18n/i18n'; // Importe sua configuração do i18next

const savedLanguage = localStorage.getItem('language');
if (savedLanguage) {
  i18n.changeLanguage(savedLanguage);
}

function Routes() {

    function PrivateRoute({ children, ...rest }) {
        return (
            <Route {...rest} render={() => {
                return localStorage.getItem("token")
                    ? children
                    : <Redirect to='/' />
            }} />
        )
    }

    return (
        <I18nextProvider i18n={i18n}>
            <BrowserRouter>
                <Route path="/" exact>
                    <Login />
                </Route>
                <Route path="/signup" exact>
                    <Signup />
                </Route>
                <Route path="/privacy" exact>
                    <Privacy />
                </Route>
                <Route path="/success" exact>
                    <Success />
                </Route>
                <Route path="/home" exact>
                    <Home />
                </Route>
                <PrivateRoute path="/settings">
                    <Settings />
                </PrivateRoute>
                <PrivateRoute path="/planoptions">
                    <PlanOptions />
                </PrivateRoute>
                <PrivateRoute path="/card">
                    <CardReactMUI />
                </PrivateRoute>
                <PrivateRoute path="/pix">
                    <PixReactMUI />
                </PrivateRoute>
                <PrivateRoute path="/(mysuppliers|suppliers)/">
                    <Suppliers />
                </PrivateRoute>
                <PrivateRoute path="/(myproviders|providers)/">
                    <Providers />
                </PrivateRoute>
                <PrivateRoute path="/favorites">
                    <Favorites />
                </PrivateRoute>
                <PrivateRoute path="/dashboard">
                    <Dashboard />
                </PrivateRoute>
                <PrivateRoute path="/chat/:userId?">
                    <Chat />
                </PrivateRoute>

            </BrowserRouter>
            </I18nextProvider>
       
    )
}

export default Routes;