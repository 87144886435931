import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getBrand } from '../../services/ChatService';
import BrandLogo from '../BrandLogo/BrandLogo';

function BrandLink({ personId, personName }) {
    const [brand, setBrand] = useState(null);

    useEffect(() => {
        async function fetchAvatar() {
            try {
                const brandData = await getBrand(personId);
                setBrand(`data:image/png;base64,${brandData}`);
            } catch (error) {
                console.error(error);
            }
        }
        fetchAvatar();
    }, [personId]);

    return (
        <Link to="#" className="nav-link d-flex align-items-center">
            <span className="sidebar-icon ms-n3 me-5 mb-n1">
                {brand && <BrandLogo imageUrl={brand} alt="Brand" size="small" />}
            </span>
            <span className="sidebar-text ms-n4 mb-1">
                {personName}
            </span>
        </Link>
    );
}

export default BrandLink;