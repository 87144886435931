import React, { useState, useRef, useEffect } from 'react';
import StarRatingComponent from 'react-star-rating-component';
import { Link } from 'react-router-dom';
import { getPriority } from '../../../services/SettingsService';
import PersonLink from '../../../components/PersonLink/PersonLink'; // Certifique-se de ajustar o caminho do import
import BrandLink from '../../../components/BrandLink/BrandLink';
import { getAverageRating } from '../../../services/RatingService';
import { useTranslation } from 'react-i18next';

/**
 * props:
 * - data
 * - onClick
 */
function SupplierRow(props) {
    const { t } = useTranslation();

    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);

    useEffect(() => {
        const handleResize = () => {
          setIsLargeScreen(window.innerWidth >= 992);
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    const [rating, setRating] = useState(0);

    const [priority, setPriority] = useState(0);

    useEffect(() => {
        async function fetchRating() {
            try {
                const rating = await getAverageRating(props.data.userId);
                setRating(rating.averageRating);
                const priority = await getPriority(props.data.userId);
                setPriority(priority);
            } catch (error) {
                console.error(error);
            }
        }
        fetchRating();
    }, [props.data.userId]);

    function formatarDataHora() {
        // Converter para objeto Date
        var data_hora = new Date(props.data.createdAt);
    
        // Extrair partes da data
        var dia = data_hora.getDate();
        var mes = data_hora.getMonth() + 1; // Os meses começam em zero, então adicionamos 1
        var ano = data_hora.getFullYear() % 100; // Apenas os dois últimos dígitos do ano
        var hora = data_hora.getHours();
        var minutos = data_hora.getMinutes();
    
        // Formatar a hora no formato AM/PM
        var periodo = "AM";
        if (hora >= 12) {
            periodo = "PM";
            hora -= 12;
        }
        if (hora === 0) {
            hora = 12;
        }
    
        // Criar a string formatada
        var data_formatada = dia + '/' + mes + '/' + ano + ', ' + hora + ':' + (minutos < 10 ? '0' : '') + minutos + ' ' + periodo;
    
        return data_formatada;
    }

    return (
        isLargeScreen ? (
            <tr >
                <td><span className="fw-normal">
                    {priority >= 1
                        ? <BrandLink personId={props.data.userId} personName={props.data.job ? props.data.job : props.data.name} />
                        : <PersonLink personId={props.data.userId} personName={props.data.name} />
                    }</span></td>
                <td><span className="fw-normal">{
                    props.data.voluntario === true
                    ? (
                        t('Voluntário')
                    )
                    : (
                        props.data.real
                    )
                }</span></td>
                <td
                id={"rating" + props.data.id}
                className="dv-star-rating-star"
                data-bs-toggle="modal"
                data-bs-target="#modalResp"
                name={props.data.id}
                onClick={props.onStarClick}
                >
                <StarRatingComponent    
                    name={props.data.id}
                    starCount={5}
                    value={rating}
                    //onClick={props.onStarClick}
                    emptyStarColor="#1455aa"
                /></td>
                
                <td>
                <button id={"rep" + props.data.id} type="button" className="btn btn-info btn-xs" data-bs-toggle="modal" data-bs-target="#modalResp" onClick={props.onClick}>
                        <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" /><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                        </svg>
                    </button>
                </td>
            </tr>
        ): (
            <tr style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                <td style={{ paddingLeft: "1rem", paddingRight: "1rem" }}><span className="fw-normal">
                    {priority >= 1
                        ? <BrandLink personId={props.data.userId} personName={props.data.job ? props.data.job : props.data.name} />
                        : <PersonLink personId={props.data.userId} personName={props.data.name} />
                    }</span></td>
                <td
                style={{ paddingLeft: "0.6rem", fontSize: "8px", paddingRight: "0.6rem" }}
                id={"rating" + props.data.id}
                className="dv-star-rating-star"
                data-bs-toggle="modal"
                data-bs-target="#modalResp"
                name={props.data.id}
                onClick={props.onStarClick}
                >
                <StarRatingComponent    
                    name={props.data.id}
                    starCount={5}
                    value={rating}
                    //onClick={props.onStarClick}
                    emptyStarColor="#1455aa"
                /></td>
                
                <td style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                    <button id={"rep" + props.data.id} type="button" className="btn btn-info btn-xs" data-bs-toggle="modal" data-bs-target="#modalResp" onClick={props.onClick}>
                        <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" /><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                        </svg>
                    </button>
                </td>
            </tr>
        )
    )
}

export default SupplierRow;
