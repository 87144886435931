import React, { useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { useTranslation } from 'react-i18next';

function GoogleMapsWidget(props) {
  const { t } = useTranslation();
  const [origin, setOrigin] = useState(null);
  const [duration, setDuration] = useState('');

  useEffect(() => {
    const apiOptions = {
      apiKey: "AIzaSyB0JqCNUoU9i4yNhs9KcebU08ZBC2BpqJE",
      version: "weekly",
      libraries: ["places"]
    };

    const loader = new Loader(apiOptions);

    loader.load().then(() => {
      console.log('Maps JS API loaded');
      if (props.localAddress) {
        geocodeAddress(props.localAddress, (originCoords) => {
          setOrigin(originCoords);
          const map = displayMap(originCoords);
          if (props.destinationAddress) {
            geocodeAddress(props.destinationAddress, (destinationCoords) => {
              calculateAndDisplayRoute(map, originCoords, destinationCoords);
            });
          }
        });
      }
    });
  }, [props.localAddress, props.destinationAddress]); // Adicione as props como dependências

  function geocodeAddress(address, callback) {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === 'OK') {
        const location = results[0].geometry.location;
        callback(location);
      } else {
        console.error('Geocode was not successful for the following reason: ' + status);
      }
    });
  }

  function displayMap(originCoords) {
    const mapOptions = {
      center: originCoords,
      zoom: 14,
      mapId: 'b04c21d84bd0fad2'
    };
    const mapDiv = document.getElementById('map');
    return new window.google.maps.Map(mapDiv, mapOptions);
  }

  function calculateAndDisplayRoute(map, originCoords, destinationCoords) {
    const directionsService = new window.google.maps.DirectionsService();
    const directionsRenderer = new window.google.maps.DirectionsRenderer();
    directionsRenderer.setMap(map);

    const request = {
      origin: originCoords,
      destination: destinationCoords,
      travelMode: 'DRIVING'
    };

    directionsService.route(request, (result, status) => {
      if (status === 'OK') {
        directionsRenderer.setDirections(result);
        const route = result.routes[0];
        setDuration(route.legs[0].duration.text);
      } else {
        console.error('Error fetching directions:', status);
      }
    });
  }

  return (
    <div className="card cardDark border-0 shadow colorchart" style={{ position: 'relative', height: 520 }}>
      <div className="card-body p-2">
        <div className="tradingview-widget-container" style={{ position: 'relative' }}>
          <div id="map" className="divTradingView" style={{ position: 'relative' }}></div>
          {duration 
            ? <div>{t('Tempo estimado de deslocamento')}: {duration}</div>
            : <React.Fragment></React.Fragment>
          }
        </div>
      </div>
    </div>
  );
}

export default GoogleMapsWidget;
