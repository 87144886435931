import React, { useState, useEffect } from 'react';
import SmartBadge from '../SmartBadge/SmartBadge';
import { getCategorySuppliers } from '../../services/CategoryService';
import { useTranslation } from 'react-i18next';

function SelectCatFSettings(props) {
    const { t } = useTranslation();
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        async function fetchCategories() {
            try {
                const categories = await getCategorySuppliers();
                setCategories(categories);
            } catch (error) {
                console.error('Error fetching categories', error);
            }
        }

        fetchCategories();
    }, []);

    useEffect(() => {
        if (Array.isArray(props.value)) {
            setSelectedSuppliers(props.value);
        } else {
            setSelectedSuppliers([]);
        }
    }, [props.value]);

    function handleChange(event) {
        const value = event.target.value;
        if (value && !selectedSuppliers.includes(value)) {
            const newSuppliers = [...selectedSuppliers, value];
            setSelectedSuppliers(newSuppliers);
            props.onChange(newSuppliers); // Atualizar o estado pai aqui
        }
    }

    function handleRemove(supplier) {
        const newSuppliers = selectedSuppliers.filter(item => item !== supplier);
        setSelectedSuppliers(newSuppliers);
        props.onChange(newSuppliers); // Atualizar o estado pai aqui
    }

    return (
        <div className="select-category-s">
            <select id="selectFornecedor" value="" onChange={handleChange} className={`form-select me-4 ${props.className || ''}`}>
                <option value="">{t('Selecione')}...</option>
                {categories.map(category => (
                    <option key={category.id} value={category.name}>
                        {t(category.name)}
                    </option>
                ))}
            </select>
            <div className="badges mt-3 d-flex flex-wrap">
                {selectedSuppliers.map(supplier => (
                    <SmartBadge key={supplier} id={supplier} text={supplier} onClick={() => handleRemove(supplier)} />
                ))}
            </div>
        </div>
    );
}

export default SelectCatFSettings;