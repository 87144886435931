import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './SelectType.css';

function SelectType(props) {
    const { t } = useTranslation();
    const [type, setType] = useState('');

    useEffect(() => {
        setType(props.value);
    }, [props.value])

    function onChange(event) {
        setType(event.target.value); // Atualize o estado local também
        props.onChange(event);
    }

    return (
        <select 
            id="selectQuote" 
            className={`form-select me-4 ${props.errorClass || ''}`}  
            value={type} 
            onChange={onChange} 
            disabled={props.isDisabled || false}
        >
            <option value="">{t('Selecione')}...</option>
            <option value="2">{t('Prestadores de Serviço')}</option>
            <option value="3">{t('Fornecedor de produtos')}</option>
        </select>
    ) 
}

export default SelectType;
