import React from 'react';
import { useTranslation } from 'react-i18next';
import './TermsModal.css';

function TermsModal() {
    const { t } = useTranslation();
    return (
        <div className="modal fade" id="termsModal" tabIndex="-1" aria-labelledby="termsModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="termsModalLabel">{t('Termos de Uso da YEPWEDO')}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>{t('Bem-vindo ao yepwedo.com! A YEPWEDO é uma plataforma que conecta pessoas que necessitam de um serviço ou produto, facilitando essa interação e conexão.')} {t('Estes Termos de Uso descrevem as regras e regulamentos para o uso do site da YEPWEDO, localizado em www.yepwedo.com.')}</p>
                        <p><strong>{t('1. Definições')}</strong></p>
                        <p>{t('A seguinte terminologia aplica-se a estes Termos e Condições, Declaração de Privacidade e Aviso de Isenção de Responsabilidade e a todos os Acordos')}: {t('"Cliente", "Você" e "Seu" referem-se a você, a pessoa que faz login neste site e está em conformidade com os termos e condições da Empresa.')} {t('"A Empresa", "Nós", "Nosso" e "Nos", referem-se à nossa Empresa. "Parte", "Partes" ou "Nós", refere-se tanto ao Cliente quanto a nós mesmos.')}</p>
                        <p><strong>{t('2. Cookies')}</strong></p>
                        <p>{t('Utilizamos cookies. Ao acessar o yepwedo.com, você concorda em usar cookies de acordo com a Política de Privacidade da YEPWEDO.')} {t('Os cookies são usados pelo nosso site para permitir a funcionalidade de certas áreas, tornando mais fácil para as pessoas que visitam nosso site.')}</p>
                        <p><strong>{t('3. Licença')}</strong></p>
                        <p>{t('Salvo indicação em contrário, a YEPWEDO e/ou seus licenciadores possuem os direitos de propriedade intelectual de todo o material em yepwedo.com. Todos os direitos de propriedade intelectual são reservados.')} {t('Você pode acessar isso de yepwedo.com para seu uso pessoal, sujeito a restrições definidas nestes termos e condições.')}</p>
                        <p>{t('Você não deve')}:
                            <ul>
                                <li>{t('Republicar material de yepwedo.com')}</li>
                                <li>{t('Vender, alugar ou sub-licenciar material de yepwedo.com')}</li>
                                <li>{t('Reproduzir, duplicar ou copiar material de yepwedo.com')}</li>
                                <li>{t('Redistribuir conteúdo de yepwedo.com')}</li>
                            </ul>
                        </p>
                        <p><strong>{t('4. Ferramentas de Comunicação e Comunidades')}</strong></p>
                        <p>{t('Nossa plataforma YEPWEDO conecta pessoas que necessitam de um serviço ou produto, facilitando essa interação de conexão.')} {t('Temos ferramentas de comunicação como chat, e também podem ser criadas comunidades. Conectamos B2C e B2B.')} {t('Nossa plataforma é inteiramente gratuita.')}</p>
                        <p><strong>{t('5. Comentários e Interações')}</strong></p>
                        <p>{t('Partes deste site oferecem a oportunidade para os usuários postarem e trocarem opiniões e informações em determinadas áreas do site.')} {t('A YEPWEDO não filtra, edita, publica ou revisa Comentários antes de sua presença no site.')} {t('Comentários não refletem as opiniões e opiniões da YEPWEDO, seus agentes e/ou afiliados.')} {t('Comentários refletem as opiniões e opiniões da pessoa que postou suas opiniões.')} {t('Na medida permitida pelas leis aplicáveis, a YEPWEDO não será responsável pelos Comentários ou por qualquer responsabilidade, danos ou despesas causados e/ou sofridos como resultado de qualquer uso de e/ou postagem de e/ou aparição dos Comentários neste site.')}</p>
                        <p>{t('A YEPWEDO reserva-se o direito de monitorar todos os Comentários e remover quaisquer Comentários que possam ser considerados inadequados, ofensivos ou causem violação destes Termos e Condições.')}</p>
                        <p><strong>{t('6. Hiperlink para o nosso Conteúdo')}</strong></p>
                        <p>{t('As seguintes organizações podem linkar para o nosso site sem aprovação prévia por escrito')}:
                            <ul>
                                <li>{t('Agências governamentais')};</li>
                                <li>{t('Motores de busca')};</li>
                                <li>{t('Organizações de notícias')};</li>
                                <li>{t('Distribuidores de diretórios online podem linkar para o nosso site da mesma maneira que hiperlinkam para os sites de outras empresas listadas; e')} {t('Empresas Acreditadas em Sistema Amplamente, exceto organizações sem fins lucrativos solicitantes, shoppings de caridade e grupos de arrecadação de fundos de caridade, que não podem hiperlinkar para o nosso site.')}</li>
                            </ul>
                        </p>
                        <p>{t('Essas organizações podem linkar para a nossa página inicial, para publicações ou para outras informações do site, desde que o link')}: {t('(a) não seja de forma alguma enganoso')}; {t('(b) não implique falsamente patrocínio, endosso ou aprovação da parte vinculante e seus produtos e/ou serviços')}; {t('e (c) se encaixe no contexto do site da parte vinculante.')}</p>
                        <p><strong>{t('7. iFrames')}</strong></p>
                        <p>{t('Sem aprovação prévia e permissão por escrito, você não pode criar frames ao redor de nossas páginas da web que alterem de qualquer forma a apresentação visual ou a aparência do nosso site.')}</p>
                        <p><strong>{t('8. Responsabilidade pelo Conteúdo')}</strong></p>
                        <p>{t('Não seremos responsáveis por qualquer conteúdo que apareça em seu site.')} {t('Você concorda em proteger e defender-nos contra todas as reivindicações que estão surgindo em seu site.')} {t('Nenhum link(s) deve aparecer em qualquer site que possa ser interpretado como calunioso, obsceno ou criminal, ou que infrinja, de outra forma viole, ou defenda a violação ou outra violação de quaisquer direitos de terceiros.')}</p>
                        <p><strong>{t('9. Reserva de Direitos')}</strong></p>
                        <p>{t('Reservamos o direito de solicitar que você remova todos os links ou qualquer link específico para o nosso site.')} {t('Você aprova remover imediatamente todos os links para o nosso site mediante solicitação.')} {t('Também reservamos o direito de alterar estes termos e condições e sua política de vinculação a qualquer momento.')} {t('Ao vincular-se continuamente ao nosso site, você concorda em ficar vinculado e seguir estes termos e condições de vinculação.')}</p>
                        <p><strong>{t('10. Remoção de links do nosso site')}</strong></p>
                        <p>{t('Se você encontrar qualquer link em nosso site que seja ofensivo por qualquer motivo, você está livre para nos contatar e nos informar a qualquer momento.')} {t('Consideraremos solicitações para remover links, mas não somos obrigados a fazê-lo ou a responder a você diretamente.')}</p>
                        <p><strong>{t('11. Isenção de responsabilidade')}</strong></p>
                        <p>{t('Na máxima extensão permitida pela lei aplicável, excluímos todas as representações, garantias e condições relativas ao nosso site e ao uso deste site.')} {t('Nada nesta isenção de responsabilidade irá')}:
                            <ul>
                                <li>{t('Limitar ou excluir nossa responsabilidade ou a sua por morte ou lesão pessoal')};</li>
                                <li>{t('Limitar ou excluir nossa responsabilidade ou a sua por fraude ou deturpação fraudulenta')};</li>
                                <li>{t('Limitar qualquer uma de nossas responsabilidades ou as suas de qualquer maneira que não seja permitida pela lei aplicável; ou')} {t('Excluir qualquer uma de nossas responsabilidades ou as suas que não possam ser excluídas pela lei aplicável.')}</li>
                            </ul>
                        </p>
                        <p>{t('As limitações e proibições de responsabilidade estabelecidas nesta Seção e em outros lugares desta isenção de responsabilidade')}: {t('(a) estão sujeitas ao parágrafo anterior')}; {t('e (b) governam todas as responsabilidades decorrentes da isenção de responsabilidade, incluindo responsabilidades decorrentes de contrato, ato ilícito e violação de dever estatutário.')}</p>
                        <p><strong>{t('12. Serviços gratuitos')}</strong></p>
                        <p>{t('A YEPWEDO é uma plataforma inteiramente gratuita.')} {t('Não cobramos pelo uso de nossos serviços de conexão, comunicação e criação de comunidade.')}</p>
                        <p><strong>{t('13. Lei Aplicável')}</strong></p>
                        <p>{t('Estes Termos serão regidos e interpretados de acordo com as leis do Brasil, e você se submete irrevogavelmente à jurisdição exclusiva dos tribunais localizados no Brasil para resolver qualquer conflito.')}</p>
                        <p><strong>{t('14. Alterações nos Termos')}</strong></p>
                        <p>{t('Reservamos o direito de modificar estes Termos de Uso a qualquer momento, portanto, revise-os frequentemente.')} {t('Mudanças e esclarecimentos vão surtir efeito imediatamente após sua publicação no site.')} {t('Se fizermos alterações materiais a estes termos, notificaremos aqui que eles foram atualizados.')}</p>
                        <p>{t('Última atualização')}: {t('14/06/2024')}</p>
                        <p>{t('Se você tiver alguma dúvida sobre nossos Termos de Uso, entre em contato conosco pelo e-mail: support@yepwedo.com')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TermsModal;