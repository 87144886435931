import React, { useRef, useState, useEffect } from 'react';
import './NewOrderModal.css';
import SelectType from '../SelectType/SelectType';
import SelectCategoryS from '../SelectCategory/SelectCategoryS';
import SelectCategoryF from '../SelectCategory/SelectCategoryF';
import { getSettings, getRedes } from '../../services/SettingsService';
import { updateNewdOrder } from '../../services/OrdersService';
import StarRatingComponent from 'react-star-rating-component';
import { getAverageRating } from '../../services/RatingService';
import { useTranslation } from 'react-i18next';

function NewOrderModal(props) {
    console.log(props);
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const [errors, setErrors] = useState({});
    const [isVisible, setIsVisible] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);
    const [categorias, setCategorias] = useState([]);
    const [images, setImages] = useState([]);
    const [arrayImages, setArrayImages] = useState([]);
    const [rating, setRating] = useState(0);
    const [detailedRatings, setDetailedRatings] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [redes, setRedes] = useState({});

    const today = new Date().toISOString().split('T')[0];

    const DEFAULT_FAVORITE = {
        name: '',
        sobrenamo: ''
    }

    const DEFAULT_ORDER = {
        type: '',
        name: '',
        sobrenamo: '',
        street: '',
        state: '',
        status: 'ABERTO',
        country: '',
        city: '',
        phone: '',
        cep: '',
        number: '',
        neighborhood: '',
        job: '',
        cnpj: '',
        categoriaS: '',
        categoriaF: '',
        photoName: '',       
        description: '',
        userId: ''
    };

    const [order, setOrder] = useState(DEFAULT_ORDER);
    const [defaultFavorite, setDefaultFavorite] = useState(DEFAULT_FAVORITE);

    const btnClose = useRef('');
    const btnSend = useRef('');

    /*
    useEffect(() => {
        const modal = document.getElementById('modalOrder');
        modal.addEventListener('hidden.bs.modal', (event) => {
            setIsVisible(false);
            setImages([]);
        });
        modal.addEventListener('shown.bs.modal', (event) => {
            setIsVisible(true);
        });
    }, []);

    useEffect(() => {
        getSettings()
            .then(result => {
                setOrder(result);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
            })
    }, []);
    */

    useEffect(() => {
        const modal = document.getElementById('modalOrder');
        modal.addEventListener('hidden.bs.modal', (event) => {
            setIsVisible(false);
            setImages([]);
            setArrayImages([]);
            setOrder(DEFAULT_ORDER);
            setDefaultFavorite(DEFAULT_FAVORITE);
            setErrors({});
            setRating(0);
            setDetailedRatings({});
            setIsFavorite(false);
            setCategorias([]);
            setIsSubmitting(false); // Libera o botão quando a modal for fechada
        });
    
        modal.addEventListener('shown.bs.modal', (event) => {
            setIsVisible(true);
            if (!props.data) {
                getSettings()
                    .then(result => {
                        setOrder(result);
                    })
                    .catch(err => {
                        console.error(err.response ? err.response.data : err.message);
                    });
            }
            setIsSubmitting(false);
        });
    
        return () => {
            modal.removeEventListener('hidden.bs.modal', () => {});
            modal.removeEventListener('shown.bs.modal', () => {});
        };
    }, []);


    useEffect(() => {
        if (!props.data) return;
        if (Object.entries(props.data).length === 0) return;

        getSettings()
            .then(result => {
                setOrder(result);
                if (props.data.type === '3') {
                    setOrder(prevState => ({ ...prevState, type: props.data.type, 
                        favoriteId: props.data.supplierId,
                        rating: props.data.rating
                    }));
                    setDefaultFavorite(prevState => ({ ...prevState, name: props.data.name,
                        sobrenamo: props.data.sobrenamo
                    }));
                    setCategorias(JSON.parse(props.data.categoriaF));
                    setIsFavorite(true);
                } else {
                    setOrder(prevState => ({ ...prevState, type: props.data.type, 
                        favoriteId: props.data.providerId,
                        rating: props.data.rating
                    }));
                    setDefaultFavorite(prevState => ({ ...prevState, name: props.data.name,
                        sobrenamo: props.data.sobrenamo
                    }));
                    setCategorias(JSON.parse(props.data.categoriaS));
                    setIsFavorite(true);
                }

            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
            });
        
    }, [props.data]);

    useEffect(() => {
        const fetchData = async (id) => {
            try {
                const rating = await getAverageRating(id);
                setRating(rating.averageRating);
                setDetailedRatings(rating.detailedRatings);
                const redesData = await getRedes(id);
                setRedes(redesData);
            } catch (error) {
                console.error(error);
            }
        };
    
        if (order.favoriteId) {
            fetchData(order.favoriteId);
        }
    }, [order.favoriteId]);

    function openSocialMediaLink(url) {
        btnClose.current.click();
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    function onSubmit(event) {
        event.preventDefault();
        setIsSubmitting(true); // Bloqueia o botão ao submeter
        //console.log(order);
        let validationErrors = {};

        if (!order.checked) {
            validationErrors.checked = t('Por favor, complete o CADASTRO para poder realizar solicitações.');
        }
        if (!order.type || order.type === '1') {
            validationErrors.type = t('Selecione o tipo de fornecedor.');
        }

        if (!order.description) {
            validationErrors.description = t('Por favor, preencha a descrição.');
        }

        if (!order.disponibility) {
            validationErrors.disponibility = t('Selecione a data de disponibilidade.');
        }

        if (order.type === '3' && (!order.categoriaF || order.categoriaF === '[]')) {
            validationErrors.categoriaF = t('Preencha a categoria do fornecedor.');
        }

        if (order.type === '2' && (!order.categoriaS || order.categoriaS === '[]')) {
            validationErrors.categoriaS = t('Preencha a categoria do prestador.');
        }
        
        if (order.disponibility < today) {
            validationErrors.disponibility = t('A data de disponibilidade não pode ser menor que a data atual.');
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setIsSubmitting(false);
            return;
        }

        const formData = new FormData();

        arrayImages.forEach((file, index) => {
            formData.append(`file`, file);
        });
        formData.append('userId', order.id);
        formData.append('name', order.name);
        formData.append('sobrenamo', order.sobrenamo);
        formData.append('state', order.state);
        formData.append('country', order.country);
        formData.append('city', order.city);
        formData.append('cep', order.cep);
        formData.append('street', order.street);
        formData.append('number', order.number);
        formData.append('neighborhood', order.neighborhood);
        formData.append('job', order.job);
        formData.append('cnpj', order.cnpj);
        formData.append('type', order.type);
        formData.append('status', order.status);
        formData.append('phone', order.phone);
        formData.append('description', order.description);
        formData.append('categoriaS', order.categoriaS);
        formData.append('categoriaF', order.categoriaF);
        formData.append('disponibility', converterData(order.disponibility));
        formData.append('currentLanguage', currentLanguage);

        if (props.data) {
            formData.append('favoriteId', order.favoriteId);
        }
        
        updateNewdOrder(formData)
            .then(result => {
                setArrayImages([]);
                btnClose.current.click();
                if (props.onEnviou){
                    props.onEnviou();
                }
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setIsSubmitting(false);
            });
    }

    function formatarDataHora(data) {
        var data_hora = new Date(data);
        var dia = data_hora.getDate();
        var mes = data_hora.getMonth() + 1;
        var ano = data_hora.getFullYear() % 100;
        var hora = data_hora.getHours();
        var minutos = data_hora.getMinutes();
        var periodo = "AM";
        if (hora >= 12) {
            periodo = "PM";
            hora -= 12;
        }
        if (hora === 0) {
            hora = 12;
        }
        var data_formatada = dia + '/' + mes + '/' + ano + ', ' + hora + ':' + (minutos < 10 ? '0' : '') + minutos + ' ' + periodo;
        return data_formatada;
    }

    function converterData(data) {
        if(data && data !== undefined){
            const [ano, mes, dia] = data.split('-');
            return `${dia}-${mes}-${ano}`;
        }else{
            return null;
        }
    }

    function onInputChange(event) {
        const { id, value } = event.target;
        setOrder(prevState => ({ ...prevState, [id]: value }));
        setErrors(prevErrors => ({ ...prevErrors, [id]: '' }));
    }

    function onImageChange(event) {
        const files = event.target.files;
        setArrayImages(prevArray => [...prevArray, ...files]);
        if (files && files.length > 0) {
            const newImages = [];
            for (let i = 0; i < files.length; i++) {
                newImages.push(URL.createObjectURL(files[i]));
            }
            setImages(prevImages => [...prevImages, ...newImages]);
        }
    }

    function removeImage(index) {
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
        setArrayImages(prevArrayImages => prevArrayImages.filter((_, i) => i !== index));
    }

    function renderImagePreviews() {
        return images.map((image, index) => (
            <div key={index} className="image-preview-container">
                <img src={image} alt={`Image ${index}`} className="image-preview" />
                <button type="button" className="btn-remove-image" onClick={() => removeImage(index)}>x</button>
            </div>
        ));
    }

    function onTypeChange(event) {
        setErrors(prevErrors => ({ ...prevErrors, type: '' }));
        setOrder(prevState => ({ ...prevState, type: event.target.value }));
    }

    function onCategorySChange(event) {
        if (event === null || event === '[]') return;
        setErrors(prevErrors => ({ ...prevErrors, categoriaS: '' }));
        setOrder(prevState => ({ ...prevState, categoriaS: event.value }));
    }

    function onCategoryFChange(event) {
        if (event === null || event === '[]') return;
        setErrors(prevErrors => ({ ...prevErrors, categoriaF: '' }));
        setOrder(prevState => ({ ...prevState, categoriaF: event.value }));
    }

    function onCategorySFavorite(event) {
        if (event === null || event === '[]') return;
        setErrors(prevErrors => ({ ...prevErrors, categoriaS: '' }));
        setOrder(prevState => ({ ...prevState, categoriaS: event.target.value }));
    }

    function onCategoryFFavorite(event) {
        if (event === null || event === '[]') return;
        setErrors(prevErrors => ({ ...prevErrors, categoriaF: '' }));
        setOrder(prevState => ({ ...prevState, categoriaF: event.target.value }));
    }

    return (
        <div className="modal fade" id="modalOrder" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered custom-modal" role="document">
                <div className="modal-content">
                    { order.rating && order.rating === true
                        ? <React.Fragment>
                        <div className="modal-header">
                            <p className="modal-title">{t('Comentários')}</p>
                            <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body divScroll-2">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="outra">{defaultFavorite.name} {defaultFavorite.sobrenamo}</label>
                                        <div className="col-md-12 mb-3 text-left  mt-n3">
                                                <StarRatingComponent
                                                    name="rating"
                                                    starCount={5}
                                                    value={rating}
                                                    emptyStarColor="#1455aa"
                                                />
                                        </div>
                                    </div>
                                </div>
                                {
                                    detailedRatings && detailedRatings.length > 0
                                        ? detailedRatings.map(item => 
                                            item.dataValues.comments !== ''
                                                ? <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <label htmlFor="outras">{item.name} {item.sobrenamo} </label> - <span className="small">{formatarDataHora(item.dataValues.createdAt)}</span>
                                                            <p>{item.dataValues.comments}</p>
                                                        </div>
                                                    </div>
                                                    </React.Fragment>
                                                : <React.Fragment></React.Fragment> )
                                        : <React.Fragment></React.Fragment>
                                }
                            </div>
                        </div></React.Fragment>
                        : ( <React.Fragment>
                                <div className="modal-header">
                                    <p className="modal-title" id="modalTitleNotify">{t('Solicitação')}</p>
                                    <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-4 mb-3">
                                                <div className="form-group">
                                                    <label htmlFor="symbol">{t('Tipo de Fornecedor')}*</label>
                                                    <SelectType 
                                                        value={order.type} 
                                                        onChange={onTypeChange} 
                                                        isDisabled={props.isSelectDisabled} 
                                                        required 
                                                        errorClass={errors.type ? 'error' : ''}
                                                    />
                                                    {errors.type && <div className="error-message" style={{ marginTop: '8px' }}>{errors.type}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <div className="form-group">
                                                    <label htmlFor="symbol">{t('Categoria do Fornecedor')}*</label>
                                                    {
                                                        !isFavorite
                                                            ? order.type && order.type === '2'
                                                                ? <SelectCategoryS 
                                                                    value={order.categoriaS} 
                                                                    onChange={onCategorySChange} 
                                                                    className={errors.categoriaS ? 'error' : ''}
                                                                />
                                                                : <SelectCategoryF 
                                                                    value={order.categoriaF} 
                                                                    onChange={onCategoryFChange} 
                                                                    className={errors.categoriaF ? 'error' : ''}
                                                                />
                                                            : order.type && order.type === '2'
                                                                ? <select 
                                                                    id="selectCategorias" 
                                                                    className={`form-select me-4 ${errors.categoriaS ? 'error' : ''}`} 
                                                                    value={order.categoriaS} 
                                                                    onChange={onCategorySFavorite}
                                                                >
                                                                    <option value="">{t('Selecione')}...</option>
                                                                    {categorias.map(category => (
                                                                        <option key={category} value={category}>
                                                                            {t(category)}
                                                                        </option>
                                                                    ))} 
                                                                </select>
                                                                : <select 
                                                                    id="selectCategorias" 
                                                                    className={`form-select me-4 ${errors.categoriaF ? 'error' : ''}`} 
                                                                    value={order.categoriaF} 
                                                                    onChange={onCategoryFFavorite}
                                                                >
                                                                    <option value="">{t('Selecione')}...</option>
                                                                    {categorias.map(category => (
                                                                        <option key={category} value={category}>
                                                                            {t(category)}
                                                                        </option>
                                                                    ))} 
                                                                </select>
                                                    }
                                                    {errors.categoriaS && <div className="error-message" style={{ marginTop: '8px' }}>{errors.categoriaS}</div>}
                                                    {errors.categoriaF && <div className="error-message" style={{ marginTop: '8px' }}>{errors.categoriaF}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="disponibility">{t('Data prevista')}*</label>
                                                <input 
                                                    type="date" 
                                                    id="disponibility" 
                                                    className={`form-control ${errors.disponibility ? 'error' : ''}`} 
                                                    onChange={onInputChange} 
                                                    min={today} 
                                                />
                                                {errors.disponibility && <div className="error-message" style={{ marginTop: '8px' }}>{errors.disponibility}</div>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="description">{t('Descrição')}*</label>
                                                <textarea 
                                                    id="description" 
                                                    className={`form-control mb-3 ${errors.description ? 'error' : ''}`} 
                                                    placeholder={t('Informe suas necessidades e se você fornecerá o material ou se deve ser cotado.')}
                                                    value={order.description} 
                                                    onChange={onInputChange}
                                                    style={{ 
                                                        width: '100%', 
                                                        height: 'auto', 
                                                        minHeight: '80px',  
                                                        overflow: 'hidden', 
                                                        whiteSpace: 'pre-wrap',
                                                        wordWrap: 'break-word'
                                                    }}
                                                />
                                                {errors.description && <div className="error-message">{errors.description}</div>}
                                            </div>
                                        </div>
                                        <div className="row image-input">
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="images">{t('Arraste suas imagens para dentro do campo abaixo!')}</label>
                                                <input type="file" id="images" className="form-control" multiple onChange={onImageChange} accept="image/*" />
                                                {renderImagePreviews()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="row w-100">
                                        <div className="col-md-6 mb-3 d-flex align-items-center">
                                            {redes.facebook && redes.facebook !== '' && redes.facebook !== null && (
                                                <button type="button" className="btn btn-xs" onClick={() => openSocialMediaLink(redes.facebook)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#1877F2" className="icon icon-xs me-2">
                                                        <path d="M22.675 0h-21.35c-.734 0-1.325.592-1.325 1.325v21.351c0 .733.591 1.324 1.325 1.324h11.498v-9.294h-3.128v-3.622h3.128v-2.672c0-3.1 1.893-4.785 4.658-4.785 1.324 0 2.463.098 2.796.142v3.24h-1.918c-1.504 0-1.796.715-1.796 1.763v2.312h3.587l-.467 3.622h-3.12v9.294h6.116c.734 0 1.325-.591 1.325-1.324v-21.35c0-.733-.591-1.325-1.325-1.325z"/>
                                                    </svg>
                                                </button>
                                            )}
                                            {redes.instagram && redes.instagram !== '' && redes.instagram !== null && (
                                                <button type="button" className="btn btn-xs" onClick={() => openSocialMediaLink(redes.instagram)}>
                                                    <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 132.004 132"
                                                                    className="icon icon-xs me-2"
                                                                >
                                                                    <defs>
                                                                    <linearGradient id="b">
                                                                        <stop offset="0" stopColor="#3771c8" />
                                                                        <stop stopColor="#3771c8" offset=".128" />
                                                                        <stop offset="1" stopColor="#60f" stopOpacity="0" />
                                                                    </linearGradient>
                                                                    <linearGradient id="a">
                                                                        <stop offset="0" stopColor="#fd5" />
                                                                        <stop offset=".1" stopColor="#fd5" />
                                                                        <stop offset=".5" stopColor="#ff543e" />
                                                                        <stop offset="1" stopColor="#c837ab" />
                                                                    </linearGradient>
                                                                    <radialGradient
                                                                        id="c"
                                                                        cx="158.429"
                                                                        cy="578.088"
                                                                        r="65"
                                                                        gradientUnits="userSpaceOnUse"
                                                                        gradientTransform="matrix(0 -1.98198 1.8439 0 -1031.402 454.004)"
                                                                        fx="158.429"
                                                                        fy="578.088"
                                                                        xlinkHref="#a"
                                                                    />
                                                                    <radialGradient
                                                                        id="d"
                                                                        cx="147.694"
                                                                        cy="473.455"
                                                                        r="65"
                                                                        gradientUnits="userSpaceOnUse"
                                                                        gradientTransform="matrix(.17394 .86872 -3.5818 .71718 1648.348 -458.493)"
                                                                        fx="147.694"
                                                                        fy="473.455"
                                                                        xlinkHref="#b"
                                                                    />
                                                                    </defs>
                                                                    <path
                                                                    fill="url(#c)"
                                                                    d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
                                                                    transform="translate(1.004 1)"
                                                                    />
                                                                    <path
                                                                    fill="url(#d)"
                                                                    d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
                                                                    transform="translate(1.004 1)"
                                                                    />
                                                                    <path
                                                                    fill="#fff"
                                                                    d="M66.004 18c-13.036 0-14.672.057-19.792.29-5.11.234-8.598 1.043-11.65 2.23-3.157 1.226-5.835 2.866-8.503 5.535-2.67 2.668-4.31 5.346-5.54 8.502-1.19 3.053-2 6.542-2.23 11.65C18.06 51.327 18 52.964 18 66s.058 14.667.29 19.787c.235 5.11 1.044 8.598 2.23 11.65 1.227 3.157 2.867 5.835 5.536 8.503 2.667 2.67 5.345 4.314 8.5 5.54 3.054 1.187 6.543 1.996 11.652 2.23 5.12.233 6.755.29 19.79.29 13.037 0 14.668-.057 19.788-.29 5.11-.234 8.602-1.043 11.656-2.23 3.156-1.226 5.83-2.87 8.497-5.54 2.67-2.668 4.31-5.346 5.54-8.502 1.18-3.053 1.99-6.542 2.23-11.65.23-5.12.29-6.752.29-19.788 0-13.036-.06-14.672-.29-19.792-.24-5.11-1.05-8.598-2.23-11.65-1.23-3.157-2.87-5.835-5.54-8.503-2.67-2.67-5.34-4.31-8.5-5.535-3.06-1.187-6.55-1.996-11.66-2.23-5.12-.233-6.75-.29-19.79-.29zm-4.306 8.65c1.278-.002 2.704 0 4.306 0 12.816 0 14.335.046 19.396.276 4.68.214 7.22.996 8.912 1.653 2.24.87 3.837 1.91 5.516 3.59 1.68 1.68 2.72 3.28 3.592 5.52.657 1.69 1.44 4.23 1.653 8.91.23 5.06.28 6.58.28 19.39s-.05 14.33-.28 19.39c-.214 4.68-.996 7.22-1.653 8.91-.87 2.24-1.912 3.835-3.592 5.514-1.68 1.68-3.275 2.72-5.516 3.59-1.69.66-4.232 1.44-8.912 1.654-5.06.23-6.58.28-19.396.28-12.817 0-14.336-.05-19.396-.28-4.68-.216-7.22-.998-8.913-1.655-2.24-.87-3.84-1.91-5.52-3.59-1.68-1.68-2.72-3.276-3.592-5.517-.657-1.69-1.44-4.23-1.653-8.91-.23-5.06-.276-6.58-.276-19.398s.046-14.33.276-19.39c.214-4.68.996-7.22 1.653-8.912.87-2.24 1.912-3.84 3.592-5.52 1.68-1.68 3.28-2.72 5.52-3.592 1.692-.66 4.233-1.44 8.913-1.655 4.428-.2 6.144-.26 15.09-.27zm29.928 7.97c-3.18 0-5.76 2.577-5.76 5.758 0 3.18 2.58 5.76 5.76 5.76 3.18 0 5.76-2.58 5.76-5.76 0-3.18-2.58-5.76-5.76-5.76zm-25.622 6.73c-13.613 0-24.65 11.037-24.65 24.65 0 13.613 11.037 24.645 24.65 24.645C79.617 90.645 90.65 79.613 90.65 66S79.616 41.35 66.003 41.35zm0 8.65c8.836 0 16 7.163 16 16 0 8.836-7.164 16-16 16-8.837 0-16-7.164-16-16 0-8.837 7.163-16 16-16z"
                                                                    />
                                                                </svg>
                                                </button>
                                            )}
                                            {redes.twitter && redes.twitter !== '' && redes.twitter !== null && (
                                                <button type="button" className="btn btn-xs" onClick={() => openSocialMediaLink(redes.twitter)}>
                                                    <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlSpace="preserve"
                                                                    className="icon icon-xs me-2"
                                                                    version="1.1"
                                                                    style={{
                                                                    shapeRendering: "geometricPrecision",
                                                                    textRendering: "geometricPrecision",
                                                                    imageRendering: "optimizeQuality",
                                                                    fillRule: "evenodd",
                                                                    clipRule: "evenodd"
                                                                    }}
                                                                    viewBox="0 0 211.07 210.92"
                                                                >
                                                                    <defs>
                                                                    <style type="text/css">
                                                                        {`
                                                                        .fil1 {fill:#040404}
                                                                        .fil0 {fill:#FAFAFA}
                                                                        `}
                                                                    </style>
                                                                    </defs>
                                                                    <g id="Layer_x0020_1">
                                                                    <metadata id="CorelCorpID_0Corel-Layer" />
                                                                    <g id="X_icon_2.svg">
                                                                        <g>
                                                                        <path
                                                                            className="fil0"
                                                                            d="M43.28 43.06c0.42,0.61 10.26,14.93 21.86,31.82 11.6,16.9 22.33,32.52 23.84,34.72 1.51,2.2 2.75,4.05 2.75,4.1 0,0.05 -0.56,0.75 -1.25,1.54 -0.69,0.8 -2.63,3.05 -4.31,5 -1.68,1.96 -4.52,5.25 -6.3,7.32 -1.78,2.07 -4.92,5.72 -6.97,8.11 -2.05,2.39 -5.74,6.68 -8.2,9.53 -7.6,8.84 -8.7,10.11 -14.48,16.85 -3.09,3.6 -6.05,7.04 -6.58,7.65 -0.53,0.61 -0.97,1.17 -0.97,1.24 0,0.09 1.94,0.14 5.47,0.14l5.47 0 6.01 -7c3.31,-3.85 6.57,-7.64 7.25,-8.43 1.47,-1.69 12.68,-14.74 13.62,-15.85 0.36,-0.43 0.89,-1.04 1.16,-1.35 0.28,-0.32 2.49,-2.88 4.9,-5.69 2.42,-2.82 4.46,-5.19 4.54,-5.27 0.08,-0.08 1.36,-1.57 2.84,-3.31 1.48,-1.73 2.74,-3.15 2.8,-3.15 0.06,0 7.67,11.02 16.92,24.5 9.25,13.47 16.98,24.74 17.19,25.03l0.37 0.53 18.75 0c15.42,0 18.74,-0.03 18.68,-0.18 -0.06,-0.16 -9.05,-13.27 -32.1,-46.82 -16.59,-24.15 -18.8,-27.4 -18.74,-27.56 0.06,-0.16 2.32,-2.8 17.18,-20.1 2.55,-2.97 6.06,-7.06 7.81,-9.09 1.75,-2.03 3.51,-4.08 3.92,-4.55 0.41,-0.47 2.56,-2.97 4.77,-5.55 2.22,-2.58 5.99,-6.97 8.37,-9.75 2.39,-2.78 4.43,-5.16 4.54,-5.3 0.18,-0.23 -0.13,-0.24 -5.36,-0.24l-5.55 0 -2.47 2.88c-3.29,3.83 -9.23,10.74 -10.92,12.7 -0.75,0.86 -1.68,1.95 -2.07,2.41 -0.39,0.46 -1.16,1.36 -1.71,1.99 -0.55,0.63 -3.33,3.87 -6.18,7.19 -2.85,3.32 -5.22,6.08 -5.26,6.12 -0.05,0.04 -0.66,0.75 -1.36,1.57 -1.22,1.45 -2.46,2.89 -8.16,9.5 -2.5,2.9 -2.63,3.03 -2.84,2.76 -0.12,-0.15 -7.45,-10.82 -16.29,-23.7l-16.07 -23.42 -18.82 -0 -18.82 -0 0.77 1.1 0 0zm14.69 7.68c0.14,0.22 3.66,5.26 7.81,11.2 7.88,11.27 38.86,55.61 58.78,84.13 6.37,9.11 11.65,16.65 11.75,16.75 0.14,0.14 1.99,0.17 8.66,0.14l8.49 -0.04 -22.21 -31.79c-12.22,-17.48 -29.9,-42.8 -39.31,-56.25l-17.09 -24.46 -8.57 -0.04 -8.57 -0.04 0.26 0.39z"
                                                                        />
                                                                        </g>
                                                                        <g>
                                                                        <path
                                                                            className="fil1"
                                                                            d="M-0 105.46l0 105.46 105.5 -0.04 105.5 -0.04 0.04 -105.43 0.04 -105.43 -105.53 0 -105.53 0 0 105.46 0 0zm0.07 0.07c0,58 0.02,81.73 0.04,52.73 0.02,-29 0.02,-76.46 0,-105.46 -0.02,-29 -0.04,-5.27 -0.04,52.73l0 0zm43.21 -62.47c0.42,0.61 10.26,14.93 21.86,31.82 11.6,16.9 22.33,32.52 23.84,34.72 1.51,2.2 2.75,4.05 2.75,4.1 0,0.05 -0.56,0.75 -1.25,1.54 -0.69,0.8 -2.63,3.05 -4.31,5 -1.68,1.96 -4.52,5.25 -6.3,7.32 -1.78,2.07 -4.92,5.72 -6.97,8.11 -2.05,2.39 -5.74,6.68 -8.2,9.53 -7.6,8.84 -8.7,10.11 -14.48,16.85 -3.09,3.6 -6.05,7.04 -6.58,7.65 -0.53,0.61 -0.97,1.17 -0.97,1.24 0,0.09 1.94,0.14 5.47,0.14l5.47 0 6.01 -7c3.31,-3.85 6.57,-7.64 7.25,-8.43 1.47,-1.69 12.68,-14.74 13.62,-15.85 0.36,-0.43 0.89,-1.04 1.16,-1.35 0.28,-0.32 2.49,-2.88 4.9,-5.69 2.42,-2.82 4.46,-5.19 4.54,-5.27 0.08,-0.08 1.36,-1.57 2.84,-3.31 1.48,-1.73 2.74,-3.15 2.8,-3.15 0.06,0 7.67,11.02 16.92,24.5 9.25,13.47 16.98,24.74 17.19,25.03l0.37 0.53 18.75 0c15.42,0 18.74,-0.03 18.68,-0.18 -0.06,-0.16 -9.05,-13.27 -32.1,-46.82 -16.59,-24.15 -18.8,-27.4 -18.74,-27.56 0.06,-0.16 2.32,-2.8 17.18,-20.1 2.55,-2.97 6.06,-7.06 7.81,-9.09 1.75,-2.03 3.51,-4.08 3.92,-4.55 0.41,-0.47 2.56,-2.97 4.77,-5.55 2.22,-2.58 5.99,-6.97 8.37,-9.75 2.39,-2.78 4.43,-5.16 4.54,-5.3 0.18,-0.23 -0.13,-0.24 -5.36,-0.24l-5.55 0 -2.47 2.88c-3.29,3.83 -9.23,10.74 -10.92,12.7 -0.75,0.86 -1.68,1.95 -2.07,2.41 -0.39,0.46 -1.16,1.36 -1.71,1.99 -0.55,0.63 -3.33,3.87 -6.18,7.19 -2.85,3.32 -5.22,6.08 -5.26,6.12 -0.05,0.04 -0.66,0.75 -1.36,1.57 -1.22,1.45 -2.46,2.89 -8.16,9.5 -2.5,2.9 -2.63,3.03 -2.84,2.76 -0.12,-0.15 -7.45,-10.82 -16.29,-23.7l-16.07 -23.42 -18.82 -0 -18.82 -0 0.77 1.1 0 0zm14.69 7.68c0.14,0.22 3.66,5.26 7.81,11.2 7.88,11.27 38.86,55.61 58.78,84.13 6.37,9.11 11.65,16.65 11.75,16.75 0.14,0.14 1.99,0.17 8.66,0.14l8.49 -0.04 -22.21 -31.79c-12.22,-17.48 -29.9,-42.8 -39.31,-56.25l-17.09 -24.46 -8.57 -0.04 -8.57 -0.04 0.26 0.39z"
                                                                        />
                                                                        </g>
                                                                    </g>
                                                                    </g>
                                                                </svg>
                                                </button>
                                            )}
                                            {redes.tiktok && redes.tiktok !== '' && redes.tiktok !== null && (
                                                <button type="button" className="btn btn-xs" onClick={() => openSocialMediaLink(redes.tiktok)}>
                                                    <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 512 512"
                                                                    className="icon icon-xs me-2"
                                                                >
                                                                    <defs>
                                                                        <style>
                                                                            {`
                                                                            .cls-1 { fill: #00f6ef; }
                                                                            .cls-2 { fill: #fff; }
                                                                            .cls-3 { fill: #ff004f; }
                                                                            `}
                                                                        </style>
                                                                    </defs>
                                                                    <rect height="512" rx="71.15" width="512" x="0" y="0" />
                                                                    <path
                                                                        className="cls-1"
                                                                        d="M389.39,221.92V164.85c-74.6-3.15-77-70.94-77-77.31v-.48H254.73V309.33h0a45.66,45.66,0,1,1-32.36-43.71V206.76a104.57,104.57,0,0,0-13.32-.85A103.42,103.42,0,1,0,312.47,309.33c0-1.45,0-2.89-.1-4.32V195.56C338.92,219.85,389.39,221.92,389.39,221.92Z"
                                                                    />
                                                                    <path
                                                                        className="cls-2"
                                                                        d="M406.37,236V178.9c-74.61-3.15-77-70.94-77-77.31v-.48H271.71V323.38h0a45.66,45.66,0,1,1-32.36-43.7V220.81A104.57,104.57,0,0,0,226,220,103.42,103.42,0,1,0,329.45,323.38c0-1.45,0-2.89-.1-4.32V209.61C355.9,233.9,406.37,236,406.37,236Z"
                                                                    />
                                                                    <path
                                                                        className="cls-3"
                                                                        d="M313.82,101.11c2.78,15.14,10.9,38.81,34.57,52.66-18.09-21.07-19-48.26-19-52.18v-.48Z"
                                                                    />
                                                                    <path
                                                                        className="cls-3"
                                                                        d="M406.37,236V178.9a106.46,106.46,0,0,1-17-2v44.95s-50.47-2.07-77-26.36V304.91c.06,1.43.1,2.87.1,4.32a103.43,103.43,0,0,1-160.72,86.1,103.41,103.41,0,0,0,177.7-71.95c0-1.45,0-2.89-.1-4.32V209.61C355.9,233.9,406.37,236,406.37,236Z"
                                                                    />
                                                                    <path
                                                                        className="cls-3"
                                                                        d="M222.37,265.53a45.69,45.69,0,0,0-33.19,84.85,45.69,45.69,0,0,1,50.17-70.7V220.81A104.57,104.57,0,0,0,226,220c-1.23,0-2.44,0-3.66.07Z"
                                                                    />
                                                                </svg>
                                                </button>
                                            )}
                                            {redes.linkedin && redes.linkedin !== '' && redes.linkedin !== null && (
                                                <button type="button" className="btn btn-xs" onClick={() => openSocialMediaLink(redes.linkedin)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#0A66C2" className="icon icon-xs me-2">
                                                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5v-14c0-2.761-2.239-5-5-5zm-11 19h-3v-10h3v10zm-1.5-11.249c-.966 0-1.75-.785-1.75-1.751s.784-1.75 1.75-1.75 1.75.784 1.75 1.75-.784 1.751-1.75 1.751zm13.5 11.249h-3v-5.5c0-1.379-1.121-2.5-2.5-2.5s-2.5 1.121-2.5 2.5v5.5h-3v-10h3v1.25c.878-.926 2.144-1.5 3.5-1.5 2.481 0 4.5 2.019 4.5 4.5v5.75z"/>
                                                    </svg>
                                                </button>
                                            )}
                                        </div>
                                        <div className="col-md-6 mb-3 d-flex align-items-center justify-content-end">
                                            <button ref={btnSend} type="button" className="btn btn-sm btn-primary" disabled={isSubmitting} onClick={onSubmit}>{t('Solicitar')}
                                            </button>
                                        </div>
                                    </div>    
                                </div>
                            </React.Fragment>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default NewOrderModal;
