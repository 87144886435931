
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DPicker.css'; // Importe o arquivo CSS aqui


function ClienteCalendar({ availableDates, onDateSelect }) {
    //console.log(availableDates);
    const [selectedDates, setSelectedDates] = useState([]);

    const handleDateSelect = date => {
        setSelectedDates([date]); // Restringe a seleção do cliente a apenas uma data
        onDateSelect([date]); // Passa a data selecionada para o componente pai
    };

    const dayClassNames = date =>
        selectedDates.length > 0 && date.toLocaleDateString() === selectedDates[0].toLocaleDateString()
            ? 'selected-day'
            : '';

    return (
        <div>
            <p>Escolha:</p>
            <DatePicker
                selected={selectedDates.length > 0 ? selectedDates[0] : null}
                onChange={handleDateSelect}
                dateFormat="dd/MM/yyyy"
                isClearable
                inline
                dayClassName={dayClassNames} // Aplica o estilo para o dia selecionado pelo cliente
                includeDates={availableDates.map(date => new Date(date))}
            />
            {selectedDates.length > 0 && (
                <React.Fragment>
                    <p><b>Dia Selecionado:</b> {selectedDates[0].toLocaleDateString()}</p>
                </React.Fragment>
            )}
        </div>
    );
}

export default ClienteCalendar;