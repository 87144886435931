import axios from './BaseService';

const HYDRA_URL = `${process.env.REACT_APP_API_URL}/dash/`;

export async function getDashboard() {
    
    const response = await axios.get(HYDRA_URL + 'dashboard');
    return response.data;
}

export async function checkVerification() {
    
    const response = await axios.get(HYDRA_URL + 'verification');
    return response.data;
}