import React, { useState, useEffect } from "react";
import { loadMercadoPago } from "@mercadopago/sdk-js";
import { postCredit } from "../../services/PaymentService";
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import './CartaoCreditoVideo.css'; // Você pode manter este import, caso tenha estilos adicionais

const CartaoCreditoVideo = () => {
    const [cardData, setCardData] = useState({
        number: '',
        name: '',
        expiry: '',
        cvc: '',
        focused: ''
    });

    useEffect(() => {
        const initializeCardForm = async () => {
            await loadMercadoPago();
            const mp = new window.MercadoPago('TEST-cb7afe1d-e196-42a8-a9c3-3e5c39c11e5f');

            const cardForm = mp.cardForm({
                amount: '479.90',
                iframe: true,
                form: {
                    id: 'form-checkout',
                    cardNumber: {
                        id: 'form-checkout__cardNumber',
                        placeholder: 'Número do cartão',
                        style: {
                            height: 'auto',
                            fontSize: '16px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            transition: 'border-color 0.3s ease',
                        }
                    },
                    expirationDate: {
                        id: 'form-checkout__expirationDate',
                        placeholder: 'MM/YY',
                        style: {
                            height: 'auto',
                            fontSize: '16px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            transition: 'border-color 0.3s ease',
                        }
                    },
                    securityCode: {
                        id: 'form-checkout__securityCode',
                        placeholder: 'Código de segurança',
                        style: {
                            height: 'auto',
                            fontSize: '16px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            transition: 'border-color 0.3s ease',
                        }
                    },
                    cardholderName: {
                        id: 'form-checkout__cardholderName',
                        placeholder: 'Titular do cartão',
                    },
                    issuer: {
                        id: 'form-checkout__issuer',
                        placeholder: 'Banco emissor',
                    },
                    installments: {
                        id: 'form-checkout__installments',
                        placeholder: 'Parcelas',
                    },
                    identificationType: {
                        id: 'form-checkout__identificationType',
                        placeholder: 'Tipo de documento',
                    },
                    identificationNumber: {
                        id: 'form-checkout__identificationNumber',
                        placeholder: 'Número do documento',
                    },
                    cardholderEmail: {
                        id: 'form-checkout__cardholderEmail',
                        placeholder: 'E-mail',
                    },
                },
                callbacks: {
                    onFormMounted: (error) => {
                        if (error) return console.warn('Form Mounted handling error: ', error);
                        console.log('Form mounted');
                    },
                    onBinChange: (bin) => {
                        console.log(bin);
                        setCardData(prev => ({ ...prev, number: bin }));
                    },
                    onSubmit: (event) => {
                        event.preventDefault();

                        const {
                            paymentMethodId: payment_method_id,
                            issuerId: issuer_id,
                            cardholderEmail: email,
                            amount,
                            token,
                            installments,
                            identificationNumber,
                            identificationType,
                        } = cardForm.getCardFormData();

                        postCredit(
                            token,
                            issuer_id,
                            payment_method_id,
                            Number(amount),
                            Number(installments),
                            email,
                            identificationType,
                            identificationNumber
                        ).then(response => {
                            console.log("response")
                            console.log(response)
                        }).catch(error => {
                            console.log("error", error)
                        });
                    },
                    onFetching: (resource) => {
                        console.log('Fetching resource: ', resource);

                        const progressBar = document.querySelector('.progress-bar');
                        progressBar?.removeAttribute('value');

                        return () => {
                            progressBar?.setAttribute('value', '0');
                        };
                    },
                },
            });

            const cardNumberElement = document.getElementById('form-checkout__cardNumber');
            const expirationDateElement = document.getElementById('form-checkout__expirationDate');
            const securityCodeElement = document.getElementById('form-checkout__securityCode');
            const cardholderNameElement = document.getElementById('form-checkout__cardholderName');

            cardNumberElement?.addEventListener('input', (e) => setCardData(prev => ({ ...prev, number: e.target.value })));
            expirationDateElement?.addEventListener('input', (e) => setCardData(prev => ({ ...prev, expiry: e.target.value.replace('/', '') })));
            securityCodeElement?.addEventListener('input', (e) => setCardData(prev => ({ ...prev, cvc: e.target.value })));
            cardholderNameElement?.addEventListener('input', (e) => setCardData(prev => ({ ...prev, name: e.target.value })));

            cardNumberElement?.addEventListener('focus', () => setCardData(prev => ({ ...prev, focused: 'number' })));
            expirationDateElement?.addEventListener('focus', () => setCardData(prev => ({ ...prev, focused: 'expiry' })));
            securityCodeElement?.addEventListener('focus', () => setCardData(prev => ({ ...prev, focused: 'cvc' })));
            cardholderNameElement?.addEventListener('focus', () => setCardData(prev => ({ ...prev, focused: 'name' })));

            cardNumberElement?.addEventListener('blur', () => setCardData(prev => ({ ...prev, focused: '' })));
            expirationDateElement?.addEventListener('blur', () => setCardData(prev => ({ ...prev, focused: '' })));
            securityCodeElement?.addEventListener('blur', () => setCardData(prev => ({ ...prev, focused: '' })));
            cardholderNameElement?.addEventListener('blur', () => setCardData(prev => ({ ...prev, focused: '' })));
        };

        initializeCardForm();
    }, []);

    return (
    <>
        <div className="payment-container">
            <div className="card-form-container">
                <Cards
                    number={cardData.number}
                    name={cardData.name}
                    expiry={cardData.expiry}
                    cvc={cardData.cvc}
                    focused={cardData.focused}
                />
            </div>
            <form id="form-checkout" className="card-form-container">
                <div 
                    id="form-checkout__cardNumber" 
                    style={{
                        backgroundColor: 'white',
                        maxHeight: '40px', 
                        width: '100%', 
                        padding: '10px', 
                        margin: '5px 0', 
                        fontSize: '16px', 
                        border: '1px solid #ccc', 
                        borderRadius: '5px', 
                        transition: 'border-color 0.3s ease'
                    }}
                ></div>
                <div 
                    id="form-checkout__expirationDate" 
                    style={{ 
                        backgroundColor: 'white',
                        maxHeight: '40px', 
                        width: '100%', 
                        padding: '10px', 
                        margin: '5px 0', 
                        fontSize: '16px', 
                        border: '1px solid #ccc', 
                        borderRadius: '5px', 
                        transition: 'border-color 0.3s ease' 
                    }}
                ></div>
                <div 
                    id="form-checkout__securityCode" 
                    style={{ 
                        backgroundColor: 'white',
                        maxHeight: '40px', 
                        width: '100%', 
                        padding: '10px', 
                        margin: '5px 0', 
                        fontSize: '16px', 
                        border: '1px solid #ccc', 
                        borderRadius: '5px', 
                        transition: 'border-color 0.3s ease' 
                    }}
                ></div>
                <input 
                    type="text" 
                    id="form-checkout__cardholderName" 
                    placeholder="Titular do cartão" 
                    className="input-field"
                />
                <select id="form-checkout__issuer" className="select-field"></select>
                <select id="form-checkout__installments" className="select-field"></select>
                <select id="form-checkout__identificationType" className="select-field"></select>
                <input 
                    type="text" 
                    id="form-checkout__identificationNumber" 
                    placeholder="Número do documento" 
                    className="input-field"
                />
                <input 
                    type="email" 
                    id="form-checkout__cardholderEmail" 
                    placeholder="E-mail" 
                    className="input-field"
                />
                <button type="submit" id="form-checkout__submit" className="submit-button">Pagar</button>
                <progress value="0" className="progress-bar">Carregando...</progress>
                <img src="https://http2.mlstatic.com/frontend-assets/ui-navigation/5.18.1/mercadopago/logo__large.png" alt="Mercado Pago Logo" className="mercado-pago-logo" />
            </form>
        </div>
    </>
    );
}

export default CartaoCreditoVideo;
