
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DPicker.css'; // Importe o arquivo CSS aqui


function FornecedorCalendar({ onAvailabilityChange }) {
    const [selectedDates, setSelectedDates] = useState([]);

    const handleDateChange = date => {
        const index = selectedDates.findIndex(selectedDate =>
            selectedDate.getDate() === date.getDate() &&
            selectedDate.getMonth() === date.getMonth() &&
            selectedDate.getFullYear() === date.getFullYear()
        );

        let updatedDates;
        if (index === -1) {
            updatedDates = [...selectedDates, date];
        } else {
            updatedDates = selectedDates.filter((_, i) => i !== index);
        }

        setSelectedDates(updatedDates);
        // Passa a disponibilidade atualizada para o componente pai (ou para a API)
        onAvailabilityChange(updatedDates);
    };

    const dayClassNames = date =>
        selectedDates.some(selectedDate =>
            selectedDate.getDate() === date.getDate() &&
            selectedDate.getMonth() === date.getMonth() &&
            selectedDate.getFullYear() === date.getFullYear()
        )
        ? 'selected-day'
        : '';

    const today = new Date(); // Obtém a data atual

    return (
            <DatePicker
                selected={null}
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
                isClearable
                inline
                dayClassName={dayClassNames} // Aplica o estilo para dias selecionados pelo fornecedor
                minDate={today}
            />
    );
}

export default FornecedorCalendar;