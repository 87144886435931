import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
/*
props:
- symbol
- data
*/
function OrdersRow(props) {
    const { t } = useTranslation();

    const id = localStorage.getItem('id');

    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);
    
    const [negados, setNegados] = useState(0);

    useEffect(() => {
        const handleResize = () => {
          setIsLargeScreen(window.innerWidth >= 992);
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    useEffect(() => {
        if (props.data.services && props.data.services.length > 0) {
            const negados = props.data.services.filter(order => order.winner === false && order.active === false || order.denied === true);
            setNegados(negados.length);
        } else if (props.data.suppliers && props.data.suppliers.length > 0) {
            const negados = props.data.suppliers.filter(order => order.winner === false && order.active === false || order.negado === true);
            setNegados(negados.length);
        } else {
            setNegados(0);
        }
    }, [props.data]);

    function handleRowClick() {
        if (!props.data) {
            console.error("props.data is undefined or null");
            return;
        }

        const myLocation = {
            city: props.data.city,
            street: props.data.street,
            number: props.data.number
        };

        if (props.onMyLocation) {
            props.onMyLocation(myLocation);
        }

        if (props.myType !== '1') return props.onViewOrder([]);

        if (props.data.active === false) {
            if (props.data.services && props.data.services.length > 0) {
                const vencedores = props.data.services.filter(order => order.winner === true);
                const myDestination = {
                    city: vencedores[0].city,
                    street: vencedores[0].street,
                    number: vencedores[0].number
                };

                if (props.onMyDestination) {
                    props.onMyDestination(myDestination);
                }
            } else if (props.data.suppliers && props.data.suppliers.length > 0) {
                const vencedores = props.data.suppliers.filter(order => order.winner === true);
                const myDestination = {
                    city: vencedores[0].city,
                    street: vencedores[0].street,
                    number: vencedores[0].number
                };

                if (props.onMyDestination) {
                    props.onMyDestination(myDestination);
                }
            }
            props.onActive(props.data.active);
            props.onViewOrder([]);
            return;
        }

        if (props.data.suppliers && props.data.suppliers.length > 0) {
            props.onViewOrder(props.data.suppliers);
        } else if (props.data.services && props.data.services.length > 0) {
            props.onViewOrder(props.data.services);
        } else if (props.data.favoriteId !== 0) {
            console.log("PEGA DESTINO DO ESCOLHIDO");
        } else {
            props.onActive(false);
            props.onViewOrder([]);
            return;
        }
        props.onActive(props.data.active);
    }

    function formatarDataHora() {
        var data_hora = new Date(props.data.createdAt);
        var dia = data_hora.getDate();
        var mes = data_hora.getMonth() + 1;
        var ano = data_hora.getFullYear() % 100;
        var hora = data_hora.getHours();
        var minutos = data_hora.getMinutes();

        var periodo = "AM";
        if (hora >= 12) {
            periodo = "PM";
            hora -= 12;
        }
        if (hora === 0) {
            hora = 12;
        }

        var data_formatada;

        if (isLargeScreen) {
            data_formatada = dia + '/' + mes + '/' + ano + ', ' + hora + ':' + (minutos < 10 ? '0' : '') + minutos + ' ' + periodo;
        } else {
            data_formatada = dia + '/' + mes + '/' + ano;
        }

        return data_formatada;
    }

    function getStatus() {
        if (props.data.services && props.data.services.length > 0) {
            const service = props.data.services.find(item => parseFloat(id) === parseFloat(item.userId));
            if (service) {
                if (service.active === true && service.winner === false) {
                    return (<span className="badge bg-warning py-1">{t('ANÁLISE')}</span>);                    
                } else if (service.active === false && service.winner === false) {
                    return (<span className="badge bg-danger py-1">{t('NEGADO')}</span>);
                } else if (service.active === false && service.winner === true) {
                    return (<span className="badge bg-success py-1">{t('APROVADO')}</span>);
                }
            }
        } else if (props.data.suppliers && props.data.suppliers.length > 0) {
            const supplier = props.data.suppliers.find(item => parseFloat(id) === parseFloat(item.userId));
            if (supplier) {
                if (supplier.active === true && supplier.winner === false) {
                    return (<span className="badge bg-warning py-1">{t('ANÁLISE')}</span>);                    
                } else if (supplier.active === false && supplier.winner === false) {
                    return (<span className="badge bg-danger py-1">{t('NEGADO')}</span>);
                } else if (supplier.active === false && supplier.winner === true) {
                    return (<span className="badge bg-success py-1">{t('APROVADO')}</span>);
                }
            }
        } else if (props.data.favoriteId !== 0) {
            return (<span className="badge bg-purple py-1">{t('EXCLUSIVO')}</span>); 
        }
        return (<span className="badge bg-info py-1">{props.data.status ? t(props.data.status) : t('ABERTO')}</span>);
    }

    // Função para calcular a cor de fundo com base na quantidade de serviços ou fornecedores
    function calculateBackgroundColor(servicesCount, suppliersCount) {
        const totalCount = servicesCount + suppliersCount;
        if (totalCount === 0) {
            return 'rgba(255, 255, 255, 1)'; // Branco para sem respostas
        } else {
            const intensity = Math.min(totalCount * 20, 255); // Quanto mais serviços ou fornecedores, mais intenso o azul
            return `rgba(173, 216, 230, ${intensity / 255})`; // Azul claro com intensidade variável
        }
    }

    const servicesCount = props.data.services ? props.data.services.length : 0;
    const suppliersCount = props.data.suppliers ? props.data.suppliers.length : 0;
    const backgroundColor = calculateBackgroundColor(servicesCount, suppliersCount);

    return (
        <>
        { isLargeScreen 
            ? ( 
                <tr style={{ paddingLeft: "1rem", paddingRight: "1rem", backgroundColor }} onClick={handleRowClick}>
                    <td className="text-gray-900" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>#{props.data.id}</td>
                    <td className="text-gray-900" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{formatarDataHora()}</td>
                    <td className="text-gray-900" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                        {
                            props.myType === '1'
                                ? props.data.type === '3' ? `${t('Fornecedor')}/${t(props.data.categoriaF)}` : `${t('Serviço')}/${t(props.data.categoriaS)}`
                                : props.data.type === '3' ? `${t(props.data.categoriaF)}` : `${t(props.data.categoriaS)}`
                        }
                    </td>
                    <td className="text-gray-900" style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "center", verticalAlign: "middle" }}>
                        {props.myType === '1' && props.data.active === true 
                            ? props.data.favoriteId !== 0 
                                ? <span className="badge bg-purple py-1">{t('DIRETO')}</span>
                                : props.data.type === '3' 
                                    ? props.data.suppliers.length  - negados
                                    : props.data.services.length  - negados
                            : <a
                            id={"view" + props.data.id}
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#modalViewOrder"
                            onClick={props.onClick}
                          >
                            {getStatus()}
                          </a>    
                        }
                    </td>
                </tr>
            ): (
                <tr style={{ paddingLeft: "1rem", paddingRight: "1rem", backgroundColor }} onClick={handleRowClick}>
                    <td className="text-gray-900" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>#{props.data.id}</td>
                    <td className="text-gray-900" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{formatarDataHora()}</td>
                    <td className="text-gray-900" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                        {
                            props.myType === '1'
                                ? props.data.type === '3' ? `${t('Fornecedor')}/${props.data.categoriaF}` : `${t('Serviço')}/${props.data.categoriaS}`
                                : props.data.type === '3' ? `${props.data.categoriaF}` : `${props.data.categoriaS}`
                        }
                    </td>
                    <td className="text-gray-900" style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "center", verticalAlign: "middle" }}>
                        {props.myType === '1' && props.data.active === true
                            ? props.data.favoriteId !== 0 
                                ? <span className="badge bg-purple py-1">{t('DIRETO')}</span>
                                : props.data.type === '3' 
                                    ? props.data.suppliers.length  - negados
                                    : props.data.services.length  - negados
                            : <a
                              id={"view" + props.data.id}
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#modalViewOrder"
                              onClick={props.onClick}
                            >
                              {getStatus()}
                            </a>
                        }
                    </td>
                </tr>
            )
        }
        </>
    );
}

export default OrdersRow;
