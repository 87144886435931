import React, { useState, useEffect } from 'react';
import { getLinkYears } from "../../services/PaymentService";
import './TicketPix.css'; // Certifique-se de importar o arquivo CSS

export default function PlanYears(props) {
    const [yearsUrl, setYearsUrl] = useState("");
    

    useEffect(() => {
        getLinkYears()
            .then(response => {
            //console.log(response);
            setYearsUrl(response.data.plan);
        });
    }, []);

    return (
        <div className="container-pix">
            {yearsUrl && <iframe src={yearsUrl} className="iframe" />}
        </div>
    );
}
