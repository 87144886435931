import React, { useState, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { saveUser, getCode } from '../../services/AuthService';
import PrivacyModal from '../../components/Privacy/PrivacyModal';
import TermsModal from '../../components/Terms/TermsModal';
import { useTranslation } from 'react-i18next';
import './Signup.css'; // Importar o mesmo CSS da página de login

function SignUp() {
    const { t, i18n } = useTranslation();
    
    // Adicione esta linha para pegar o idioma atual
    const currentLanguage = i18n.language;
    const history = useHistory();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [code, setCode] = useState(['', '', '', '', '']);
    const [newCode, setCodeSend] = useState('');
    const [error, setError] = useState('');
    const [step, setStep] = useState(1);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [userType, setUserType] = useState('');
    const codeInputsRef = useRef([]);

    const DEFAULT_USER = {
        name: '',
        email: '',
        password: '',
        limitId: 1,
        phone: '',
        isActive: true
    }

    const [user, setUser] = useState(DEFAULT_USER);

    function onAgreeTermsChange(event) {
        setAgreeTerms(event.target.checked);
    }

    function onChangeInput(event) {
        const { id, value } = event.target;
        if (id === 'username') {
            const [name, ...rest] = value.split(' ');
            const sobrenamo = rest.join(' ');
            setUser(prevState => ({ ...prevState, name, sobrenamo }));
          }
        else if (id === 'email') setUser(prevState => ({ ...prevState, email: value }));
        else if (id === 'password') setUser(prevState => ({ ...prevState, password: value }));
        else if (id === 'confirmPassword') setConfirmPassword(value);
        else if (id === 'code') setCode(value);
    }

    function onUserTypeChange(event) {
        //console.log(event.target.value);
        setUserType(event.target.value);
        setUser(prevState => ({ ...prevState, type: event.target.value }));
    }

    function onCodeChange(index, value) {
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        if (value !== '' && index < code.length - 1) {
            codeInputsRef.current[index + 1].focus();
        }
    }

    function onSubmit(event) {
        event.preventDefault();
        if (step === 1) {
            if (!agreeTerms) {
                setError(t('Você deve concordar com os Termos de Uso e a Política de Privacidade.'));
                return;
            }
            if (!userType) {
                setError(t('Por favor, selecione um tipo de usuário.'));
                return;
            }
            if (user.password !== confirmPassword) {
                setError(t('As senhas não correspondem.'));
                return;
            }
            const getcode = {
                email: user.email,
                currentLanguage: currentLanguage
            }
            getCode(getcode)
                .then(result => {
                    //console.log(result);
                    setCodeSend(result);
                    setStep(2);
                })
                .catch(err => {
                    console.error(err);
                    setError(t('Código inválido!'));
                });
        } else if (step === 2) {
            const codeStr = code.join('');
            if (codeStr === newCode) {
                saveUser(user)
                    .then(result => {
                        history.push('/');
                    })
                    .catch(err => {
                        console.error(err.response ? err.response.data : err.message);
                        setError(err.response ? err.response.data : err.message);
                    })
            } else {
                setError(t('Código de verificação inválido!'));
            }
        }
    }

    return (
        <React.Fragment>
        <div className="container">
            <div className="login-box ">
                <h2>{step === 1 ? t('Registrar-se') : t('Confirme seu email com o código que acabamos de enviar.')}</h2>
                <form onSubmit={onSubmit}>
                    {step === 1 && (
                        <>
                            <div className="input-container">
                                <i className="fas fa-user"></i>
                                <input type="text" id="username" placeholder={t('Nome')} autoFocus required onChange={onChangeInput} />
                            </div>
                            <div className="input-container">
                                <i className="fas fa-envelope"></i>
                                <input type="email" id="email" placeholder={t('Digite seu email')} required onChange={onChangeInput} />
                            </div>
                            <div className="input-container">
                                <i className="fas fa-lock"></i>
                                <input type="password" id="password" placeholder={t('Digite sua senha')} required onChange={onChangeInput} />
                            </div>
                            <div className="input-container">
                                <i className="fas fa-lock"></i>
                                <input type="password" id="confirmPassword" placeholder={t('Confirme sua senha')} required onChange={onChangeInput} />
                            </div>
                            <div className="input-container user-type-container">
                                <div className="form-check">
                                    <input type="radio" id="normal" className="check" name="userType" value="1" checked={userType === '1'} onChange={onUserTypeChange} />
                                    <label htmlFor="normal"> {t('Cliente')}</label>
                                </div>
                                <div className="form-check">
                                    <input type="radio" id="serviceProvider" className="check" name="userType" value="2" checked={userType === '2'} onChange={onUserTypeChange} />
                                    <label htmlFor="serviceProvider"> {t('Prestador de serviços')}</label>
                                </div>
                                <div className="form-check">
                                    <input type="radio" id="productSupplier" className="check" name="userType" value="3" checked={userType === '3'} onChange={onUserTypeChange} />
                                    <label htmlFor="productSupplier"> {t('Fornecedor de produtos')}</label>
                                </div>
                            </div>
                            <div className="input-container">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="agreeTerms" checked={agreeTerms} onChange={onAgreeTermsChange} required />
                                    <label className="form-check-label text-term" htmlFor="agreeTerms">
                                        {t('Eu aceito o ')} <a href="#" className="text-decoration-underline" data-bs-toggle="modal" data-bs-target="#termsModal">{t('Termo de Uso')}</a>{t(' e a ')}<a href="#" className="text-decoration-underline" data-bs-toggle="modal" data-bs-target="#privacyModal">{t('Política de Privacidade')}</a>.
                                    </label>
                                </div>
                            </div>
                        </>
                    )}
                    {step === 2 && (
                        <div className="input-container">
                            <label htmlFor="code">{t('Código')}:</label>
                            <div className="d-flex justify-content-center align-items-center">
                                {code.map((digit, index) => (
                                    <input
                                        id="code"
                                        key={index}
                                        ref={(el) => codeInputsRef.current[index] = el}
                                        type="text"
                                        className="form-control mx-2 text-center rounded-circle border border-gold"
                                        style={{ width: '50px', height: '50px' }}
                                        maxLength="1"
                                        value={digit}
                                        onChange={(event) => onCodeChange(index, event.target.value)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    <button type="submit" className="blue-button">{step === 1 ? t('Registrar') : t('Enviar')}</button>
                    {error && <div className="alert alert-danger mt-2">{error}</div>}
                </form>
            </div>
        </div>
        <PrivacyModal />
        <TermsModal />
        </React.Fragment>
    );
}

export default SignUp;
