import React from 'react';
import './Modal.css';

function Modal({ title, message, onClose }) {
  return (
    <div className="custom-modal-container">
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>{title}</h2>
          <p>{message}</p>
          <button onClick={onClose}>Fechar</button>
        </div>
      </div>
    </div>
  );
}

export default Modal;