import React from 'react';
import PropTypes from 'prop-types';
import './Avatar.css';

const Avatar = ({ imageUrl, alt, size, frameStyle }) => {
    return (
        <div className={`avatar-container ${size} ${frameStyle}`}>
            <div className="avatar-circle">
                <img src={imageUrl} alt={alt} className="avatar-image" />
            </div>
        </div>
    );
};

Avatar.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['small', 'medium', 'large', 'big']),
    frameStyle: PropTypes.oneOf([
        'wood', 
        'blue', 
        'gold', 
        'double-shadow', 
        'double-shadow-blue', 
        'double-shadow-white', 
        'double-shadow-black', 
        'rainbow', 
        'default'
    ]),
};

Avatar.defaultProps = {
    size: 'medium',
    frameStyle: 'default',
};

export default Avatar;
