import React, { useState, useEffect } from 'react';
import { postCriarPix } from "../../services/PaymentService";
import './TicketPix.css'; // Certifique-se de importar o arquivo CSS

export default function TicketPix(props) {
    const [ticketUrl, setTicketUrl] = useState("");
    const [mockBody, setMockBody] = useState({
        transaction_amount: 479.90,
        description: "Plano anual",
        paymentMethodId: "pix",
        email: '',
        identificationType: '',
        number: ''
    });

    useEffect(() => {
        let updatedMockBody = { ...mockBody };

        if (props.email) {
            updatedMockBody.email = props.email;
        }

        if (props.identificationType) {
            updatedMockBody.identificationType = props.identificationType;
        }

        if (props.number) {
            updatedMockBody.number = props.number;
        }

        setMockBody(updatedMockBody);

    }, [props.email, props.identificationType, props.number]);

    useEffect(() => {
        const allFieldsFilled = mockBody.email && mockBody.identificationType && mockBody.number;

        if (allFieldsFilled && props.pix) {
            handlePost();
        }
    }, [mockBody, props.pix]);

    const handlePost = () => {
        console.log(mockBody);

        postCriarPix(mockBody).then(response => {
            console.log("response");
            setTicketUrl(response.data.point_of_interaction.transaction_data.ticket_url);
        });
    };

    return (
        <div className="container-pix">
            {ticketUrl && <iframe src={ticketUrl} className="iframe" />}
        </div>
    );
}
