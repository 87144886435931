import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import twemoji from 'twemoji';
import './LanguageSelector.css';

function LanguageSelector() {
  const { i18n } = useTranslation();

  // Recuperar o idioma salvo do localStorage ao montar o componente
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
    twemoji.parse(document.body);
  }, [i18n]);

  const changeLanguage = (event) => {
    const newLanguage = event.currentTarget.dataset.language;
    i18n.changeLanguage(newLanguage);
    localStorage.setItem('language', newLanguage); // Salvar o idioma no localStorage
  };

  return (
    <div className="language-selector">
      <div role="img" aria-label="Español" data-language="es" onClick={changeLanguage} className="language-flag">🇪🇸</div>
      <div role="img" aria-label="Português" data-language="pt" onClick={changeLanguage} className="language-flag">🇧🇷</div>
      <div role="img" aria-label="Alemão" data-language="de" onClick={changeLanguage} className="language-flag">🇩🇪</div>
      <div role="img" aria-label="English" data-language="en" onClick={changeLanguage} className="language-flag">🇺🇸</div>
      <div role="img" aria-label="Italiano" data-language="it" onClick={changeLanguage} className="language-flag">🇮🇹</div>
      <div role="img" aria-label="Russo" data-language="ru" onClick={changeLanguage} className="language-flag">🇷🇺</div>
    </div>
  );
}

export default LanguageSelector;