import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { doLogout } from '../../services/AuthService';
import Avatar from '../Avatar/Avatar';
import { getName, getAvatar, getFavorites } from '../../services/SettingsService';
import './SideBar.css'

import SideBarItem from './SideBarItem';

function SideBar() {
    const history = useHistory();

    const [name, setName] = useState('User');
    const [avatarBase64, setAvatarBase64] = useState('');
    const [isFavoritesVisible, setIsFavoritesVisible] = useState(false);

    const { t } = useTranslation();

    function cleanAndRedirect() {
        localStorage.removeItem('token');
        history.push('/');
    }

    function onLogoutClick(event) {
        doLogout()
            .then(response => cleanAndRedirect())
            .catch(error => {
                console.error(error);
                cleanAndRedirect();
            })
    }

    useEffect(() => {
        getName()
            .then((result) => setName(result))
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
            })
        getAvatar()
            .then(avatarData => {
                setAvatarBase64(avatarData);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
            })
        getFavorites()
        .then(favoriteData => {
            if (favoriteData) {
                //console.log("DEU CERTO O FAVORITOS");
                setIsFavoritesVisible(true);
            }
        })
        .catch(err => {
            console.error(err.response ? err.response.data : err.message);
        })
            
    }, []);

    //const userId = localStorage.getItem('id');

    return (
        <nav id="sidebarMenu" className="sidebar d-lg-block bg-gray-802 text-white collapse" datasimplebar="true">
            <div className="sidebar-inner px-4 pt-3">
                <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                    <div className="collapse-close d-md-none">
                        <a href="#sidebarMenu" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu"
                            aria-expanded="true" aria-label="Toggle navigation">
                            <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"></path>
                            </svg>
                        </a>
                    </div>
                </div>
                <ul className="nav flex-column pt-3 pt-md-0">
                    <li className="nav-item" style={{ position: 'relative', marginBottom: '10px' }}>
                        <Link to="/settings" className="nav-link d-flex align-items-center">
                            <span className="sidebar-icon mb-n1">
                            <Avatar imageUrl={`data:image/png;base64,${avatarBase64}`} alt="Avatar" size="small" frameStyle="double-shadow-white" />
                            </span>
                            <span className="mt-1 ms-1 mb-2 sidebar-text">{name.length > 8 ? `${t('Olá')} ${name}!` : `${t('Bem vindo')} ${name}!`}</span>
                        </Link>
                    </li>
                    <SideBarItem to="/dashboard" text={t('Painel')}>
                        <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                            <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                        </svg>
                    </SideBarItem>
                    <li className="nav-item">
                        <span className="nav-link collapsed d-flex justify-content-between align-items-center" data-bs-toggle="collapse" data-bs-target="#submenu-orders">
                            <span>
                                <span className="sidebar-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs me-2">
                                        <path fillRule="evenodd" d="M9.315 7.584C12.195 3.883 16.695 1.5 21.75 1.5a.75.75 0 0 1 .75.75c0 5.056-2.383 9.555-6.084 12.436A6.75 6.75 0 0 1 9.75 22.5a.75.75 0 0 1-.75-.75v-4.131A15.838 15.838 0 0 1 6.382 15H2.25a.75.75 0 0 1-.75-.75 6.75 6.75 0 0 1 7.815-6.666ZM15 6.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z" clipRule="evenodd" />
                                        <path d="M5.26 17.242a.75.75 0 1 0-.897-1.203 5.243 5.243 0 0 0-2.05 5.022.75.75 0 0 0 .625.627 5.243 5.243 0 0 0 5.022-2.051.75.75 0 1 0-1.202-.897 3.744 3.744 0 0 1-3.008 1.51c0-1.23.592-2.323 1.51-3.008Z" />
                                    </svg>
                                </span>
                                <span className="sidebar-text">{t('Solicitações')}</span>
                            </span>
                            <span className="link-arrow">
                                <svg className="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                                </svg>
                            </span>
                        </span>
                        <div className="multi-level collapse" role="list" id="submenu-orders">
                            <ul className="flex-column nav">
                                <SideBarItem to="/providers" text={t('Serviços')} >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs me-2">
                                        <path fillRule="evenodd" d="M12 6.75a5.25 5.25 0 0 1 6.775-5.025.75.75 0 0 1 .313 1.248l-3.32 3.319c.063.475.276.934.641 1.299.365.365.824.578 1.3.64l3.318-3.319a.75.75 0 0 1 1.248.313 5.25 5.25 0 0 1-5.472 6.756c-1.018-.086-1.87.1-2.309.634L7.344 21.3A3.298 3.298 0 1 1 2.7 16.657l8.684-7.151c.533-.44.72-1.291.634-2.309A5.342 5.342 0 0 1 12 6.75ZM4.117 19.125a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75h-.008a.75.75 0 0 1-.75-.75v-.008Z" clipRule="evenodd" />
                                    </svg>
                                </SideBarItem>
                                <SideBarItem to="/suppliers" text={t('Fornecedores')} >
                                    <svg xmlns="http://www.w3.org/2000/svg" style={{ transform: 'scaleY(-1)' }} viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs me-2">
                                        <path d="M12 .75a8.25 8.25 0 0 0-4.135 15.39c.686.398 1.115 1.008 1.134 1.623a.75.75 0 0 0 .577.706c.352.083.71.148 1.074.195.323.041.6-.218.6-.544v-4.661a6.714 6.714 0 0 1-.937-.171.75.75 0 1 1 .374-1.453 5.261 5.261 0 0 0 2.626 0 .75.75 0 1 1 .374 1.452 6.712 6.712 0 0 1-.937.172v4.66c0 .327.277.586.6.545.364-.047.722-.112 1.074-.195a.75.75 0 0 0 .577-.706c.02-.615.448-1.225 1.134-1.623A8.25 8.25 0 0 0 12 .75Z" />
                                        <path fillRule="evenodd" d="M9.013 19.9a.75.75 0 0 1 .877-.597 11.319 11.319 0 0 0 4.22 0 .75.75 0 1 1 .28 1.473 12.819 12.819 0 0 1-4.78 0 .75.75 0 0 1-.597-.876ZM9.754 22.344a.75.75 0 0 1 .824-.668 13.682 13.682 0 0 0 2.844 0 .75.75 0 1 1 .156 1.492 15.156 15.156 0 0 1-3.156 0 .75.75 0 0 1-.668-.824Z" clipRule="evenodd" />
                                    </svg>

                                </SideBarItem>
                                <SideBarItem to="/favorites" text={t('Favoritos')} >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs me-2">
                                      <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                    </svg>

                                </SideBarItem>
                            </ul>
                        </div>
                    </li>
                    <SideBarItem to="/chat" text={t('Chat')} data-bs-toggle="collapse"
                            data-bs-target="#sidebarNew" aria-controls="sidebarNew" aria-expanded="false" aria-label="Toggle navigation">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth="1.3" // Ajusta a espessura do traço
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="icon icon-xs me-2"
                            >
                            <path
                                fillRule="evenodd"
                                d="M11.097 1.515a.75.75 0 0 1 .589.882L10.666 7.5h4.47l1.079-5.397a.75.75 0 1 1 1.47.294L16.665 7.5h3.585a.75.75 0 0 1 0 1.5h-3.885l-1.2 6h3.585a.75.75 0 0 1 0 1.5h-3.885l-1.08 5.397a.75.75 0 1 1-1.47-.294l1.02-5.103h-4.47l-1.08 5.397a.75.75 0 1 1-1.47-.294l1.02-5.103H3.75a.75.75 0 0 1 0-1.5h3.885l1.2-6H5.25a.75.75 0 0 1 0-1.5h3.885l1.08-5.397a.75.75 0 0 1 .882-.588ZM10.365 9l-1.2 6h4.47l1.2-6h-4.47Z"
                                clipRule="evenodd"
                            />
                        </svg>

                    </SideBarItem>
                        
                    <li role="separator" className="dropdown-divider mt-4 mb-3 border-gray-700"></li>
                    <SideBarItem to="/" text={t('Sair')} onClick={onLogoutClick}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="1.5" // Ajusta a espessura do traço
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="icon icon-xs me-2"
                                >
                                <path
                                    d="M12 2.25a.75.75 0 0 1 .75.75v9a.75.75 0 0 1-1.5 0V3a.75.75 0 0 1 .75-.75ZM6.166 5.106a.75.75 0 0 1 0 1.06 8.25 8.25 0 1 0 11.668 0 .75.75 0 1 1 1.06-1.06c3.808 3.807 3.808 9.98 0 13.788-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788a.75.75 0 0 1 1.06 0Z"
                                />
                            </svg>
                    </SideBarItem>
                    <li role="separator" className="dropdown-divider mt-4 mb-3 border-gray-700"></li>
                    <li className="nav-item" style={{ position: 'relative', marginBottom: '10px', marginTop: '30px' }}>
                        <Link to="/dashboard" className="nav-link d-flex align-items-center">
                            <span className="sidebar-icon ms-4">
                            <svg
                                className="icon icon-xs me-2"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="20 46.333 160 107"
                                xmlns="http://www.w3.org/2000/svg"
                                data-type="color"
                                role="img"
                                aria-label="Homepage"
                                style={{ transform: 'scaleY(-1)' }} // Inverte o ícone verticalmente
                            >
                                <g>
                                    <path
                                        d="M73.333 46.333h53.334l-53.334 107H20l53.333-107z"
                                        fill="#D6DCDC"
                                        data-color="1"
                                    ></path>
                                    <path
                                        d="M73.333 46.333zM100 99.833l26.667 53.5H180l-26.666-53.5H100z"
                                        fill="#D6DCDC"
                                        clipRule="evenodd"
                                        fillRule="evenodd"
                                        data-color="1"
                                    ></path>
                                </g>
                            </svg>
                            </span>
                            <span className="sidebar-text">Yepwedo</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default SideBar;
