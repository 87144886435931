import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function SelectHour(props) {
    const { t } = useTranslation();
    const [hour, setHour] = useState('');

    useEffect(() => {
        setHour(props.value);
    }, [props.value]);

    function handleChange(event) {
        //const selectedServico = event.target.value;
        setHour(event.target.value);
        props.onChange(event);
    }

    return (
        <select id="selectServico" className="form-select me-4" value={hour} onChange={handleChange}>
            <option value="">Selecione...</option>
            <option value="Manhã (8hs-12hs)">{t('Manhã (8hs-12hs)')}</option>
            <option value="Tarde (13hs-18hs)">{t('Tarde (13hs-18hs)')}</option>
            <option value="Noite (18hs-23hs)">{t('Noite (18hs-23hs)')}</option>
            <option value="Horário Comercial (8hs-18hs)">{t('Horário Comercial (8hs-18hs)')}</option>
                        {/* Adicione mais opções conforme necessário */}
        </select>
    );
}

export default SelectHour;
