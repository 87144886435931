import React, { useState, useEffect } from 'react';
import SmartBadge from '../SmartBadge/SmartBadge';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function SelectCities(props) {
    const { t } = useTranslation();
    const [selectedCities, setSelectedCities] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
            axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${props.state}/municipios`)
                .then(response => {
                    const data = response.data;
                    //console.log(data);
                    setCategories(data);

                })
                .catch(error => {
                    console.error('Erro ao consultar CEP:', error);
                });
    }, [props.state]);

    useEffect(() => {
        if (Array.isArray(props.value)) {
            setSelectedCities(props.value);
        } else {
            setSelectedCities([]);
        }
    }, [props.value]);

    function handleChange(event) {
        const value = event.target.value;
        if (value && !selectedCities.includes(value)) {
            const newServices = [...selectedCities, value];
            setSelectedCities(newServices);
            props.onChange(newServices); // Atualizar o estado pai aqui
        }
    }

    function handleRemove(service) {
        const newServices = selectedCities.filter(item => item !== service);
        setSelectedCities(newServices);
        props.onChange(newServices); // Atualizar o estado pai aqui
    }

    return (
        <div className="select-category-s">
            <select id="selectCities" className="form-select me-4" value="" onChange={handleChange}>
                <option value="">{t('Selecione')}...</option>
                {categories.map((cidade, index) => (
                    <option key={index} value={cidade.nome}>
                    {cidade.nome}
                    </option>
                ))}
            </select>
            <div className="badges mt-3 d-flex flex-wrap">
                {selectedCities.map(service => (
                    <SmartBadge key={service} id={service} text={service} onClick={() => handleRemove(service)} />
                ))}
            </div>
        </div>
    );
}

export default SelectCities;