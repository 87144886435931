import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAvatar, getContacts, getNickname, getCity, searchUserInDatabase } from '../../services/ChatService';
import { getFavorites } from '../../services/FavoriteService';
import Avatar from '../../components/Avatar/Avatar';
import { useTranslation } from 'react-i18next';
import './NewSideBar.css';

function NewSideBar({ onClose, onSelectUser, repliedUser, messageNotifications, sendMessage }) {
    const { t } = useTranslation();
    const [favorites, setFavorites] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [search, setSearch] = useState("");
    const [city, setCity] = useState('');
    const [avatar, setAvatar] = useState('');
    const [searchResult, setSearchResult] = useState(null);
    const localUserId = localStorage.getItem('id');
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);
    const [favoritesExpanded, setFavoritesExpanded] = useState(false);
    const [contactsExpanded, setContactsExpanded] = useState(false);
    const [usersDelivered, setUsersDelivered] = useState([]);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 992);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function getClassName(itemName) {
        return window.location.pathname === itemName ? 'nav-item active' : 'nav-item';
    }

    useEffect(() => {
        if (repliedUser) {
            setFavorites(prevFavorites => {
                const existingFavorite = prevFavorites.find(fav => fav.id === repliedUser.id);
                if (existingFavorite) {
                    return prevFavorites.map(favorite => 
                        favorite.id === repliedUser.id 
                            ? { ...favorite, ...repliedUser }
                            : favorite
                    );
                } else {
                    return [...prevFavorites, repliedUser];
                }
            });
        }
    }, [repliedUser]);

    useEffect(() => {
        getFavorites(1)
            .then(result => {
                const favoritePromises = result.rows.map(favorite => {
                    const { supplierId, providerId, type } = favorite;
                    const id = type === '3' ? supplierId : providerId;

                    return Promise.all([getAvatar(id), getNickname(id)])
                        .then(([avatar, nickName]) => ({
                            id,
                            avatar,
                            nickName
                        }));
                });

                return Promise.all(favoritePromises);
            })
            .then(favorites => {
                setFavorites(favorites);
                if (repliedUser) {
                    setFavorites(prevFavorites => {
                        const existingFavorite = prevFavorites.find(fav => fav.id === repliedUser.id);
                        if (existingFavorite) {
                            return prevFavorites.map(favorite => 
                                favorite.id === repliedUser.id 
                                    ? { ...favorite, ...repliedUser }
                                    : favorite
                            );
                        } else {
                            return [...prevFavorites, repliedUser];
                        }
                    });
                }
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
            });
    }, [repliedUser]);

    useEffect(() => {
        getContacts(1)
            .then(result => {
                const contactsPromises = result.map(id => {
                    return Promise.all([getAvatar(id), getNickname(id)])
                        .then(([avatar, nickName]) => ({
                            id,
                            avatar,
                            nickName
                        }));
                });

                return Promise.all(contactsPromises);
            })
            .then(contacts => {
                setContacts(contacts);
                if (repliedUser) {
                    setContacts(prevContacts => {
                        const existingContacts = prevContacts.find(con => con.id === repliedUser.id);
                        if (existingContacts) {
                            return prevContacts.map(contacts => 
                                contacts.id === repliedUser.id 
                                    ? { ...contacts, ...repliedUser }
                                    : contacts
                            );
                        } else {
                            return [...prevContacts, repliedUser];
                        }
                    });
                }
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
            });
    }, [repliedUser]);

    useEffect(() => {
        async function fetchData() {
            try {
                const [userCity, userAvatar] = await Promise.all([getCity(localUserId), getAvatar(1)]);
                setCity(userCity);
                setAvatar(userAvatar);
            } catch (err) {
                console.error(err.response ? err.response.data : err.message);
            }
        }
        fetchData();
    }, [localUserId]);

    useEffect(() => {
        async function fetchData(userId, unreadCount) {
          try {
            const [avatar, nickName] = await Promise.all([getAvatar(userId), getNickname(userId)]);
            setUsersDelivered(prevUsersDelivered => {
              // Verifica se o usuário já está na lista
              const userExists = prevUsersDelivered.some(user => user.id === userId);
              if (userExists) {
                // Atualiza o usuário existente
                return prevUsersDelivered.map(user =>
                  user.id === userId ? { ...user, unreadCount } : user
                );
              }
              // Adiciona o novo usuário
              return [...prevUsersDelivered, { id: userId, avatar, nickName, unreadCount }];
            });
          } catch (err) {
            console.error(err.response ? err.response.data : err.message);
          }
        }
    
        if (messageNotifications && Object.keys(messageNotifications).length > 0) {
          Object.entries(messageNotifications).forEach(([userId, unreadCount]) => {
            //console.log('COUNT', unreadCount);
            if (parseFloat(unreadCount) === 0) return;
            fetchData(userId, unreadCount);
          });
        }
      }, [messageNotifications]);

    const handleSearchChange = async (e) => {
        setSearch(e.target.value);

        // Aqui você pode fazer a busca no banco de dados pelo nickname, nome ou email
        try {
            const result = await searchUserInDatabase(e.target.value);
            //console.log(result);
            setSearchResult(result);
        } catch (err) {
            console.error(err.response ? err.response.data : err.message);
        }

        const filteredFavorites = favorites.filter(user => user.nickName && user.nickName.toLowerCase().includes(e.target.value.toLowerCase()));
        const filteredContacts = contacts.filter(user => user.nickName && user.nickName.toLowerCase().includes(e.target.value.toLowerCase()));

        setFavoritesExpanded(filteredFavorites.length > 0);
        setContactsExpanded(filteredContacts.length > 0);
    };

    /*
    const searchUserInDatabase = async (query) => {
        // Substitua isso com a chamada para a sua API que busca o usuário no banco de dados
        // Aqui está um exemplo fictício
        const response = await fetch(`/api/searchUser?query=${query}`);
        if (!response.ok) throw new Error('Erro ao buscar usuário');
        return response.json();
    };*/

    const handleSelectUser = async (user) => {
        onSelectUser(user);
    };

    const filteredFavorites = favorites.filter(user => user.nickName && user.nickName.toLowerCase().includes(search.toLowerCase()));
    const filteredContacts = contacts.filter(user => user.nickName && user.nickName.toLowerCase().includes(search.toLowerCase()));

    return (
        <>
        { isLargeScreen 
            ? (
                <nav id="sidebarNew" className="sidebar d-lg-block bg-gray-802 text-white collapse" datasimplebar="true">
                    <div className="sidebar-inner px-4 pt-3">
                        <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                            <div className="collapse-close d-md-none">
                                <a href="#sidebarNew" data-bs-toggle="collapse" data-bs-target="#sidebarNew" aria-controls="sidebarNew"
                                    aria-expanded="true" aria-label="Toggle navigation">
                                    <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                            <button id="back" className="btn btn-sm btn-white btn-xs animate-up-2 me-2" onClick={() => onClose()}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs me-2">
                                    <path fillRule="evenodd" d="M9.53 2.47a.75.75 0 0 1 0 1.06L4.81 8.25H15a6.75 6.75 0 0 1 0 13.5h-3a.75.75 0 0 1 0-1.5h3a5.25 5.25 0 1 0 0-10.5H4.81l4.72 4.72a.75.75 0 1 1-1.06 1.06l-6-6a.75.75 0 0 1 0-1.06l6-6a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
                                </svg>
                            </button>
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder={t('Buscar contatos...')} 
                                value={search} 
                                onChange={handleSearchChange} 
                            />
                        </div>
                        <ul className="nav flex-column pt-3 pt-md-0">
                            {searchResult && searchResult.length > 0 
                                ? searchResult.map((user) => (    
                                        <li className="nav-item">
                                            <div className={`multi-level collapse show`} role="list" id="submenu-community">
                                                <ul className="flex-column nav">
                                                    <li className="nav-item" key={user.id} onClick={() => handleSelectUser({
                                                        id: user.id,
                                                        nickName: user.nickname ? user.nickname : user.name,
                                                        city: user.city,
                                                        avatar: user.avatar
                                                    })}>
                                                        <Link to="#" className="nav-link d-flex align-items-center">
                                                            <span className="sidebar-icon ms-n5 me-5 mb-n1">
                                                                <Avatar imageUrl={`data:image/png;base64,${user.avatar}`} alt="Avatar" size="small" frameStyle="double-shadow-blue" />
                                                            </span>
                                                            <span className="sidebar-text ms-n4 mb-2">
                                                                {user.nickname || user.name}
                                                                {messageNotifications[user.id] > 0 && (
                                                                    <span className="notification-badge">{messageNotifications[user.id]}</span>
                                                                )}
                                                            </span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    ))
                                : ( <><li className="nav-item" onClick={() => handleSelectUser({ id: city, nickName: `Chat ${city}`, city: city, avatar: avatar })}>
                                        <a href="#" className="nav-link d-flex align-items-center">
                                            <span className="sidebar-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs me-2">
                                                    <path fillRule="evenodd" d="M12 2a4 4 0 11-3.646 2.417A5.5 5.5 0 1011 15.5h.256A4 4 0 1112 2zM4.5 15.5a4.5 4.5 0 109 0 4.5 4.5 0 00-9 0zm14-4a3 3 0 110 6 3 3 0 010-6zm-3-3a3 3 0 110 6 3 3 0 010-6z" clipRule="evenodd" />
                                                </svg>
                                            </span>
                                            <span className="sidebar-text">{t('Comunidade')}</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <span className="nav-link collapsed d-flex justify-content-between align-items-center" data-bs-toggle="collapse" data-bs-target="#submenu-favorites">
                                            <span>
                                                <span className="sidebar-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs me-2">
                                                    <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                                </svg>
                                                </span>
                                                <span className="sidebar-text">{t('Meus Favoritos')}</span>
                                            </span>
                                            <span className="link-arrow">
                                                <svg className="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                            </span>
                                        </span>
                                        <div className={`multi-level collapse ${favoritesExpanded ? 'show' : ''}`} role="list" id="submenu-favorites">
                                            <ul className="flex-column nav">
                                                {filteredFavorites.map((user) => (                       
                                                    <li className={getClassName(user.nickName)} key={user.id} onClick={() => handleSelectUser(user)}>
                                                        <Link to="#" className="nav-link d-flex align-items-center">
                                                            <span className="sidebar-icon ms-n5 me-5 mb-n1">
                                                                <Avatar imageUrl={`data:image/png;base64,${user.avatar}`} alt="Avatar" size="small" frameStyle="double-shadow-blue" />
                                                            </span>
                                                            <span className="sidebar-text ms-n4 mb-2">
                                                                {user.nickName || 'Sem Nome'}
                                                                {messageNotifications[user.id] > 0 && (
                                                                    <span className="notification-badge">{messageNotifications[user.id]}</span>
                                                                )}
                                                            </span>
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="nav-item">
                                        <span className="nav-link collapsed d-flex justify-content-between align-items-center" data-bs-toggle="collapse" data-bs-target="#submenu-contacts">
                                            <span>
                                                <span className="sidebar-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs me-2">
                                                        <path d="M4.5 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM14.25 8.625a3.375 3.375 0 1 1 6.75 0 3.375 3.375 0 0 1-6.75 0ZM1.5 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM17.25 19.128l-.001.144a2.25 2.25 0 0 1-.233.96 10.088 10.088 0 0 0 5.06-1.01.75.75 0 0 0 .42-.643 4.875 4.875 0 0 0-6.957-4.611 8.586 8.586 0 0 1 1.71 5.157v.003Z" />
                                                    </svg>
                                                </span>
                                                <span className="sidebar-text">{t('Meus Contatos')}</span>
                                            </span>
                                            <span className="link-arrow">
                                                <svg className="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                            </span>
                                        </span>
                                        <div className={`multi-level collapse ${contactsExpanded ? 'show' : ''}`} role="list" id="submenu-contacts">
                                            <ul className="flex-column nav">
                                                {filteredContacts.map((user) => (                       
                                                    <li className={getClassName(user.nickName)} key={user.id} onClick={() => handleSelectUser(user)}>
                                                        <Link to="#" className="nav-link d-flex align-items-center">
                                                            <span className="sidebar-icon ms-n5 me-5 mb-n1">
                                                                <Avatar imageUrl={`data:image/png;base64,${user.avatar}`} alt="Avatar" size="small" frameStyle="double-shadow-blue"/>
                                                            </span>
                                                            <span className="sidebar-text ms-n4 mb-2">
                                                                {user.nickName || 'Sem Nome'}
                                                                {messageNotifications[user.id] > 0 && (
                                                                    <span className="notification-badge">{messageNotifications[user.id]}</span>
                                                                )}
                                                            </span>
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </li>
                                </> )}
                                {usersDelivered && usersDelivered.length > 0 && (<>
                                    <li className="nav-item">
                                        <span className="nav-link collapsed d-flex justify-content-between align-items-center" data-bs-toggle="collapse" data-bs-target="#submenu-noreads">
                                            <span>
                                                <span className="sidebar-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs me-2">
                                                  <path fillRule="evenodd" d="M4.848 2.771A49.144 49.144 0 0 1 12 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 0 1-3.476.383.39.39 0 0 0-.297.17l-2.755 4.133a.75.75 0 0 1-1.248 0l-2.755-4.133a.39.39 0 0 0-.297-.17 48.9 48.9 0 0 1-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97Z" clipRule="evenodd" />
                                                </svg>
                                                </span>
                                                <span className="sidebar-text">{t('Recentes')}</span>
                                            </span>
                                            <span className="link-arrow">
                                                <svg className="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                            </span>
                                        </span>
                                       
                                        {usersDelivered.map((user) => (   
                                            <div className={`multi-level collapse show`} role="list" id="submenu-noreads">
                                                <ul className="flex-column nav">
                                                    <li className="nav-item" key={user.id} onClick={() => handleSelectUser({
                                                        id: user.id,
                                                        nickName: user.nickName,
                                                        avatar: user.avatar
                                                        })}>
                                                        <Link to="#" className="nav-link d-flex align-items-center">
                                                            <span className="sidebar-icon ms-n5 me-5 mb-n1">
                                                                <Avatar imageUrl={`data:image/png;base64,${user.avatar}`} alt="Avatar" size="small" frameStyle="double-shadow-blue"/>
                                                            </span>
                                                            <span className="sidebar-text ms-n4 mb-2">
                                                                {user.nickName}
                                                                {messageNotifications[user.id] > 0 && (
                                                                    <span className="notification-badge">{messageNotifications[user.id]}</span>
                                                                )}
                                                            </span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        ))}
                                    </li>    
                                </>)}
                        </ul>
                    </div>
                </nav>
            ) : (<nav id="sidebarNew" className="sidebar d-lg-block bg-gray-802 text-white collapse" datasimplebar="true">
                    <div className="sidebar-inner px-4 pt-3">
                        <div className="d-flex align-items-center mb-3">
                            <button id="back" className="btn btn-sm btn-white btn-xs animate-up-2 me-2" data-bs-toggle="collapse" data-bs-target="#sidebarNew" aria-controls="sidebarNew"
                                            aria-expanded="true" aria-label="Toggle navigation">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs me-2">
                                    <path fillRule="evenodd" d="M9.53 2.47a.75.75 0 0 1 0 1.06L4.81 8.25H15a6.75 6.75 0 0 1 0 13.5h-3a.75.75 0 0 1 0-1.5h3a5.25 5.25 0 1 0 0-10.5H4.81l4.72 4.72a.75.75 0 1 1-1.06 1.06l-6-6a.75.75 0 0 1 0-1.06l6-6a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
                                </svg>
                            </button>
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder={t('Buscar contatos...')} 
                                value={search} 
                                onChange={handleSearchChange} 
                            />
                        </div>
                        <ul className="nav flex-column pt-3 pt-md-0">
                            {searchResult && searchResult.length > 0 
                                ? searchResult.map((user) => (    
                                    <li className="nav-item">
                                        <div className={`multi-level collapse show`} role="list" id="submenu-community">
                                            <ul className="flex-column nav">
                                                <li className="nav-item" key={user.id} onClick={() => handleSelectUser({
                                                    id: user.id,
                                                    nickName: user.nickname ? user.nickname : user.name,
                                                    city: user.city,
                                                    avatar: user.avatar
                                                })} data-bs-toggle="collapse" data-bs-target="#sidebarNew" aria-controls="sidebarNew"
                                                aria-expanded="true" aria-label="Toggle navigation">
                                                    <Link to="#" className="nav-link d-flex align-items-center">
                                                        <span className="sidebar-icon ms-n5 me-5 mb-n1">
                                                            <Avatar imageUrl={`data:image/png;base64,${user.avatar}`} alt="Avatar" size="small" frameStyle="double-shadow-blue"/>
                                                        </span>
                                                        <span className="sidebar-text ms-n4 mb-2">
                                                            {user.nickname || user.name}
                                                            {messageNotifications[user.id] > 0 && (
                                                                <span className="notification-badge">{messageNotifications[user.id]}</span>
                                                            )}
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    ))
                                :   (<> <li className="nav-item" onClick={() => handleSelectUser({ id: city, nickName: `Chat ${city}`, city: city, avatar: avatar })} data-bs-toggle="collapse" data-bs-target="#sidebarNew" aria-controls="sidebarNew"
                                            aria-expanded="true" aria-label="Toggle navigation">
                                            <a href="#" className="nav-link d-flex align-items-center">
                                                <span className="sidebar-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs me-2">
                                                        <path fillRule="evenodd" d="M12 2a4 4 0 11-3.646 2.417A5.5 5.5 0 1011 15.5h.256A4 4 0 1112 2zM4.5 15.5a4.5 4.5 0 109 0 4.5 4.5 0 00-9 0zm14-4a3 3 0 110 6 3 3 0 010-6zm-3-3a3 3 0 110 6 3 3 0 010-6z" clipRule="evenodd" />
                                                    </svg>
                                                </span>
                                                <span className="sidebar-text">{t('Comunidade')}</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <span className="nav-link collapsed d-flex justify-content-between align-items-center" data-bs-toggle="collapse" data-bs-target="#submenu-favorites">
                                                <span>
                                                    <span className="sidebar-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs me-2">
                                                        <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                                    </svg>
                                                    </span>
                                                    <span className="sidebar-text">{t('Meus Favoritos')}</span>
                                                </span>
                                                <span className="link-arrow">
                                                    <svg className="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                                                    </svg>
                                                </span>
                                            </span>
                                            <div className={`multi-level collapse ${favoritesExpanded ? 'show' : ''}`} role="list" id="submenu-favorites">
                                                <ul className="flex-column nav">
                                                    {filteredFavorites.map((user) => (                       
                                                        <li className={getClassName(user.nickName)} key={user.id} onClick={() => handleSelectUser(user)} data-bs-toggle="collapse" data-bs-target="#sidebarNew" aria-controls="sidebarNew"
                                                        aria-expanded="true" aria-label="Toggle navigation">
                                                            <Link to="#" className="nav-link d-flex align-items-center">
                                                                <span className="sidebar-icon">
                                                                    <Avatar imageUrl={`data:image/png;base64,${user.avatar}`} alt="Avatar" size="small" frameStyle="double-shadow-blue"/>
                                                                </span>
                                                                <span className="sidebar-text">
                                                                    {user.nickName || 'Sem Nome'}
                                                                    {messageNotifications[user.id] > 0 && (
                                                                        <span className="notification-badge">{messageNotifications[user.id]}</span>
                                                                    )}
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <span className="nav-link collapsed d-flex justify-content-between align-items-center" data-bs-toggle="collapse" data-bs-target="#submenu-contacts">
                                                <span>
                                                    <span className="sidebar-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs me-2">
                                                            <path d="M4.5 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM14.25 8.625a3.375 3.375 0 1 1 6.75 0 3.375 3.375 0 0 1-6.75 0ZM1.5 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM17.25 19.128l-.001.144a2.25 2.25 0 0 1-.233.96 10.088 10.088 0 0 0 5.06-1.01.75.75 0 0 0 .42-.643 4.875 4.875 0 0 0-6.957-4.611 8.586 8.586 0 0 1 1.71 5.157v.003Z" />
                                                        </svg>
                                                    </span>
                                                    <span className="sidebar-text">{t('Meus Contatos')}</span>
                                                </span>
                                                <span className="link-arrow">
                                                    <svg className="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                                                    </svg>
                                                </span>
                                            </span>
                                            <div className={`multi-level collapse ${contactsExpanded ? 'show' : ''}`} role="list" id="submenu-contacts">
                                                <ul className="flex-column nav">
                                                    {filteredContacts.map((user) => (                       
                                                        <li className={getClassName(user.nickName)} key={user.id} onClick={() => handleSelectUser(user)} data-bs-toggle="collapse" data-bs-target="#sidebarNew" aria-controls="sidebarNew"
                                                        aria-expanded="true" aria-label="Toggle navigation">
                                                            <Link to="#" className="nav-link d-flex align-items-center">
                                                                <span className="sidebar-icon">
                                                                    <Avatar imageUrl={`data:image/png;base64,${user.avatar}`} alt="Avatar" size="small" frameStyle="double-shadow-blue"/>
                                                                </span>
                                                                <span className="sidebar-text">
                                                                    {user.nickName || 'Sem Nome'}
                                                                    {messageNotifications[user.id] > 0 && (
                                                                        <span className="notification-badge">{messageNotifications[user.id]}</span>
                                                                    )}
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </li>
                                        {usersDelivered && usersDelivered.length > 0 && (<>
                                    <li className="nav-item">
                                        <span className="nav-link collapsed d-flex justify-content-between align-items-center" data-bs-toggle="collapse" data-bs-target="#submenu-noreads">
                                            <span>
                                                <span className="sidebar-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs me-2">
                                                  <path fillRule="evenodd" d="M4.848 2.771A49.144 49.144 0 0 1 12 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 0 1-3.476.383.39.39 0 0 0-.297.17l-2.755 4.133a.75.75 0 0 1-1.248 0l-2.755-4.133a.39.39 0 0 0-.297-.17 48.9 48.9 0 0 1-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97Z" clipRule="evenodd" />
                                                </svg>
                                                </span>
                                                <span className="sidebar-text">{t('Recentes')}</span>
                                            </span>
                                            <span className="link-arrow">
                                                <svg className="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                            </span>
                                        </span>
                                       
                                        {usersDelivered.map((user) => (   
                                            <div className={`multi-level collapse show`} role="list" id="submenu-noreads">
                                                <ul className="flex-column nav">
                                                    <li className="nav-item" key={user.id} onClick={() => handleSelectUser({
                                                        id: user.id,
                                                        nickName: user.nickName,
                                                        avatar: user.avatar
                                                        })} data-bs-toggle="collapse" data-bs-target="#sidebarNew" aria-controls="sidebarNew"
                                                        aria-expanded="true" aria-label="Toggle navigation">
                                                        <Link to="#" className="nav-link d-flex align-items-center">
                                                            <span className="sidebar-icon ms-n5 me-5 mb-n1">
                                                                <Avatar imageUrl={`data:image/png;base64,${user.avatar}`} alt="Avatar" size="small" frameStyle="double-shadow-blue" />
                                                            </span>
                                                            <span className="sidebar-text ms-n4 mb-2">
                                                                {user.nickName}
                                                                {messageNotifications[user.id] > 0 && (
                                                                    <span className="notification-badge">{messageNotifications[user.id]}</span>
                                                                )}
                                                            </span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        ))}
                                    </li>    
                                </>)}
                                    </>
                                )
                            }         
                    </ul>
                </div>
            </nav>)
        }
    </>        
    );
}

export default NewSideBar;
