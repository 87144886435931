import React from 'react';
import { useTranslation } from 'react-i18next';
import './SmartBadge.css';

/**
 * props:
 * - id
 * - text
 * - onClick
 */
function SmartBadge(props) {
    const { t } = useTranslation();
    return (
        <div className="input-group me-2 d-flex flex-row flex-nowrap">
            <span id="spanNoWrap" className="alert-2 alert-info py-1">
                {props.children} {t(props.text)}
            </span>
            <button id={props.id} type="button" className="btn btn-info btn-xs alert-2" title="Click to Remove" onClick={props.onClick}>X</button>
        </div>
    )
}

export default SmartBadge;