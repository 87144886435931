import React, { useState, useEffect, useRef } from 'react';

import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { useParams } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import Menu from '../../components/Menu/Menu';
import Toast from '../../components/Toast/Toast';
import NewSideBar from './NewSideBar';
import NewContactModal from './NewContactModal/NewContactModal';
import './Chat.css';
import { getAvatar, getNickname, getCity, uploadFile, getDeliveredMessages } from '../../services/ChatService';
import Avatar from '../../components/Avatar/Avatar';
import { Link } from 'react-router-dom';
import Picker from '@emoji-mart/react';
import emojiData from '@emoji-mart/data';
import pt from '@emoji-mart/data/i18n/pt.json';
import automationsImage from './automations.png';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import Linkify from 'react-linkify';
import { useTranslation } from 'react-i18next';


registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const CHAT_HISTORY_BATCH_SIZE = 20;

function Automations() {
    const { t } = useTranslation();
    const { userId } = useParams();
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);
    const [messageInput, setMessageInput] = useState('');
    const [notification, setNotification] = useState({ type: '', text: '' });
    const [chatMessages, setChatMessages] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);
    const [repliedUser, setRepliedUser] = useState(null);
    const [my, setMy] = useState({});
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [files, setFiles] = useState([]);
    const [loadingMessages, setLoadingMessages] = useState([]);
    const [city, setCity] = useState('');
    const localUserId = localStorage.getItem('id');
    const [isDropchatOpen, setIsDropchatOpen] = useState(false);
    const dropchatRef = useRef(null);
    const chatContainerRef = useRef(null);
    const messagesEndRef = useRef(null);
    const maxExecutions = 1;
    const [executionCount, setExecutionCount] = useState(0);

    const [messageNotifications, setMessageNotifications] = useState({});
    const [isGeneralRoom, setIsGeneralRoom] = useState(false);
    const [isLoadingHistory, setIsLoadingHistory] = useState(false);
    const [hasMoreMessages, setHasMoreMessages] = useState(true);
    const [pauseScroll, setPauseScroll] = useState(false);

    const [showScrollButton, setShowScrollButton] = useState(false);

    const processedMessagesRef = useRef(new Set());

    const chatContainerStyle = {
        backgroundImage: `url(${automationsImage})`,
    };

    const toggleDropchat = () => {
        setIsDropchatOpen(!isDropchatOpen);
    };

    useEffect(() => {
        // Salvar o background original do body
        const originalBackground = document.body.style.background;
    
        // Alterar o background do body
        document.body.style.background = '#1F2937';
    
        // Restaurar o background original quando o componente for desmontado
        return () => {
          document.body.style.background = originalBackground;
        };
      }, []);


    const {
        sendMessage,
        lastJsonMessage,
        readyState,
    } = useWebSocket(process.env.REACT_APP_WS_URL, {
        queryParams: { token: localStorage.getItem("token") },
        onOpen: () => {
            console.log('WebSocket connection opened');
            setReconnectAttempts(0); // Reset reconnection attempts on successful connection
        },
        onError: (event) => {
            console.error(event);
            //setNotification({ type: 'error', text: event.message || 'WebSocket error' });
        },
        shouldReconnect: (closeEvent) => true,
        reconnectAttempts: 0,
        reconnectInterval: 3000,
        reconnectMaxInterval: 30000, // Maximum interval for reconnection attempts
        reconnectExponential: true, // Exponential backoff for reconnection attempts
    });

    const [reconnectAttempts, setReconnectAttempts] = useState(0);

    // Definir reconnectInterval dentro do useEffect
    useEffect(() => {
        const reconnectInterval = Math.min(3000 * Math.pow(2, reconnectAttempts), 30000);
    
        if (readyState !== WebSocket.OPEN) {
            const reconnect = setTimeout(() => {
                setReconnectAttempts((prev) => prev + 1);
            }, reconnectInterval);
    
            return () => clearTimeout(reconnect);
        } else {
            setReconnectAttempts(0); // Reset attempts on successful connection
        }
    }, [readyState, reconnectAttempts]);

    // Ping/pong para manter a conexão ativa
    useEffect(() => {
        const pingInterval = setInterval(() => {
            if (readyState === WebSocket.OPEN) {
                sendMessage(JSON.stringify({ type: 'ping' }));
            }
        }, 30000); // 30 segundos

        return () => clearInterval(pingInterval);
    }, [readyState, sendMessage]);

    /*
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };*/

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 992);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (lastJsonMessage) {
            //console.log('Mensagem recebida:', lastJsonMessage);
            if (lastJsonMessage.type === 'history') {
                if (!isLoadingHistory) setChatMessages([]);
                const updatedHistory = lastJsonMessage.history.map(msg => {
                    if (msg.userId !== localUserId){
                        //console.log(msg.id, 'read', msg.userId);
                        sendStatusUpdate(msg.id, 'read', msg.userId);
                    }
                    return msg;
                });
                setChatMessages(prevMessages => [...updatedHistory, ...prevMessages]);
                setHasMoreMessages(lastJsonMessage.history.length === CHAT_HISTORY_BATCH_SIZE);
                setIsLoadingHistory(false);
            } else if (['direct', 'chat', 'edit', 'delete'].includes(lastJsonMessage.type)) {
                if (lastJsonMessage.type === 'edit')  {
                    setChatMessages(prevMessages => {
                        return prevMessages.map(msg => {
                            //console.log('EDIT: ', msg.content, lastJsonMessage.content);
                            if (msg.id === lastJsonMessage.messageId) {
                                return { ...msg, content: lastJsonMessage.content };
                            }
                            return msg;
                        });
                    });
                } else if ( lastJsonMessage.type === 'delete') {
                    
                    setChatMessages([]);
                    loadChatHistory(selectedUser.id);
                    
                } else if (parseFloat(lastJsonMessage.userId) === parseFloat(selectedUser?.id) || lastJsonMessage.userId === localUserId) {

                    setChatMessages(prevMessages => [...prevMessages, lastJsonMessage]);
                    if (lastJsonMessage.userId !== localUserId){
                        sendStatusUpdate(lastJsonMessage.id, 'read', lastJsonMessage.userId);
                    }
                } else if (lastJsonMessage.type === 'chat' && lastJsonMessage.chatId === city && lastJsonMessage.chatId === selectedUser.id) {
                        setChatMessages(prevMessages => [...prevMessages, lastJsonMessage]);
                   
                } else {
                    sendStatusUpdate(lastJsonMessage.id, 'delivered', lastJsonMessage.userId);
                    setMessageNotifications(prevNotifications => ({
                        ...prevNotifications,
                        [lastJsonMessage.userId]: (prevNotifications[lastJsonMessage.userId] || 0) + 1
                    }));
                }
            } else if (lastJsonMessage.type === 'read' || lastJsonMessage.type === 'delivered') {
                //console.log('RECEBI A CONFIRMACAO', lastJsonMessage.type);
                setChatMessages(prevMessages => {
                    return prevMessages.map(msg => {
                        if (msg.id === lastJsonMessage.messageId) {
                            return { ...msg, status: { ...msg.status, ...lastJsonMessage.status } };
                        }
                        return msg;
                    });
                });
            }
        }
    }, [lastJsonMessage, selectedUser?.id, localUserId]);

    useEffect(() => {
        if (pauseScroll) return;
        scrollToBottom();
    }, [chatMessages, loadingMessages]);

    useEffect(() => {
        async function fetchData() {
            try {
                const [avatar, nickName, userCity, deliveredMessages] = await Promise.all([
                    getAvatar(localUserId),
                    getNickname(localUserId),
                    getCity(localUserId),
                    getDeliveredMessages(localUserId)
                ]);
    
                setMy({
                    id: localUserId,
                    avatar,
                    nickName,
                    city: userCity
                });
                setCity(userCity);
    
                if (!selectedUser) {
                    if (!userId) {
                        const [avatar] = await Promise.all([getAvatar(1)]);
                        setSelectedUser({
                            id: userCity, nickName: `Chat ${userCity}`, city: userCity, avatar: avatar
                        });
                    }
                }
                /*
                setMessageNotifications(prevNotifications => ({
                    ...prevNotifications,
                    [lastJsonMessage.userId]: (prevNotifications[lastJsonMessage.userId] || 0) + 1
                }));*//*
                setMessageNotifications(deliveredMessages.reduce((acc, message) => {
                    acc[message.userId] = (acc[message.userId] || 0) + 1;
                    return acc;
                }, {}));*/

                const deliveredCounts = deliveredMessages.reduce((acc, message) => {
                    acc[message.userId] = (acc[message.userId] || 0) + message.messageCount;
                    return acc;
                }, {});
            
                // Atualiza as notificações de mensagem com os novos valores
                setMessageNotifications(prevNotifications => {
                    const newNotifications = { ...prevNotifications };
            
                    for (const userId in deliveredCounts) {
                        newNotifications[userId] = (newNotifications[userId] || 0) + deliveredCounts[userId];
                    }
            
                    return newNotifications;
                });

            } catch (err) {
                console.error(err.response ? err.response.data : err.message);
            }
        }
        fetchData();
    }, [localUserId]);

    useEffect(() => {
        if (isGeneralRoom) {
            loadChatHistory(null);
        } else if (userId) {
            async function fetchData() {
                try {
                    const [avatar, nickName, userCity] = await Promise.all([getAvatar(userId), getNickname(userId), getCity(userId)]);
                    setSelectedUser({
                        id: userId,
                        avatar,
                        nickName,
                        city: userCity
                    });
                } catch (err) {
                    console.error(err.response ? err.response.data : err.message);
                }
            }
            fetchData();
            loadChatHistory(userId);
        }
    }, [isGeneralRoom, userId]);

    
    useEffect(() => {
        if (selectedUser && executionCount < maxExecutions) {
            for (let i = 0; i < chatMessages.length; i++) {
                const message = chatMessages[i];
                if (parseFloat(message.userId) === parseFloat(selectedUser.id)) {
                    sendStatusUpdate(message.id, 'read', selectedUser.id);
                    //console.log('PASSOU POR ESSA PUTARIA: ', message.id, 'read', selectedUser.id);
                }
            }
            setExecutionCount(prevCount => prevCount + 1);
        }
        /*if (selectedUser) { //&& executionCount < maxExecutions) {
            chatMessages.forEach(message => {
                if (parseFloat(message.userId) === parseFloat(selectedUser.id)) {
                    sendStatusUpdate(message.id, 'read', selectedUser.id);
                    console.log('PASSOU POR ESSA PUTARIA: ', message.id, 'read', selectedUser.id);
                }
            });
            //setExecutionCount(prevCount => prevCount + 1);
        }*/
    }, [chatMessages, selectedUser, executionCount]);
    

    useEffect(() => {
        if (!userId) {
            setChatMessages([]);
        }
        if (!selectedUser) return;
        if (selectedUser && selectedUser.id !== my.city) {
            setIsGeneralRoom(false);
            setExecutionCount(0);
        } else {
            setIsGeneralRoom(true);
            setCity(selectedUser.city);
        }
    }, [selectedUser]);


    const sendStatusUpdate = (messageId, statusType, userId) => {
        const message = {
            type: statusType,
            messageId: messageId,
            userId: localUserId,
            recipientId: userId
        };
        sendMessage(JSON.stringify(message));
    };

    const loadChatHistory = (otherUserId, offset = 0) => {
        setIsLoadingHistory(true);
        setPauseScroll(true);
        const message = {
            type: 'history',
            userId: localUserId,
            otherUserId: otherUserId,
            city: city,
            limit: CHAT_HISTORY_BATCH_SIZE,
            offset: offset
        };
        sendMessage(JSON.stringify(message));
        setTimeout(() => {
            setPauseScroll(false);
        }, 3000);
        setMessageNotifications(prevNotifications => ({
            ...prevNotifications,
            [otherUserId]: 0
        }));
    };

    const sendMessageToWebSocket = (content, contentType = 'text', messageId = null) => {
        const message = {
            type: isGeneralRoom ? 'chat' : 'direct',
            userId: localUserId,
            userName: my.nickName,
            content: content,
            contentType: contentType,
            city: isGeneralRoom ? city : null,
            recipientId: isGeneralRoom ? city : selectedUser ? selectedUser.id : null,
            timestamp: new Date().toISOString(),
            status: {
                sent: true,
                delivered: false,
                read: false
            }
        };
        sendMessage(JSON.stringify(message));
    };

    const sendDeleteToWebSocket = (content, contentType = 'text', messageId = null) => {
        const message = {
            id: messageId,
            type: 'delete',
            userId: localUserId,
            recipientId: isGeneralRoom ? city : selectedUser ? selectedUser.id : null,
        };
        sendMessage(JSON.stringify(message));
    };

    const sendEditToWebSocket = (content, contentType = 'text', messageId = null) => {
        const message = {
            id: messageId,
            type: 'edit',
            userId: localUserId,
            content,
            recipientId: isGeneralRoom ? city : selectedUser ? selectedUser.id : null,
        };
        sendMessage(JSON.stringify(message));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (messageInput.trim() !== '') {
            sendMessageToWebSocket(messageInput);
            setMessageInput('');
            const textarea = document.querySelector('.chat__input');
            textarea.style.height = 'auto';
        }
    };

    /*
    const handleFileUpload = async (file) => {
        const messageId = Date.now();
        setLoadingMessages(prev => [...prev, messageId]);
        try {
            const response = await uploadFile(file);
            //const fileUrl = `https://yepwedo.com:3001${response.fileUrl}`;
            const fileUrl = `http://34.95.222.37:3003${response.fileUrl}`;
            sendMessageToWebSocket(fileUrl, 'file');
        } catch (error) {
            console.error('Failed to upload file', error);
        } finally {
            setLoadingMessages(prev => prev.filter(id => id !== messageId));
        }
    };*/

    const handleDwnMessage= async (fileUrl) => {
        const link = document.createElement('a');
        link.href = fileUrl;
    
        // Extrair o nome do arquivo da URL
        const fileName = fileUrl.split('/').pop();
        link.download = fileName;
    
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleFileUpload = async (file) => {
        const messageId = Date.now();
        setLoadingMessages(prev => [...prev, messageId]);
        try {
            const response = await uploadFile(file);
            const fileUrl = `${process.env.REACT_APP_API_URL}${response.fileUrl}`;
            sendMessageToWebSocket(fileUrl, 'file');
        } catch (error) {
            console.error('Failed to upload file', error);
        } finally {
            setLoadingMessages(prev => prev.filter(id => id !== messageId));
        }
    };

    const handleInput = (event) => {
        setMessageInput(event.target.value);
        event.target.style.height = 'auto';
        event.target.style.height = event.target.scrollHeight + 'px';
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            handleSubmit(event);
        }
    };

    const handleKeyDown2 = (event) => {
        if (event.key === 'Enter') {
            setMessageInput(messageInput + '\n');
        }
    };

    /*
    const handleScroll = () => {
        //console.log('Chamou');
        if (chatContainerRef.current.scrollTop <= -chatContainerRef.current.scrollHeight + 1000 && hasMoreMessages && !isLoadingHistory) {
            loadChatHistory(selectedUser?.id, chatMessages.length);
        }
    };*/

    const scrollToBottom = () => {
        chatContainerRef.current.scrollTo({ top: chatContainerRef.current.scrollHeight, behavior: 'smooth' });
        setShowScrollButton(false);
    };

    const handleScroll = () => {
        if (chatContainerRef.current.scrollTop === 0 && hasMoreMessages && !isLoadingHistory) {
            loadChatHistory(selectedUser?.id, chatMessages.length);
        }
        const bottomThreshold = 50;
        const isAtBottom = chatContainerRef.current.scrollHeight - chatContainerRef.current.scrollTop <= chatContainerRef.current.clientHeight + bottomThreshold;
        setShowScrollButton(!isAtBottom);
    };

    const addEmoji = (emoji) => {
        setMessageInput(messageInput + emoji.native);
        setShowEmojiPicker(false);
    };

    const formatTimestamp = (timestamp) => {
        const now = new Date();
        const messageTime = new Date(timestamp);
        const diffInMinutes = Math.floor((now - messageTime) / 60000);

        if (diffInMinutes < 1) {
            return t('Agora');
        } else if (diffInMinutes < 60) {
            return `${diffInMinutes} min`;
        } else if (diffInMinutes < 24 * 60) {
            return messageTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        } else {
            return messageTime.toLocaleDateString();
        }
    };

    const [messageOptionsOpen, setMessageOptionsOpen] = useState(null);

    const toggleMessageOptions = (messageId) => {
        setMessageOptionsOpen(prevId => (prevId === messageId ? null : messageId));
    };

    const isMessageOptionsOpen = (messageId) => {
        return messageOptionsOpen === messageId;
    };

    const handleEditMessage = (messageId) => {
        const newContent = prompt(t('Edite a mensagem') + ':', chatMessages.find(m => m.id === messageId).content);
        if (newContent !== null) {
            setMessageOptionsOpen(null); // Fecha o dropdown
            sendEditToWebSocket(newContent, 'edit', messageId);
        }
    };

    const handleDeleteMessage = (messageId) => {
        if (window.confirm(t('Tem certeza que deseja excluir esta mensagem?'))) {
            setMessageOptionsOpen(null); // Fecha o dropdown
            sendDeleteToWebSocket('', 'delete', messageId);
        }
    };

    const handleAddMessage = (url) => {
        window.location.href = url;
    };

    const getStatusIcon = (message) => {
        if (message.status.read) {
            return <span className="message-status read">✔✔</span>; // Ícone de lido
        } else if (message.status.delivered) {
            return <span className="message-status delivered">✔✔</span>; // Ícone de entregue
        } else if (message.status.sent) {
            return <span className="message-status sent">✔</span>; // Ícone de enviado
        } else {
            return null;
        }
    };

    const renderMessageContent = (message) => {
        return (
            <div className={message.userId === localUserId ? "message message--self" : "message message--other"}>
                <div className="message-header">
                     <span className="message--sender">{message.userName}</span>
                    {message.userId === localUserId ? (
                        <div className="message-options">
                            <button className="message-options-button" onClick={() => toggleMessageOptions(message.id)}>
                                <span className="link-arrow">
                                    <svg className="icon icon-sm" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(90deg)' }}>
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                                    </svg>
                                </span>
                            </button>
                            {isMessageOptionsOpen(message.id) && (
                                <div className="message-dropdown">
                                    {   message.contentType === 'file'
                                        ? <button onClick={() => handleDwnMessage(message.content)}>{t('Download')}</button>
                                        : <button onClick={() => handleEditMessage(message.id)}>{t('Editar')}</button>
                                    }                                  
                                    <button onClick={() => handleDeleteMessage(message.id)}>{t('Excluír')}</button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="message-options">
                            <button className="message-options-button" onClick={() => toggleMessageOptions(message.id)}>
                                <span className="link-arrow">
                                    <svg className="icon icon-sm" fill="blue" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(90deg)' }}>
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                                    </svg>
                                </span>
                            </button>
                            {isMessageOptionsOpen(message.id) && (
                                <div className="message-dropdown">
                                    {   message.contentType === 'file'
                                        ? <button onClick={() => handleDwnMessage(message.content)}>{t('Download')}</button>
                                        : <React.Fragment></React.Fragment>
                                    }
                                    <button onClick={() => handleAddMessage(`/chat/${message.userId}`)}>{t('Mensagem')}</button>
                                    <button onClick={() => handleDeleteMessage(message.id)}>{t('Excluír')}</button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="message-body">
                    {message.contentType === 'file' ? renderFileContent(message.content) : <Linkify>{message.content}</Linkify>}
                </div>
                <span className="message--timestamp">{formatTimestamp(message.timestamp)} {message.userId === localUserId ? getStatusIcon(message) : ''}</span>
                
            </div>
        );
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const maxVideoSize = 30 * 1024 * 1024; // 30MB
            const videoExtensions = ['mp4', 'webm', 'ogg', 'mkv'];
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (videoExtensions.includes(fileExtension) && file.size > maxVideoSize) {
                alert(t('O arquivo de vídeo excede o tamanho máximo permitido de 30MB.'));
                return;
            }
            // Carregar o arquivo se não for vídeo ou se estiver dentro do limite de tamanho
            await handleFileUpload(file);
        }
    };

    const renderFileContent = (content) => {
        const fileExtension = content.split('.').pop().toLowerCase();
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
        const videoExtensions = ['mp4', 'webm', 'ogg', 'mkv'];
        const audioExtensions = ['mp3', 'wav', 'ogg', 'm4a', '3gp'];
        const pdfExtensions = ['pdf'];
    
        if (imageExtensions.includes(fileExtension)) {
            return <img src={content} alt="Imagem enviada" style={{ maxWidth: '100%', maxHeight: '300px' }} />;
        } else if (videoExtensions.includes(fileExtension)) {
            return (
                <video controls style={{ maxWidth: '100%', maxHeight: '300px' }}>
                    <source src={content} type={`video/${fileExtension}`} />
                    {t('Seu navegador não suporta a tag de vídeo.')}
                </video>
            );
        } else if (audioExtensions.includes(fileExtension)) {
            // Se a extensão for mp3, mas na verdade for m4a, force o tipo de áudio
            let mimeType = `audio/${fileExtension}`;
            if (fileExtension === 'mp3' && content.includes('.m4a')) {
                mimeType = 'audio/mp4';
            }
            return (
                <audio controls style={{ width: '100%' }}>
                    <source src={content} type={mimeType} />
                    {t('Seu navegador não suporta a tag de áudio.')}
                </audio>
            );
        } else if (pdfExtensions.includes(fileExtension)) {
            return (
                <Worker workerUrl={`${process.env.REACT_APP_API_URL}/js/pdf.worker.min.js`}>
                    <div style={{ height: '300px' }}>
                        <Viewer fileUrl={content} />
                    </div>
                </Worker>
            );
        } else {
            return <a href={content} target="_blank" rel="noopener noreferrer">{content}</a>;
        }
    };
    
    /*<Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js`}>*/

    return (
        <React.Fragment>
            <Menu />
            {isLargeScreen ? (
                <main className="content">
                    {isSidebarOpen && (
                        <NewSideBar
                            onClose={() => setIsSidebarOpen(false)}
                            onSelectUser={(user) => {
                                if (user) {
                                    setSelectedUser(user);
                                    setChatMessages([]);
                                    loadChatHistory(user.id);
                                }
                            }}
                            repliedUser={repliedUser}
                            messageNotifications={messageNotifications}
                            sendMessage={sendMessage}
                        />
                    )}
                    <div className="chat-container">
                        {selectedUser && (
                            <section className="chat__header" style={{background: '#1F2937'}}>
                                <div className="container-fluid d-flex justify-content-between align-items-center">
                                    <ul className="navbar-nav flex-row align-items-center">
                                        <li className="nav-item">
                                            <a className="d-flex align-items-center mt-2" data-bs-toggle="modal" data-bs-target="#modalNewContact">
                                                <span className="sidebar-icon ms-4 mb-n1">
                                                    <Avatar imageUrl={`data:image/png;base64,${selectedUser.avatar}`} alt="Avatar" size="medium" frameStyle="double-shadow-white"/>
                                                </span>
                                                <span className="sidebar-text text-white ms-3">{!selectedUser.nickName?.includes('Chat') ? selectedUser.nickName : t('Comunidade')}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </section>
                        )}
                        <section className="chat__messages-wrapper" style={{background: '#1F2937'}} ref={chatContainerRef} onScroll={handleScroll}>
                            <div className="chat__messages">
                                {chatMessages.map((message, index) => (
                                    <React.Fragment key={index}>
                                        {renderMessageContent(message)}
                                    </React.Fragment>
                                ))}
                                {loadingMessages.map(id => (
                                    <div key={id} className="message message--self loading-message">
                                        <div className="loading-spinner"></div>
                                        <span className="message--timestamp">{formatTimestamp(new Date())}</span>
                                    </div>
                                ))}
                                <div ref={messagesEndRef}></div>
                            </div>
                        </section>
                        {showScrollButton && (
                            <button className="scroll-to-bottom-button" onClick={scrollToBottom}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs-2">
                                  <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-.53 14.03a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V8.25a.75.75 0 0 0-1.5 0v5.69l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3Z" clipRule="evenodd" />
                                </svg>
                            </button>
                        )}
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <form onSubmit={handleSubmit} className="chat__form" style={{background: '#1F2937'}}>
                                <div className="chat__input-container">
                                    <button type="button" className="emoji-button" onClick={() => setShowEmojiPicker(!showEmojiPicker)} style={{color: '#007BFF'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="icon icon-xs-2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 15.182a4.5 4.5 0 0 1-6.364 0M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z" />
                                    </svg>

                                        {/*#007BFF*/}
                                    </button>
                                    <button type="button" className="emoji-button ms-n2" style={{color: '#007BFF'}} onClick={() => document.querySelector('#file-input').click()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="icon icon-xs-1">
                                        <path strokeLinecap="round" strokeLinejoin="round" transform="rotate(316 12 12)" d="M18.375 12.739L10.682 20.432A4.5 4.5 0 0 1 4.318 14.068l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
                                    </svg>
                                    </button>
                                    <textarea
                                        className="chat__input"
                                        placeholder={t('Escreva sua mensagem aqui...')}
                                        required
                                        value={messageInput}
                                        onChange={handleInput}
                                        onKeyDown={handleKeyDown}
                                        rows="1"
                                    />
                                    <button type="submit" className="chat__button" style={{color: '#007BFF'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs-2 ms-3">
                                            <path d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
                                        </svg>
                                    </button>
                                </div>
                                <input
                                    id="file-input"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                                {showEmojiPicker && (
                                    <div className="emoji-picker">
                                        <Picker
                                            data={emojiData}
                                            i18n={pt}
                                            onEmojiSelect={addEmoji}
                                            perLine={9}
                                            emojiButtonSize={32}
                                        />
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </main>
            ) : (
                <main className="content">
                    {isSidebarOpen && (
                        <NewSideBar
                            onClose={() => setIsSidebarOpen(false)}
                            onSelectUser={(user) => {
                                if (user) {
                                    setSelectedUser(user);
                                    setChatMessages([]);
                                    loadChatHistory(user.id);
                                }
                            }}
                            repliedUser={repliedUser}
                            messageNotifications={messageNotifications}
                            sendMessage={sendMessage}
                        />
                    )}
                    <div className="chat-container" style={{ left: "0", right: "auto", width: "100%" }}>
                        <section className="chat__header" style={{background: '#1F2937'}}>
                            <div className="container-fluid d-flex justify-content-between align-items-center">
                                {selectedUser ? (
                                    <React.Fragment>
                                        <ul className="navbar-nav flex-row align-items-center">
                                            <li className="nav-item">
                                                <a className="d-flex align-items-center mt-2" data-bs-toggle="modal" data-bs-target="#modalNewContact">
                                                    <span className="sidebar-icon ms-4 mb-n1">
                                                        <Avatar imageUrl={`data:image/png;base64,${selectedUser.avatar}`} alt="Avatar" size="medium" />
                                                    </span>
                                                    <span className="sidebar-text text-white ms-3">{!selectedUser.nickName?.includes('Chat') ? selectedUser.nickName : t('Comunidade')}</span>
                                                </a>
                                            </li>
                                        </ul>
                                        <a className="mt-2 mb-2 me-3" type="button" onClick={toggleDropchat}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="icon icon-xs-2">
                                                <path fillRule="evenodd" d="M10.5 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm0 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm0 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z" clipRule="evenodd" />
                                            </svg>
                                        </a>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <ul className="navbar-nav flex-row align-items-center">
                                            <li className="nav-item">
                                            </li>
                                        </ul>
                                        <a className="mt-2 mb-2 me-3" type="button" onClick={toggleDropchat}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="icon icon-xs-2">
                                                <path fillRule="evenodd" d="M10.5 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm0 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm0 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z" clipRule="evenodd" />
                                            </svg>
                                        </a>
                                    </React.Fragment>
                                )}
                            </div>
                        </section>
                        {isDropchatOpen && (
                            <div ref={dropchatRef} className={`dropdown-menu show ${isDropchatOpen ? 'right-aligned' : ''}`}>
                                <DropchatMenuItems t={t} onClose={() => setIsSidebarOpen(false)} onToggle={() => setIsDropchatOpen(false)} />
                            </div>
                        )}
                        <section className="chat__messages-wrapper" style={{background: '#1F2937'}} ref={chatContainerRef} onScroll={handleScroll}>
                            <div className="chat__messages">
                                {chatMessages.map((message, index) => (
                                    <React.Fragment key={index}>
                                        {renderMessageContent(message)}
                                    </React.Fragment>
                                ))}
                                {loadingMessages.map(id => (
                                    <div key={id} className="message message--self loading-message">
                                        <div className="loading-spinner"></div>
                                        <span className="message--timestamp">{formatTimestamp(new Date())}</span>
                                    </div>
                                ))}
                                <div ref={messagesEndRef}></div>
                            </div>
                        </section>
                        {showScrollButton && (
                            <button className="scroll-to-bottom-button" onClick={scrollToBottom}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs-2">
                                  <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-.53 14.03a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V8.25a.75.75 0 0 0-1.5 0v5.69l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3Z" clipRule="evenodd" />
                                </svg>
                            </button>
                        )}
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <form onSubmit={handleSubmit} className="chat__form" style={{ background: '#1F2937', left: "0", right: "auto", width: "100%" }}>
                                <div className="chat__input-container">
                                <button type="button" className="emoji-button" onClick={() => setShowEmojiPicker(!showEmojiPicker)} style={{color: '#007BFF'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="icon icon-xs-2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 15.182a4.5 4.5 0 0 1-6.364 0M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z" />
                                    </svg>

                                        {/*#007BFF*/}
                                    </button>
                                    <button type="button" className="emoji-button ms-n2" style={{color: '#007BFF'}} onClick={() => document.querySelector('#file-input').click()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="icon icon-xs-1">
                                        <path strokeLinecap="round" strokeLinejoin="round" transform="rotate(316 12 12)" d="M18.375 12.739L10.682 20.432A4.5 4.5 0 0 1 4.318 14.068l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
                                    </svg>
                                    </button>
                                    <textarea
                                        className="chat__input"
                                        placeholder={t('Escreva sua mensagem aqui...')}
                                        required
                                        value={messageInput}
                                        onChange={handleInput}
                                        onKeyDown={handleKeyDown2}
                                        rows="1"
                                    />
                                    <button type="submit" className="chat__button" style={{color: '#007BFF'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs-2 ms-3">
                                            <path d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
                                        </svg>
                                    </button>
                                </div>
                                <input
                                    id="file-input"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                                {showEmojiPicker && (
                                    <div className="emoji-picker">
                                        <Picker
                                            data={emojiData}
                                            i18n={pt}
                                            onEmojiSelect={addEmoji}
                                            perLine={9}
                                            emojiButtonSize={32}
                                        />
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </main>
            )}
            <NewContactModal selectedUser={selectedUser} />
            <Toast type={notification.type} text={notification.text} />
        </React.Fragment>
    );
    }
    
    const DropchatMenuItems = ({ onClose, onToggle, t }) => (
        <ul className="list-unstyled">
            <li>
                <Link to="#" className="dropdown-item" data-bs-toggle="collapse" data-bs-target="#sidebarNew" aria-controls="sidebarNew" aria-expanded="true" onClick={onToggle}>
                    {t('Contatos')}
                </Link>
            </li>
            <li>
                <Link to="/dashboard" className="dropdown-item" onClick={onClose}>
                {t('Voltar')}
                </Link>
            </li>
        </ul>
    );
    
export default Automations;
    