import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import NewOrderModal from '../../components/NewOrder/NewOrderModal';
import PrivacyModal from '../../components/Privacy/PrivacyModal';
import TermsModal from '../../components/Terms/TermsModal';
import Pagination from '../../components/Pagination/Pagination';
import Toast from '../../components/Toast/Toast';
import FavoritesRow from './FavoritesRow'; // Import the FavoritesRow component
import { getFavorites } from '../../services/FavoriteService'; // Create a service to fetch favorites
import { useTranslation } from 'react-i18next';

function Favorites() {
    const { t } = useTranslation();

    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);
    const [favorites, setFavorites] = useState([]);
    const [viewFavorite, setViewFavorite] = useState({});
    const [notification, setNotification] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const [showPrivacyModal, setShowPrivacyModal] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);

    const history = useHistory();
    const location = useLocation();

    const PAGE_SIZE = 10;

    useEffect(() => {
        const handleResize = () => {
          setIsLargeScreen(window.innerWidth >= 992);
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const unlisten = history.listen((location) => {
            setPage(getPage(location) || 1);
        });

        return () => {
            unlisten();
        };
    }, [history]);

    useEffect(() => {
        loadFavorites(page);
    }, [page, refresh]);

    const loadFavorites = (page) => {
        setIsLoading(true);
        const offset = (page - 1) * PAGE_SIZE;
        
        getFavorites()
            .then(result => {
                const paginatedFavorites = result.rows.slice(offset, offset + PAGE_SIZE);
                setFavorites(paginatedFavorites);
                setCount(result.count);
                setIsLoading(false);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setIsLoading(false);
            });
    };

    function getPage(location) {
        return new URLSearchParams(location.search).get('page');
    }

    function onClick(event) {
        const id = event.target.id.replace('fav', '');
        const sup = favorites.find(o => o.id == id);
        setViewFavorite({ ...sup });
    }

    function onStarClick(event) {
        const id = event.target.getAttribute('name');
        const item = favorites.find(o => o.id == id);
        if (item) {
          const supWithRating = { ...item, rating: true };
          setViewFavorite(supWithRating);
        }
    }

    function onExcludeFavorite() {
        setNotification({ type: 'success', text: t('Favorito excluído com sucesso!') });
        setRefresh(Date.now());
    }

    const openPrivacyModal = () => setShowPrivacyModal(true);
    const closePrivacyModal = () => setShowPrivacyModal(false);

    const openTermsModal = () => setShowTermsModal(true);
    const closeTermsModal = () => setShowTermsModal(false);

    return (
        <>
        { isLargeScreen 
            ? (
                <React.Fragment>
                    <Menu />
                    <main className="content">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <div className="d-block mb-4 mb-md-0">
                                <h2 className="h4">{t('Meus Favoritos')}</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-body border-0 shadow table-wrapper table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th className="border-gray-200">{t('NOME')}</th>
                                                <th className="border-gray-200">{t('PROFISSÃO')}</th>
                                                <th className="border-gray-200">{t('CATEGORIA')}</th>
                                                <th className="border-gray-200">{t('AVALIAÇÃO')}</th>
                                                <th className="border-gray-200">{t('AÇÃO')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                !isLoading && favorites && favorites.length
                                                    ? favorites.map(item => 
                                                        <FavoritesRow key={item.id} data={item} onClick={onClick} onExclude={onExcludeFavorite} onStarClick={onStarClick}/>
                                                    )
                                                    : <tr><td colSpan={5}>{t('Carregando')}...</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Pagination count={count} size={PAGE_SIZE} />
                        <Footer />
                    </main>
                    <NewOrderModal data={viewFavorite} isSelectDisabled={true} />
                    <PrivacyModal show={showPrivacyModal} onHide={closePrivacyModal} />
                    <TermsModal show={showTermsModal} onHide={closeTermsModal} />
                    <Toast type={notification.type} text={notification.text} />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Menu />
                    <main className="content">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <div className="d-block mb-4 mb-md-0">
                                <h2 className="h4">{t('Meus Favoritos')}</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-body border-0 shadow table-wrapper table-responsive" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                                    <table className="table table-hover" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                                        <thead style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                                            <tr style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                                                <th className="border-gray-200" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('NOME')}</th>
                                                <th className="border-gray-200" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('PROFISSÃO')}</th>
                                                <th className="border-gray-200" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('CATEGORIA')}</th>
                                                <th className="border-gray-200" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('AVALIAÇÃO')}</th>
                                                <th className="border-gray-200" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('AÇÃO')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                !isLoading && favorites && favorites.length
                                                    ? favorites.map(item => 
                                                        <FavoritesRow key={item.id} data={item} onClick={onClick} onExclude={onExcludeFavorite} onStarClick={onStarClick}/>
                                                    )
                                                    : <tr><td colSpan={5}>{t('Carregando')}...</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Pagination count={count} size={PAGE_SIZE} />
                        <Footer />
                    </main>
                    <PrivacyModal show={showPrivacyModal} onHide={closePrivacyModal} />
                    <TermsModal show={showTermsModal} onHide={closeTermsModal} />
                    <NewOrderModal data={viewFavorite} isSelectDisabled={true}/>
                    <Toast type={notification.type} text={notification.text} />
                </React.Fragment>
            )
        }
    </>
    );    
}

export default Favorites;
