import React, { useState, useEffect } from 'react';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import './PlanOptions.css';
import PlanMonths from './PlanMonths';
import PlanYears from './PlanYears';
import Modal from './Modal'; // Importando Modal
import { getSettings } from '../../services/SettingsService';
import { getPlan, cancelPlan } from '../../services/PaymentService';
import { useTranslation } from 'react-i18next';

function PlanOptions() {
  const { t, i18n } = useTranslation();
  const [settings, setSettings] = useState({});
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [plan, setPlan] = useState({});
  const [refresh, setRefresh] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showModalCancelar, setShowModalCancelar] = useState(false);

  const getQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const id = params.get('id');
    return { token, id };
  };

  useEffect(() => {
    const { token, id } = getQueryParams();
    if (token && id) {
      localStorage.setItem('token', token);
      localStorage.setItem('id', id);
    }
  }, []);

  useEffect(() => {
    const { token, id } = getQueryParams();
    if (token && id) {
      localStorage.setItem('token', token);
      localStorage.setItem('id', id);
    }
    const fetchData = async () => {
      try {
        const settingsResult = await getSettings();
        console.log(settingsResult);
        setSettings(settingsResult);

        const planResult = await getPlan();
        if (planResult.userId) {
          console.log(planResult);
          setPlan(planResult);

          // Verifica se o plano está incompleto
          if (!planResult.description || planResult.description === null) {
            setShowModal(true); // Exibe a modal
          }
        }
      } catch (err) {
        console.error(err.response ? err.response.data : err.message);
      }
    };
    fetchData();
  }, [refresh]);

  const handlePlanSelect = (plano) => {
    //'Premium Mensal')}>{settings.priority === 0 ? 'Assinar Agora!' : plan.description && plan.description === 'Mensal' ? 'Cancelar' : 'Assinar Agora!'}
    if (plano === 'Premium Mensal' && settings.priority === 0 || plano === 'Premium Anual' && settings.priority === 0) {
      setSelectedPlan(plano);
    } else if (plano === 'Grátis' && settings.priority !== 0) {
        // Mensagem , Você tem certeza que deseja cancelar o plano atual ?
        //Apenas função que cancela o plano e atribui a prioridade novamente.
        onCancelPlan();
    } else if ( plano === 'Premium Anual' && plan.description && plan.description === 'Mensal' ||
      plano === 'Premium Mensal' && plan.description && plan.description === 'Anual' ) {
        // Mensagem , Você tem certeza que deseja cancelar o plano atual ?
        onCancelPlan(plano, plan.description);
    } else if ( plano !== 'Grátis' && plan.description ) {
        onCancelPlan(false, false, 'cancelar');
    }
  };

  function onCancelPlan(planoA = false, planoB = false, planoC = false) {
    if (planoA && planoB) {
      const confirmationMessage = `${t('Verificamos que você possui o Plano')} ${t(planoB)}. ${t('Para trocar de plano, é necessário realizar o cancelamento do plano atual. Tem certeza que deseja prosseguir ?')}`;
    
      if (window.confirm(confirmationMessage)) {
          const cancel = {
            subscription: plan.subscription
          }
          cancelPlan(cancel)
            .then(result => {
              console.log(result);
              setShowModalCancelar(true);
              setRefresh(Date.now());
              setSelectedPlan(planoA);
            })
            .catch(err => console.log(err))
        }
    } else if(planoC === 'cancelar') {
      if (window.confirm(t('Tem certeza que deseja cancelar seu plano atual ?'))) {
        const cancel = {
          subscription: plan.subscription
        }
        cancelPlan(cancel)
          .then(result => {
            console.log(result);
            setShowModalCancelar(true);
            setRefresh(Date.now());
            setSelectedPlan(planoA);
          })
          .catch(err => console.log(err))
      }
    } else {
      if (window.confirm(t('Para retornar ao Plano Grátis, é necessário realizar o cancelamento do plano atual. Tem certeza que deseja prosseguir ?'))) {
        const cancel = {
          subscription: plan.subscription
        }
        cancelPlan(cancel)
          .then(result => {
            console.log(result);
            setShowModalCancelar(true);
            setRefresh(Date.now());
            setSelectedPlan(planoA);
          })
          .catch(err => console.log(err))
      }
    }
  }    

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseModalCancelar = () => {
    setShowModalCancelar(false);
  };

  return (
    <>
      <Menu />
      <main className="content">
        <header className="header-section">
          <div className="d-block mb-4 mb-md-0 text-center">
            {!selectedPlan ? (
              <>
                <h1 className="h4">{t('Escolha seu Plano')}</h1>
                <p className="lead">{t('Selecione o plano que melhor atende às suas necessidades')}</p>
              </>
            ) : selectedPlan === 'Premium Mensal' ? (
              <>
                <h1 className="h4">{t('Plano')} {t(selectedPlan)}</h1>
                <p className="lead">{t('Complete seu pagamento')}</p>
              </>
            ) : (
              <>
                <h1 className="h4">{t('Plano')} {t(selectedPlan)}</h1>
                <p className="lead">{t('Complete seu pagamento')}</p>
              </>
            )}
          </div>
        </header>
      
        <div className="py-4">
          {!selectedPlan ? (
            <div className="plan-container">
              <div className="plan-card">
                <h3 className="plan-title">{t('Grátis')}</h3>
                <ul className="plan-features">
                <li>{t('Solicitações Imediatas')}: <b>{t('Sim')}</b></li>
                <li>{t('Notificações Instantâneas')}: <b>{t('Não')}</b></li>
                <li>{t('Pedidos Exclusivos do Cliente')}: <b>{t('Não')}</b></li>
                <li>{t('Fila de Resposta ao Cliente')}: <b>{t('Normal')}</b></li>
                <li>{t('Limite de Respostas Simultâneas')}: <b>{t('Limitado')}</b></li>
                <li>{t('Campo para Logo da Empresa')}: <b>{t('Não')}</b></li>
                <li>{t('Campo para Website')}: <b>{t('Não')}</b></li>
                <li>{t('Campos para Redes Sociais')}: <b>{t('Não')}</b></li>
                <li>{t('Atendimento em Múltiplas Cidades')}: <b>{t('Não')}</b></li>
                </ul>
                <button className="acquire-button" disabled={settings.priority === 0} onClick={() => handlePlanSelect('Grátis')}>{settings.priority === 0 ? t('Atual') : t('Voltar ao Grátis')}</button>
              </div>

              <div className="plan-card" >
                <h3 className="plan-title">{t('Premium Mensal')}</h3>
                <p className="plan-price">{t('R$ 49,90/mês')}</p>
                <ul className="plan-features">
                <li>{t('Solicitações Imediatas')}: <b>{t('Sim')}</b></li>
                <li>{t('Notificações Instantâneas')}: <b>{t('Sim')}</b></li>
                <li>{t('Pedidos Exclusivos do Cliente')}: <b>{t('Sim')}</b></li>
                <li>{t('Fila de Resposta ao Cliente')}: <b>{t('Prioridade Alta')}</b></li>
                <li>{t('Limite de Respostas Simultâneas')}: <b>{t('Ilimitado')}</b></li>
                <li>{t('Campo para Logo da Empresa')}: <b>{t('Sim')}</b></li>
                <li>{t('Campo para Website')}: <b>{t('Sim')}</b></li>
                <li>{t('Campos para Redes Sociais')}: <b>{t('Sim')}</b></li>
                <li>{t('Atendimento em Múltiplas Cidades')}: <b>{t('Sim')}</b></li>
                </ul>
                <button className={settings.priority === 0 ? 'acquire-button' : plan.description && plan.description === 'Mensal' ? 'cancel-button' : 'acquire-button'} onClick={() => handlePlanSelect('Premium Mensal')}>{settings.priority === 0 ? t('Assinar Agora!') : plan.description && plan.description === 'Mensal' ? t('Cancelar') : t('Assinar Agora!')}</button>
                <img src="https://http2.mlstatic.com/frontend-assets/ui-navigation/5.18.1/mercadopago/logo__large.png" alt="Mercado Pago Logo" className="mercado-pago-logo" />
              </div>

              <div className="plan-card" >
                <h3 className="plan-title">{t('Premium Anual')}</h3>
                <p className="plan-price">
                  {t('R$ 479,90/ano')} 
                  <span className="discount-text">-20%</span>
                </p>
                <ul className="plan-features">
                <li>{t('Solicitações Imediatas')}: <b>{t('Sim')}</b></li>
                <li>{t('Notificações Instantâneas')}: <b>{t('Sim')}</b></li>
                <li>{t('Pedidos Exclusivos do Cliente')}: <b>{t('Sim')}</b></li>
                <li>{t('Fila de Resposta ao Cliente')}: <b>{t('Prioridade Alta')}</b></li>
                <li>{t('Limite de Respostas Simultâneas')}: <b>{t('Ilimitado')}</b></li>
                <li>{t('Campo para Logo da Empresa')}: <b>{t('Sim')}</b></li>
                <li>{t('Campo para Website')}: <b>{t('Sim')}</b></li>
                <li>{t('Campos para Redes Sociais')}: <b>{t('Sim')}</b></li>
                <li>{t('Atendimento em Múltiplas Cidades')}: <b>{t('Sim')}</b></li>
                </ul>
                <button className={settings.priority === 0 ? 'acquire-button' : plan.description && plan.description === 'Anual' ? 'cancel-button' : 'acquire-button'} onClick={() => handlePlanSelect('Premium Anual')}>{settings.priority === 0 ? t('Assinar Agora!') : plan.description && plan.description === 'Anual' ? t('Cancelar') : t('Assinar Agora!')}</button>
                <img src="https://http2.mlstatic.com/frontend-assets/ui-navigation/5.18.1/mercadopago/logo__large.png" alt="Mercado Pago Logo" className="mercado-pago-logo" />
              </div>
            </div>
          ) : selectedPlan === 'Premium Mensal' ? (
            <PlanMonths />
          ) : (
            <PlanYears />
          )}
        </div>
      </main>
      <Footer />
      {showModal && (
        <Modal
          title={t('Assinatura em processo')}
          message={t('Detectamos que você iniciou um processo de assinatura, mas ele ainda não foi validado. Se você já efetuou o pagamento, por favor, aguarde a validação, ou envie um email para info@yepwedo.com. Caso tenha desistido do pagamento, fique à vontade para tentar novamente com o plano de sua escolha.')}
          onClose={handleCloseModal}
        />
      )}
      {showModalCancelar && (
        <Modal
          title={t('Assinatura cancelada.')}
          message={t('Sua assinatura foi cancelada com sucesso.')}
          onClose={handleCloseModalCancelar}
        />
      )}
    </>
  );
}

export default PlanOptions;
