import React, { useState, useEffect, useRef } from 'react';
import './Service.css';
import ServiceRow from './ServiceRow';
import ViewServiceModal from './ServiceModal/ViewServiceModal';
import { getAverageRating } from '../../../services/RatingService';
import { getPriority } from '../../../services/SettingsService';
import { useTranslation } from 'react-i18next';
import { Loader } from '@googlemaps/js-api-loader';

function Service(props) {
  const { t } = useTranslation();
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);
  const [viewSup, setViewSup] = useState({});
  const [myType, setMyType] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedOrders, setSortedOrders] = useState([]);
  const [myLocation, setMyLocation] = useState('');
  const itemsPerPage = 5;
  const loadMoreRef = useRef(null);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(props.orders)) {
      addFactorsAndSortOrders(props.orders);
    }
    if (props.myType) {
      setMyType(props.myType);
    }
  }, [props.orders, props.myType]);

  useEffect(() => {
    const handleScroll = () => {
      if (loadMoreRef.current) {
        const { bottom } = loadMoreRef.current.getBoundingClientRect();
        if (bottom <= window.innerHeight && sortedOrders.length > currentPage * itemsPerPage) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sortedOrders, currentPage]);

  async function addFactorsAndSortOrders(orders) {
    const enhancedOrders = await Promise.all(orders.map(async order => {
      const myDestination = {
        city: order.city,
        street: order.street,
        number: order.number,
      };

      const { travelTime, distance } = await calculateTravelTimeAndDistance(props.localAddress, myDestination);
      const ratings = await getAverageRating(order.userId);
      const rating = parseFloat(ratings.averageRating) || 0;
      const priority = await getPriority(order.userId);
      console.log('PRIORITY', priority);

      return {
        ...order,
        travelTime,
        distance,
        rating,
        priority,
      };
    }));

    const sorted = enhancedOrders.sort((a, b) => {
      if (a.priority !== b.priority) return b.priority - a.priority;
      if (a.rating !== b.rating) return b.rating - a.rating;
      if (a.travelTime !== b.travelTime) return a.travelTime - b.travelTime;
      return 0;
    });

    setSortedOrders(sorted);
  }

  function calculateTravelTimeAndDistance(client, provider) {
    return new Promise((resolve, reject) => {
      const loader = new Loader({
        apiKey: "AIzaSyB0JqCNUoU9i4yNhs9KcebU08ZBC2BpqJE",
        version: "weekly",
        libraries: ["places"]
      });

      loader.load().then(() => {
        const localAddress = client;
        const destinationAddress = `${provider.street}, ${provider.number}, ${provider.city}`;
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ address: localAddress }, (localResults, localStatus) => {
          if (localStatus === 'OK') {
            const originCoords = localResults[0].geometry.location;

            geocoder.geocode({ address: destinationAddress }, (destResults, destStatus) => {
              if (destStatus === 'OK') {
                const destinationCoords = destResults[0].geometry.location;
                const service = new window.google.maps.DistanceMatrixService();

                service.getDistanceMatrix(
                  {
                    origins: [originCoords],
                    destinations: [destinationCoords],
                    travelMode: 'DRIVING'
                  },
                  (response, status) => {
                    if (status === 'OK') {
                      const element = response.rows[0].elements[0];
                      const durationValue = element.duration.value;
                      const distanceValue = element.distance.value;
                      resolve({ travelTime: durationValue, distance: distanceValue });
                    } else {
                      console.error('Error fetching distance:', status);
                      reject(status);
                    }
                  }
                );
              } else {
                console.error('Geocode for destination was not successful:', destStatus);
                reject(destStatus);
              }
            });
          } else {
            console.error('Geocode for local address was not successful:', localStatus);
            reject(localStatus);
          }
        });
      }).catch(error => {
        console.error('Error loading Google Maps API:', error);
        reject(error);
      });
    });
  }

  const handleShowMore = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const displayedOrders = sortedOrders.slice(0, currentPage * itemsPerPage);

  function onMyDestination(Data) {
    props.onMyDestination(Data);
  }

  function onServiceClick(event) {
    const id = event.target.id;
    let item;

    if (id.startsWith('provider')) {
      const providerId = id.replace('provider', '');
      item = sortedOrders.find(o => o.id == providerId);
    } else if (id.startsWith('supplier')) {
      const supplierId = id.replace('supplier', '');
      item = sortedOrders.find(o => o.id == supplierId);
    }

    if (item) {
      setViewSup({ ...item });
    }
  }

  function onStarClick(event) {
    const id = event.target.getAttribute('name');
    const item = sortedOrders.find(o => o.id == id);
    if (item) {
      const supWithRating = { ...item, rating: true };
      setViewSup(supWithRating);
    }
  }

  function onAllow() {
    props.onBackToOrders();
    if (props.onAllowOrder) {
      props.onAllowOrder();
    }
    setRefresh(true);
  }

  function onDeny() {
    props.onBackToOrders();
    if (props.onDenyOrder) {
      props.onDenyOrder();
    }
    setRefresh(true);
  }

  return (
    <>
      {isLargeScreen && myType === '1' && props.active === true ? (
        <React.Fragment>
          <div className="col-sm-12 col-md-12 mb-4">
            <div className="card border-0 shadow">
              <div className="card-header">
                <div className="row">
                  <div className="col">
                    <h2 className="fs-5 fw-bold mb-0">{t('Respostas')}</h2>
                  </div>
                  <div className="col offset-md-3">
                    <button onClick={props.onBackToOrders} className="btn btn-primary ms-3">{t('Voltar')}</button>
                  </div>
                </div>
              </div>
              <div className="relative-container">
                <div className="table-responsive divScroll">
                  <table className="table align-items-center table-flush table-sm table-hover tableFixHead">
                    <thead className="thead-light">
                      <tr>
                        <th className="border-bottom col-2" scope="col">{t('NOME')}</th>
                        <th className="border-bottom col-2" scope="col" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('VALOR')}</th>
                        <th className="border-bottom col-2" scope="col">{t('AVALIAÇÃO')}</th>
                        <th className="border-bottom col-2" scope="col">{t('DETALHES')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedOrders && displayedOrders.length ? (
                        displayedOrders.map(item => {
                          return (
                            item.negado && item.negado === true
                              ? <React.Fragment key={item.id}></React.Fragment>
                              : item.denied && item.denied === true
                                ? <React.Fragment key={item.id}></React.Fragment>
                                : item.active === false && item.winner === false
                                  ? <React.Fragment key={item.id}></React.Fragment>
                                  : <ServiceRow key={item.id} data={item} onClick={onServiceClick} onMyDestination={onMyDestination} onStarClick={onStarClick} />
                          );
                        })
                      ) : (
                        <React.Fragment />
                      )}
                    </tbody>
                  </table>
                </div>
                {sortedOrders.length > displayedOrders.length && (
                  <div ref={loadMoreRef} className="float-button-wrapper">
                    {showButton && (
                      <button onClick={handleShowMore} className="float-button">{t('Ver mais')}</button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <ViewServiceModal data={viewSup} onAllow={onAllow} onDeny={onDeny} />
        </React.Fragment>
      ) : (
        myType === '1' && props.active === true ? (
          <React.Fragment>
            <div className="col-sm-12 col-md-12 mb-4">
              <div className="card border-0 shadow">
                <div className="card-header">
                  <div className="row">
                    <div className="col">
                      <h2 className="fs-5 fw-bold mb-n5">{t('Respostas')}</h2>
                    </div>
                    <div className="col-md-6 text-end">
                      <button id="back" className="btn btn-sm btn-primary animate-up-2 me-2" onClick={props.onBackToOrders}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs me-2">
                              <path fillRule="evenodd" d="M9.53 2.47a.75.75 0 0 1 0 1.06L4.81 8.25H15a6.75 6.75 0 0 1 0 13.5h-3a.75.75 0 0 1 0-1.5h3a5.25 5.25 0 1 0 0-10.5H4.81l4.72 4.72a.75.75 0 1 1-1.06 1.06l-6-6a.75.75 0 0 1 0-1.06l6-6a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
                          </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="relative-container">
                  <div className="table-responsive divScroll" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                    <table className="table align-items-center table-flush table-sm table-hover tableFixHead" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                      <thead className="thead-light" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                        <tr style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                          <th className="border-bottom col-2" scope="col" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('NOME')}</th>
                          <th className="border-bottom col-2" scope="col" style={{ paddingLeft: "0.6rem", paddingRight: "0.6rem" }}>{t('AVALIAÇÃO')}</th>
                          <th className="border-bottom col-2" scope="col" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('DETALHES')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayedOrders && displayedOrders.length ? (
                          displayedOrders.map(item => {
                            return (
                              item.negado && item.negado === true
                                ? <React.Fragment key={item.id}></React.Fragment>
                                : item.denied && item.denied === true
                                  ? <React.Fragment key={item.id}></React.Fragment>
                                  : item.active === false && item.winner === false
                                    ? <React.Fragment key={item.id}></React.Fragment>
                                    : <ServiceRow key={item.id} data={item} onClick={onServiceClick} onMyDestination={onMyDestination} onStarClick={onStarClick} />
                            );
                          })
                        ) : (
                          <React.Fragment />
                        )}
                      </tbody>
                    </table>
                  </div>
                  {sortedOrders.length > displayedOrders.length && (
                    <div ref={loadMoreRef} className="float-button-wrapper">
                      {showButton && (
                        <button onClick={handleShowMore} className="float-button">{t('Mais...')}</button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <ViewServiceModal data={viewSup} onAllow={onAllow} onDeny={onDeny} />
          </React.Fragment>
        ) : null
      )}
    </>
  );
}

export default Service;
