import React, { useState, useRef, useEffect } from 'react';
import { Link  } from 'react-router-dom';
import StarRatingComponent from 'react-star-rating-component';
import { getAverageRating } from '../../services/RatingService';
import { updateFavorite, chkFavorite } from '../../services/OrdersService';
import { useTranslation } from 'react-i18next';
import './Favorites.css';

function FavoritesRow(props) {
    const { t } = useTranslation();

    const [rating, setRating] = useState(0);
    const [favorite, setFavorite] = useState({});
    const [categorias, setCategorias] = useState([]);

    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);

    useEffect(() => {
        const handleResize = () => {
          setIsLargeScreen(window.innerWidth >= 992);
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    useEffect(async() => {
        try {
            const rating = await getAverageRating( props.data.type === '3'? props.data.supplierId : props.data.providerId ); //{ itemId: data.itemId, userId: data.userId, rating }
            setRating(rating.averageRating);
            //console.log("RAT:", rating);
        } catch (error) {
            console.error(error);
        }
    }, [props.data.userId])

    useEffect(() => {
        if(!props.data) return;
        if (Object.entries(props.data).length === 0) return;
        setFavorite(props.data);
        if (props.data.type === '3') {
            setCategorias(JSON.parse(props.data.categoriaF));
        } else{
            setCategorias(JSON.parse(props.data.categoriaS));
        } 
    }, [props.data.userId])
                

    function onFavoriteClick(event) {
        console.log(favorite);
        
        event.preventDefault();
        const updatedFavorite = { ...favorite, isFavorite: false };

        console.log(updatedFavorite);
        
        updateFavorite(updatedFavorite)
            .then(result => {
                setFavorite(updatedFavorite)
                if (props.onExclude){
                    props.onExclude();
                }
            })
            .catch(err => console.error(err.response ? err.response.data : err.message));
        
    }
    

    return (
        <>
        { isLargeScreen 
            ? (
                <tr>
                    <td>{props.data.name} {props.data.sobrenamo}</td>
                    <td>{props.data.job}</td>
                    <td>{
                            
                                <span id="spanNoWrap" className="alert-2 alert-info py-1" style={{ fontSize: "0.9em" }}>
                                    {t(categorias[0])}...
                                </span>
                        }
                    </td>
                    <td
                    id={"rating" + props.data.id}
                    className="dv-star"
                    data-bs-toggle="modal"
                    data-bs-target="#modalOrder"
                    onClick={props.onStarClick}
                    name={props.data.id}
                    >
                    <StarRatingComponent    
                        name={props.data.id}
                        starCount={5}
                        value={rating}
                        //onClick={props.onStarClick}
                        emptyStarColor="#1455aa"
                    /></td>
                    <td>
                        <button id={"fav" + props.data.id} type="button" className="btn btn-info btn-xs" data-bs-toggle="modal" data-bs-target="#modalOrder" isDisabled={true} onClick={props.onClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs">
                                <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                            </svg>
                        </button>
                        <b> </b>
                        <Link to={`/chat/${props.data.type === '3'? props.data.supplierId : props.data.providerId}`}>
                              <button
                                  id={"vencedor"}
                                  className="btn btn-sm btn-info btn-xs animate-up-2"
                                  title="CHAT"
                                  //onClick={onFavoriteClick}
                              >
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="icon icon-xs">
                                      <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 0 0-1.032-.211 50.89 50.89 0 0 0-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 0 0 2.433 3.984L7.28 21.53A.75.75 0 0 1 6 21v-4.03a48.527 48.527 0 0 1-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979Z" />
                                      <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 0 0 1.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0 0 15.75 7.5Z" />
                                  </svg>
                                  
                              </button>
                          </Link>
                          <b> </b>
                        <button id={"star" + props.data.id} 
                            className="btn btn-sm btn-info btn-xs animate-up-2" 
                            title={t('EXCLUÍR')} 
                            onClick={onFavoriteClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs" stroke="currentColor"
                                        strokeWidth="1.5" // Ajusta a espessura do traço
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                            </svg>


                        </button>
                    </td>
                </tr>
            ): (
                <tr style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                    <td style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{props.data.name} {props.data.sobrenamo}</td>
                    <td style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{
                            
                                <span id="spanNoWrap" className="alert-2 alert-info py-1" style={{ paddingLeft: "1rem", paddingRight: "1rem", fontSize: "0.9em" }}>
                                    {t(categorias[0])}...
                                </span>
                        }
                    </td>
                    <td
                    id={"rating" + props.data.id}
                    className="dv-star"
                    style={{ paddingLeft: "0.6rem", fontSize: "8px", paddingRight: "0.6rem" }}
                    data-bs-toggle="modal"
                    data-bs-target="#modalOrder"
                    onClick={props.onStarClick}
                    name={props.data.id}
                    >
                    <StarRatingComponent    
                        name={props.data.id}
                        starCount={5}
                        value={rating}
                        //onClick={props.onStarClick}
                        emptyStarColor="#1455aa"
                    /></td>
                    <td style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                        <button id={"fav" + props.data.id} type="button" className="btn btn-info btn-xs" data-bs-toggle="modal" data-bs-target="#modalOrder" isDisabled={true} onClick={props.onClick} >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs">
                                <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                            </svg>
                        </button>
                        <b> </b>
                        <Link to={`/chat/${props.data.type === '3'? props.data.supplierId : props.data.providerId}`}>
                              <button
                                  id={"vencedor"}
                                  className="btn btn-sm btn-info btn-xs animate-up-2"
                                  title="CHAT"
                                  //onClick={onFavoriteClick}
                              >
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="icon icon-xs">
                                      <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 0 0-1.032-.211 50.89 50.89 0 0 0-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 0 0 2.433 3.984L7.28 21.53A.75.75 0 0 1 6 21v-4.03a48.527 48.527 0 0 1-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979Z" />
                                      <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 0 0 1.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0 0 15.75 7.5Z" />
                                  </svg>
                                  
                              </button>
                          </Link>
                          <b> </b>
                        <button id={"star" + props.data.id} 
                            className="btn btn-sm btn-info btn-xs animate-up-2" 
                            title={t('EXCLUÍR')} 
                            onClick={onFavoriteClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs" stroke="currentColor"
                                        strokeWidth="1.5" // Ajusta a espessura do traço
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                            </svg>


                        </button>
                    </td>
                </tr>
            )
        }
        </>
    );
};

export default FavoritesRow;