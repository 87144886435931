import React, { useState, useEffect } from 'react';
import { getLinkMonths } from "../../services/PaymentService";
import './TicketPix.css'; // Certifique-se de importar o arquivo CSS

export default function PlanMonths(props) {
    const [monthsUrl, setMonthsUrl] = useState("");
    

    useEffect(() => {
        getLinkMonths()
            .then(response => {
            //console.log(response);
            setMonthsUrl(response.data.plan);
        });
    }, []);

    return (
        <div className="container-pix">
            {monthsUrl && <iframe src={monthsUrl} className="iframe" />}
        </div>
    );
}
