import React from 'react';
import { useTranslation } from 'react-i18next';

const ThankYouPage = () => {
  const { t } = useTranslation();
  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1>{t('Parabéns por escolher o Plano Premium da Yepwedo!')}</h1>
      <p>{t('Estamos muito felizes em ter você conosco. Com o plano Premium, você terá acesso a todos os recursos exclusivos que preparamos especialmente para impulsionar o seu sucesso.')}</p>
      <p>{t('Vamos começar essa jornada juntos! Conte com a gente para o que precisar.')}</p>
    </div>
  );
};

export default ThankYouPage;