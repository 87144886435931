import axios from './BaseService';

// Outros serviços
const MP_URL = process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/mp` : 'http://localhost:3001/mp';

export async function postCriarPix(body) {
    return await axios.post(`${MP_URL}/criar-pix`, body);
}

export async function getLinkMonths() {
    return await axios.get(`${MP_URL}/newmonths`);
}

export async function getLinkYears() {
    return await axios.get(`${MP_URL}/newyears`);
}

export async function getPlan() {
    const response = await axios.get(`${MP_URL}/getplan`);
    return response.data;
}

// Função para realizar o upload da imagem
export async function cancelPlan(data) {
    const mpUrl = `${MP_URL}/cancelplan`;
    const response = await axios.post(mpUrl, data)
    return response.data;
}

export async function postCredit(
    token,
    issuer_id,
    payment_method_id,
    transaction_amount,
    installments,
    email,
    identificationType,
    identificationNumber
) {
    
    const body = {
        token,
        issuer_id,
        payment_method_id,
        transaction_amount,
        installments: Number(installments),
        description: 'Capa para notebook',
        payer: {
            email,
            identification: {
                type: identificationType,
                number: identificationNumber,
            },
        },
    };

    console.log('BODY:', body);

    return await axios.post(`${MP_URL}/process_payment`, body);
}


