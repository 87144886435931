import React, { useState, useMemo, useEffect } from 'react';
import { getImages } from '../../../services/OrdersService';
import { useTranslation } from 'react-i18next';
import './ViewOrdersModal.css';

/**
 * props:
 * - data: objeto contendo as informações do vencedor
 */
function InfoWinner(props) {
    const { t } = useTranslation();
    //console.log(props.data[0]);
    const [imagesBase64, setImagesBase64] = useState([]);
    const [isImageVisible, setIsImageVisible] = useState(false);

    function openImageInNewWindow(imageSrc) {
        const newWindow = window.open();
        newWindow.document.write(`<img src="${imageSrc}" alt="Image" style="width: 100%;" />`);
        newWindow.document.title = "Image Preview";
    }
    
    function saveImage(imageSrc) {
        const link = document.createElement('a');
        link.href = imageSrc;
        link.download = 'image.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    function onImageChange(event) {
        const images = JSON.parse(props.data[0].images);
        event.preventDefault();
        getImages(images)
            .then(result => {
                setImagesBase64(result);
                setIsImageVisible(!isImageVisible);
                
            })
            .catch(err => console.log(err))
    }

    function renderImagePreviews() {
        if (isImageVisible) {
            return imagesBase64.map((image, index) => (
                <div key={index} className="image-preview-container">
                    <img src={`data:image/png;base64,${image}`} alt={`Image ${index}`} className="image-preview-f" />
                    <button type="button" className="btn btn-primary btn-xs" onClick={() => openImageInNewWindow(`data:image/png;base64,${image}`)}>Visualizar</button>
                    <b> </b>
                    <button type="button" className="btn btn-secondary btn-xs" onClick={() => saveImage(`data:image/png;base64,${image}`)}>{t('Salvar')}</button>
                </div>
            ));
        }
    }

        return (
            <div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <b>{t('Nome')}:</b> {props.data[0].name} {props.data[0].sobrenamo}
                    </div>
                    <div className="col-md-6 mb-3">
                        <b>{t('Telefone')}:</b> {props.data[0].phone}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 mb-3">
                        <b>{t('Profissão')}:</b> {props.data[0].job || '-'}
                    </div>
                    <div className="col-md-6 mb-3">
                        <b>{t('CNPJ/CPF')}:</b> {props.data[0].cnpj || '-'}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 mb-3">
                        <b>{t('Dia')}:</b> {props.data[0].raitherDay}
                    </div>
                    <div className="col-md-6 mb-3">
                        <b>{t('Horário')}:</b> {props.data[0].hour}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 mb-3">
                        <b>{t('Resposta')}:</b> {props.data[0].resposta}
                    </div>
                    <div className="col-md-6 mb-3">
                        <b>{t('Valor')}:</b> {props.data[0].voluntario ? t('Voluntário') : props.data[0].real}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 mb-3">
                        <b>{t('CEP')}:</b> {props.data[0].cep}
                    </div>
                    <div className="col-md-4 mb-3">
                        <b>{t('Cidade')}:</b> {props.data[0].city}
                    </div>
                    <div className="col-md-4 mb-3">
                        <b>{t('Estado')}:</b> {props.data[0].state}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 mb-3">
                        <b>{t('Bairro')}:</b> {props.data[0].neighborhood}
                    </div>
                    <div className="col-md-4 mb-3">
                        <b>{t('Rua')}:</b> {props.data[0].street}
                    </div>
                    <div className="col-md-4 mb-3">
                        <b>{t('Número')}:</b> {props.data[0].number}
                    </div>
                </div>
                {   props.data[0].images !== '[]'
                        ? ( <React.Fragment>
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <b>{t('Imagens')}: </b>
                                        <button id={"viewPhotos" + props.data[0].id} type="button" className="print-hide btn btn-primary animate-up-2 btn-xs" onClick={onImageChange}>
                                            <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" /><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                                            </svg>
                                        </button>
                                    </div>                           
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        {renderImagePreviews()}
                                    </div>
                                </div>
                            </React.Fragment>
                            )
                        : <React.Fragment></React.Fragment>
                }
            </div>
        );
}

export default InfoWinner;