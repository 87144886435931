import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAvatar } from '../../services/ChatService';
import Avatar from '../Avatar/Avatar';

function PersonLink({ personId, personName }) {
    const [avatar, setAvatar] = useState(null);

    useEffect(() => {
        async function fetchAvatar() {
            try {
                const avatarData = await getAvatar(personId);
                setAvatar(`data:image/png;base64,${avatarData}`);
            } catch (error) {
                console.error(error);
            }
        }
        fetchAvatar();
    }, [personId]);

    return (
        <Link to="#" className="nav-link d-flex align-items-center">
            <span className="sidebar-icon ms-n3 me-5 mb-n1">
                {avatar && <Avatar imageUrl={avatar} alt="Avatar" size="small" />}
            </span>
            <span className="sidebar-text ms-n4 mb-1">
                {personName}
            </span>
        </Link>
    );
}

export default PersonLink;