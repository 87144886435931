import React from 'react';
import { Link } from 'react-router-dom';

function VerificationModal({ show, onClose }) {
  return (
    <React.Fragment>
      <div className={`modal ${show ? 'show' : ''}`} tabIndex="-1" style={{ display: show ? 'block' : 'none' }}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Complete seu cadastro</h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
            </div>
            <div className="modal-body">
              <p>Você precisa completar seu cadastro antes de continuar.</p>
              <Link to="/settings" className="btn btn-primary">Completar Cadastro</Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default VerificationModal;