import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import StarRatingComponent from 'react-star-rating-component';
import { useTranslation } from 'react-i18next';
import { getAverageRating } from '../../../services/RatingService';
import { getPriority } from '../../../services/SettingsService';
import PersonLink from '../../../components/PersonLink/PersonLink';
import BrandLink from '../../../components/BrandLink/BrandLink'; // Certifique-se de ajustar o caminho do import
import './ServiceRow.css';

function ServiceRow(props) {
    const { t } = useTranslation();
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 992);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [rating, setRating] = useState(0);
    const [priority, setPriority] = useState(0);

    useEffect(() => {
        async function fetchRating() {
            try {
                const rating = await getAverageRating(props.data.userId);
                setRating(rating.averageRating);
                const priority = await getPriority(props.data.userId);
                setPriority(priority);
            } catch (error) {
                console.error(error);
            }
        }
        fetchRating();
    }, [props.data.userId]);

    function handleRowClick() {
        if (!props.data) {
            console.error("props.data is undefined or null");
            return;
        }

        const myDestination = {
            city: props.data.city,
            street: props.data.street,
            number: props.data.number,
        };

        if (props.onMyDestination) {
            props.onMyDestination(myDestination);
        }
    }

    function formatarDataHora() {
        const data_hora = new Date(props.data.createdAt);

        const dia = data_hora.getDate();
        const mes = data_hora.getMonth() + 1;
        const ano = data_hora.getFullYear() % 100;
        const hora = data_hora.getHours();
        const minutos = data_hora.getMinutes();

        let periodo = "AM";
        let horaFormatada = hora;

        if (hora >= 12) {
            periodo = "PM";
            if (hora > 12) horaFormatada -= 12;
        }

        if (hora === 0) {
            horaFormatada = 12;
        }

        const data_formatada = isLargeScreen 
            ? `${dia}/${mes}/${ano}, ${horaFormatada}:${minutos < 10 ? '0' : ''}${minutos} ${periodo}`
            : `${dia}/${mes}/${ano}`;

        return data_formatada;
    }

    return (
        <>
            {isLargeScreen 
                ? (
                    <tr onClick={handleRowClick}>
                        <td className="text-gray-900">
                            {priority >= 1
                                ? <BrandLink personId={props.data.userId} personName={props.data.job ? props.data.job : props.data.name} />
                                : <PersonLink personId={props.data.userId} personName={props.data.name} />
                            }
                        </td>
                        {/*<td className="text-gray-900">{formatarDataHora()}</td>*/}
                        <td className="text-gray-900" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{props.data.voluntario ? t('Sem custo') : props.data.real}</td>
                        <td
                            id="starzing"
                            data-bs-toggle="modal"
                            data-bs-target="#modalResp"
                            onClick={props.onStarClick}
                            name={props.data.id}
                        >
                            <StarRatingComponent    
                                name={props.data.id}
                                starCount={5}
                                value={rating}
                                emptyStarColor="#1455aa"
                            />
                        </td>
                        <td className="text-gray-900" >
                            <button id={props.data.type === '3' ? "supplier" + props.data.id : "provider" + props.data.id} type="button" className="btn btn-info btn-xs" data-bs-toggle="modal" data-bs-target="#modalResp" onClick={props.onClick}>
                                <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" /><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                                </svg>
                            </button>
                        </td>
                    </tr>
                ) : (
                    <tr style={{ paddingLeft: "1rem", paddingRight: "1rem" }} onClick={handleRowClick}>
                        <td className="text-gray-900" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                            {priority >= 1
                                ? <BrandLink personId={props.data.userId} personName={props.data.job ? props.data.job : props.data.name} />
                                : <PersonLink personId={props.data.userId} personName={props.data.name} />
                            }
                        </td>
                        <td style={{ paddingLeft: "0.6rem", paddingRight: "0.6rem" }}
                            id="starzing"
                            data-bs-toggle="modal"
                            data-bs-target="#modalResp"
                            onClick={props.onStarClick}
                            name={props.data.id}
                        >
                            <StarRatingComponent    
                                name={props.data.id}
                                starCount={5}
                                value={rating}
                                emptyStarColor="#1455aa"
                            />
                        </td>
                        <td className="text-gray-900" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                            <button id={props.data.type === '3' ? "supplier" + props.data.id : "provider" + props.data.id} type="button" className="btn btn-info btn-xs" data-bs-toggle="modal" data-bs-target="#modalResp" onClick={props.onClick}>
                                <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" /><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                                </svg>
                            </button>
                        </td>
                    </tr>
                )
            } 
        </>
    );   
}

export default ServiceRow;
