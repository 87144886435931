import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { getCategoryProviders } from '../../services/CategoryService';
import { useTranslation } from 'react-i18next';
import './SelectCategory.css';

function SelectCategoryS(props) {
    const { t } = useTranslation();
    const [categoria, setCategoria] = useState(null);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        if (props.value === '[]') return;
        if (props.value) {
            setCategoria({ value: props.value, label: t(props.value) });
        }
    }, [props.value, t]);

    useEffect(() => {
        async function fetchCategories() {
            try {
                const categories = await getCategoryProviders();
                const formattedCategories = categories.map(category => ({
                    value: category.name,
                    label: t(category.name)
                }));
                setCategories(formattedCategories);
            } catch (error) {
                console.error('Error fetching categories', error);
            }
        }

        fetchCategories();
    }, [t]);

    function handleChange(event) {
        setCategoria(event);
        if (event === null || event === '[]') return;
        //setCategoria(event);
        props.onChange(event);
    }

    return (
        <Select
            value={categoria}
            onChange={handleChange}
            options={categories}
            placeholder={t('Selecione') + '...'}
            isClearable
            //classNamePrefix={`react-select form-select me-4 ${props.className || ''}`}
            className={`react-select me-4 ${props.className || ''}`}
        />
    );
}

export default SelectCategoryS;
