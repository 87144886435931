import React, { useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { doLogin, doRecovery, getCode } from '../../services/AuthService';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../LanguageSelector';
import './Login.css'; // Importar arquivo de estilo CSS

import imagem from './imagem.jpg'; // Importar a imagem corretamente

function Login() {
    const { t, i18n } = useTranslation();
    
    // Adicione esta linha para pegar o idioma atual
    const currentLanguage = i18n.language;
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState(['', '', '', '', '']);
    const [newCode, setCodeSend] = useState('');
    const [error, setError] = useState('');
    const [step, setStep] = useState(1);
    const codeInputsRef = useRef([]);

    function onChangeInput(event) {
        const { id, value } = event.target;
        if (id === 'email') setEmail(value);
        else if (id === 'password') setPassword(value);
    }

    function onCodeChange(index, value) {
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);
        if (value !== '' && index < code.length - 1) {
            codeInputsRef.current[index + 1].focus();
        }
    }

    function onSubmit(event) {
        event.preventDefault();
        if (step === 1) {
            doLogin(email, password)
                .then(response => {
                    localStorage.setItem('token', response.token);
                    localStorage.setItem('hasFutures', response.hasFutures);
                    localStorage.setItem('id', response.id);
                    if (response.twoFa === false) {
                        history.push('/dashboard');
                    } else {
                        const getcode = {
                            email: email,
                            currentLanguage: currentLanguage
                        }
                        getCode(getcode)
                            .then(result => {
                                setCodeSend(result);
                                setStep(2);
                            })
                            .catch(err => {
                                console.error(err);
                                setError(t('Código inválido!'));
                            });
                    }
                })
                .catch(err => {
                    console.error(err);
                    setError(t('Usuário e/ou senha inválidos!'));
                });
        } else if (step === 2) {
            const codeStr = code.join('');
            if (codeStr === newCode) {
                history.push('/dashboard');
            } else {
                setError(t('Código inválido!'));
            }
        } else if (step === 3) {
            // Função para enviar email de recuperação de senha
            const recovery = {
                email: email,
                currentLanguage: currentLanguage
            }
            doRecovery(recovery)
                .then(() => {
                    setError(t('Senha provisória enviada para o email!'));
                    setStep(1);
                })
                .catch(err => {
                    console.error(err);
                    setError(t('Erro ao enviar email de recuperação!'));
                });
        }
    }

    function onLostPassword() {
        setStep(3); // Mudar para a etapa de recuperação de senha com email
    }

    function onReturn() {
        setStep(1); // Mudar para a etapa de recuperação de senha com email
    }

    return (
        <div className="container">
            <div className="message-box d-none d-md-block">
                <div className="message">
                    <h2>{t('Bem-vindo a Yepwedo!')}</h2>
                </div>
                <img src={imagem} alt="Imagem" className="image" />
            </div>
            <div className="login-box">
                <LanguageSelector /> {/* Adicionar o seletor de idioma aqui */}
                <h2>{step === 3 ? t('Recuperar Senha') : t('Login')}</h2>
                <form onSubmit={onSubmit}>
                    {step === 1 && (
                        <>
                            <div className="input-container">
                                <i className="fas fa-user"></i>
                                <input type="text" id="email" placeholder={t('Email')} value={email} onChange={onChangeInput} required />
                            </div>
                            <div className="input-container">
                                <i className="fas fa-lock"></i>
                                <input type="password" id="password" placeholder={t('Senha')} value={password} onChange={onChangeInput} required />
                            </div>
                        </>
                    )}
                    {step === 2 && (
                        <div className="input-container">
                            <label htmlFor="code">{t('Código enviado para o e-mail')}</label>
                            <div className="d-flex justify-content-center align-items-center">
                                {code.map((digit, index) => (
                                    <input
                                        key={index}
                                        ref={(el) => codeInputsRef.current[index] = el}
                                        type="text"
                                        className="form-control mx-2 text-center rounded-circle border border-gold"
                                        style={{ width: '50px', height: '50px' }}
                                        maxLength="1"
                                        value={digit}
                                        onChange={(event) => onCodeChange(index, event.target.value)}
                                        required
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    {step === 3 && (
                        <div className="input-container">
                            <input
                                type="text"
                                id="email"
                                placeholder={t('Digite seu email')}
                                value={email}
                                onChange={onChangeInput}
                                required
                            />
                        </div>
                    )}
                    <button type="submit" className="blue-button">
                        {step === 1 ? t('Entrar') : step === 2 ? t('Entrar') : t('Enviar Email')}
                    </button>
                </form>
                {step === 1 && <a href="#" className="forgot-password" onClick={onLostPassword}>{t('Esqueceu a senha?')}</a>}
                <div className="button-group">
                    {step === 3 || step === 2
                        ? <a href="#" className="return-button" onClick={onReturn}>{t('Voltar')}</a>
                        : <Link to="/signup" className="green-button">{t('Criar nova conta')}</Link>
                    }
                </div>
                <div className="footer">
                    <span className="sidebar-icon">
                        <svg
                            className="icon icon-xs"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="20 46.333 160 107"
                            xmlns="http://www.w3.org/2000/svg"
                            data-type="color"
                            role="img"
                            aria-label="Homepage"
                            style={{ transform: 'scaleY(-1)' }} // Inverte o ícone verticalmente
                        >
                            <g>
                                <path
                                    d="M73.333 46.333h53.334l-53.334 107H20l53.333-107z"
                                    fill="#D6DCDC"
                                    data-color="1"
                                ></path>
                                <path
                                    d="M73.333 46.333zM100 99.833l26.667 53.5H180l-26.666-53.5H100z"
                                    fill="#D6DCDC"
                                    clipRule="evenodd"
                                    fillRule="evenodd"
                                    data-color="1"
                                ></path>
                            </g>
                        </svg>
                    </span>
                    <span className="sidebar-text">Yepwedo</span>
                </div>
                {error && <p className="error-message">{error}</p>}
            </div>
        </div>
    );
}

export default Login;
